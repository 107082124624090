export function getCities() {
  return new Promise((resolve, reject) => {
    $.ajax({
      url: '/nova_poshta/cities',
      method: 'GET',
      data: {},
      success(cities) {
        resolve(cities);
      },
      error(err) {
        reject(err);
      },
    });
  });
}

export function getWarehouses(cityName) {
  return new Promise((resolve, reject) => {
    $.ajax({
      url: '/nova_poshta/warehouses',
      method: 'GET',
      data: { cityName },
      success(points) {
        resolve(points);
      },
      error(err) {
        reject(err);
      },
    });
  });
}
