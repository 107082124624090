import { wayforpayCustom } from '../common/wayforpayCustom';
import { order } from './order';

export class IconPack {
  constructor() {
    this.price = 5;
    this.currentPack = null;
  }

  static get() {
    return new Promise((resolve, reject) => {
      $.get('/icon_packs')
        .done(paidIcons => resolve(paidIcons))
        .fail(err => reject(err));
    });
  }

  setIconPack(packId) {
    if (packId !== null) {
      this.getPackById(packId).then(data => {
        this.currentPack = data;
      });
    } else {
      this.currentPack = null;
    }
  }

  getCurrentIconPack() {
    return this.currentPack;
  }

  getPrice() {
    return this.price;
  }

  getPackById(packId) {
    return this.constructor
      .get()
      .then(data => JSON.parse(data))
      .then(parsedData => parsedData.find(pack => pack.id === Number(packId)));
  }

  getTotalUAH() {
    const rate = wayforpayCustom.getRateByCurrency(order.getBaseCurrency());
    return +(this.getPrice() * rate).toFixed(2);
  }

  getTotal(currency) {
    return +(
      this.getTotalUAH() / wayforpayCustom.getRateByCurrency(currency)
    ).toFixed(2);
  }

  getOrderedIconPackDetails() {
    return {
      id: Number(this.currentPack.id),
      packName: this.currentPack.name,
      price: Number(this.getTotal(order.getUserCurrency())),
      currency: order.getUserCurrency(),
    };
  }
}

export const iconPack = new IconPack();
