export function addToCart(name, type, price) {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event: 'addToCart',
    ecommerce: {
      currencyCode: 'USD',
      add: {
        products: [
          {
            name: `${type}: ${name}`,
            id: name,
            category: type,
            price: price,
            brand: 'Waitron Menu',
            quantity: 1,
          },
        ],
      },
    },
  });
}

export function checkoutOption(step, products) {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event: 'checkout',
    ecommerce: {
      checkout: {
        actionField: { step: step },
        products: products,
      },
    },
  });
}

export function itemImpression(products) {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event: 'productsImpression',
    ecommerce: {
      impressions: [products],
    },
  });
}
