import { changeDpiDataUrl } from 'changedpi';
import _ from 'lodash';
import { canvasPage } from './canvasPage';
import { dataURItoBlob, isMenuFilled } from './helpers';
import * as notification from '../common/notifications';
import i18nClient from '../i18nextClient';
import { categoryItemBtn } from './menu_templates/changeCategoryItem';
import { grid } from './grid';
import { menuChange } from './menuChange';

function getMenuImagesData(fabricObjects) {
  // 6.38 ms → 5.63 ms
  return fabricObjects.map(fabricObj => {
    categoryItemBtn.constructor.hide(fabricObj);
    const paidIcons = fabricObj
      .getObjects()
      .filter(obj => obj.class === 'paidIcon');
    fabricObj.renderOnAddRemove = false;
    if (grid.isOn()) grid.constructor.remove(fabricObj);
    const addPlaceholders = Promise.all(
      paidIcons.map(
        paidIcon =>
          new Promise((resolve, reject) => {
            fabric.loadSVGFromURL(
              '/img/editor/waitron-payment.svg',
              (objects, options) => {
                if (!objects) reject(new Error());
                const placeholder = fabric.util.groupSVGElements(
                  objects,
                  options
                );
                placeholder.set({
                  top: paidIcon.top,
                  left: paidIcon.left,
                  class: 'premium',
                });
                placeholder.scaleToWidth(paidIcon.width * paidIcon.scaleX);
                fabricObj.add(placeholder);
                fabricObj.remove(paidIcon);
                resolve(placeholder);
              }
            );
          })
      )
    );
    return addPlaceholders.then(() => {
      const dataURL = fabricObj
        .toDataURL({
          format: 'jpeg',
          multiplier: 2,
          quality: 0.35,
          backStoreOnly: true,
        })
        .replace('data:image/jpeg;base64,', '');
      const placeholders = fabricObj
        .getObjects()
        .filter(obj => obj.class === 'premium');
      placeholders.forEach(placeholder => fabricObj.remove(placeholder));
      paidIcons.forEach(paidICon => fabricObj.add(paidICon));
      if (grid.isOn()) grid.show(fabricObj);
      fabricObj.renderOnAddRemove = true;
      return { pageId: fabricObj.id, dataURL };
    });
  });
}

export class MenuStorage {
  generateImagesFromTemplate({ menuId, images, templateId }) {
    if (!menuId || !templateId || images !== '/img/user_menus/default.jpeg') {
      return;
    }

    this.saveOptimized(menuId, { isAllPages: true });
  }

  saveOptimized(menuId, props = { isAllPages: false }) {
    // todo: do not forget to drop it
    console.time('Saving a menu');
    const listOfAllPageIds = canvasPage.getPageIds();
    const changedFabricObjects = canvasPage
      .getFabricObjects()
      .filter(fabricObj =>
        props.isAllPages
          ? true
          : _.find(menuChange.getChangedPagesList(), { pageId: fabricObj.id })
      );
    const changedPageObjects = changedFabricObjects.map(obj => ({
      id: obj.id,
      serializedCanvas: canvasPage.toJSON(obj),
    }));
    return Promise.all(getMenuImagesData(changedFabricObjects))
      .then(imagesData =>
        this.constructor.update(
          menuId,
          listOfAllPageIds,
          changedPageObjects,
          imagesData,
          isMenuFilled(),
          canvasPage.getLastUpdateTimestamp()
        )
      )
      .then(res => {
        // todo: do not forget to drop it
        console.timeEnd('Saving a menu');
        if (res.isOutdated) {
          $('#latest-changes-modal').modal('show');
        } else {
          canvasPage.setLastUpdateTimestamp(new Date(res.updatedAt).valueOf());
          menuChange.resetChanges();
        }
      });
  }

  static update(
    menuId,
    allPageIds,
    changedPageObjects,
    imagesData,
    completeMenu,
    lastUpdateTimestamp
  ) {
    return new Promise((resolve, reject) => {
      $.ajax({
        url: `/menu/${menuId}/optimized`,
        method: 'PUT',
        data: {
          allPageIds,
          changedPageObjects,
          imagesData,
          completeMenu,
          lastUpdateTimestamp,
        },
        success(res) {
          resolve(res);
        },
        error(err) {
          reject(err);
        },
      });
    });
  }

  saveToDB(menuId, success, error) {
    // todo: do not forget to drop it
    console.time('Saving a menu');
    canvasPage.setMenuName($('#menu-name').val());
    return Promise.all(getMenuImagesData(canvasPage.getFabricObjects()))
      .then(imagesData => {
        const menuData = canvasPage.getMenuData();
        canvasPage
          .getFabricObjects()
          .forEach(fabricObj =>
            categoryItemBtn.constructor.show(
              fabricObj.getActiveObject(),
              fabricObj
            )
          );
        $.ajax({
          url: `/menu/${menuId}`,
          method: 'PUT',
          data: {
            isMenuFilled: isMenuFilled(),
            menuName: canvasPage.getMenuName(),
            menuData,
            imagesData,
            lastUpdateTimestamp: canvasPage.getLastUpdateTimestamp(),
          },
          success(data) {
            // todo: do not forget to drop it
            console.timeEnd('Saving a menu');
            if (data.isOutdated) {
              $('#latest-changes-modal').modal('show');
            } else {
              canvasPage.setLastUpdateTimestamp(
                new Date(data.updated_at).valueOf()
              );
              if (success) success();
            }
          },
          error() {
            if (error) error();
          },
        });
      })
      .catch(() => {
        if (error) error();
      });
  }

  static rename(id, name) {
    $.ajax({
      url: `/menu/${id}`,
      method: 'PUT',
      data: { newMenuName: name },
      success() {
        canvasPage.setMenuName(name);
        notification.success(i18nClient.t('Saved'));
      },
      error() {
        notification.error(i18nClient.t('Something went wrong'));
      },
    });
  }

  saveAsImage() {
    const toDownload = canvasPage.getFabricObjects().map(elem => {
      if (grid.isOn()) grid.constructor.remove(elem);

      return this.constructor.getObjectURL(
        elem.toDataURL({
          format: 'jpeg',
          multiplier: canvasPage.getCanvasMultiplier(),
        })
      );
    });
    this.constructor.downloadFiles(toDownload);
  }

  static downloadFiles(files) {
    function downloadNext(i) {
      if (i >= files.length) return;
      const a = document.createElement('a');
      $(a).attr('href', files[i]);
      $(a).attr('target', '_parent');
      $(a).attr('download', `${$('#menu-name').val()}-${i + 1}.jpeg`);
      document.body.appendChild(a);
      a.click();
      a.parentNode.removeChild(a);
      setTimeout(() => {
        downloadNext(i + 1);
      }, 500);
    }

    downloadNext(0);
  }

  static getObjectURL(canvasData) {
    // const exifString = piexif.dump({
    //     '0th': {
    //         [piexif.ImageIFD.Software]: `Waitron Menu Editor rev. ${GIT_REVISION}`,
    //         [piexif.ImageIFD.XResolution]: [300, 1],
    //         [piexif.ImageIFD.YResolution]: [300, 1],
    //     },
    // });
    // const data = piexif.insert(exifString, canvasData).replace('data:image/jpeg;base64,', '');
    const data = changeDpiDataUrl(canvasData, 300).replace(
      'data:image/jpeg;base64,',
      ''
    );
    return URL.createObjectURL(dataURItoBlob(data, 'image/jpeg'));
  }
}

export const menuStorage = new MenuStorage();
