import googleFonts from 'google-fonts';
import FontFaceObserver from 'fontfaceobserver';
import { ucfirst } from './helpers';

export class Font {
  constructor() {
    this.localFonts = [
      'Montserrat Semi-Bold',
      'Montserrat Extra-Bold',
      'Montserrat Light',
      'Algeria One',
      'NexaScript Heavy',
      'FranklinGothic DemiCond',
      'Kelson Sans Bold',
      'Museo Sans',
      'OpenSans Light',
      'Amore',
      'Lifehack',
      'Nexa Rust Slab Black Shadow',
      'Kent Printed Shadowed',
      'PFCentroSlabPro-Bold',
      'Days',
      'Autoradiographic',
      'Gabriela',
      'Voga',
      'Nautilus',
      'VI Cam Huong Hua',
      'Gabriola',
      'Intro',
      'FuturaDemic',
      'Tiempos',
      'DINCondensed',
      'HelveticaNeueCyr',
    ];
    this.googleFonts = [
      'Open Sans',
      'Open Sans Condensed',
      'Open Sans Light',
      'PT Sans',
      'Roboto',
      'Ubuntu',
      'Playfair Display',
      'Oranienbaum',
      'Amatic SC',
      'Pattaya',
      'Lobster',
      'Caveat',
      'Neucha',
      'Marck Script',
      'Marmelad',
      'Montserrat',
      'Comfortaa',
      'Pattaya',
      'Elsie',
      'Fira Sans Condensed',
      'Cousine',
      'Oswald',
      'Source Serif Pro',
      'Mountains of Christmas',
      'Poppins',
      'Parisienne',
    ];
    this.googleFontsLoaded = false;
    this.usedFonts = new Set();
  }

  isBasic(font) {
    return this.localFonts.includes(font) || this.googleFonts.includes(font);
  }

  loadBasicGoogleFonts() {
    if (this.googleFontsLoaded) return;
    this.googleFontsLoaded = true;
    googleFonts.add(
      this.googleFonts.reduce(
        (obj, cur) => ({
          ...obj,
          [cur]: cur === 'Open Sans Condensed' ? 300 : true,
        }),
        {}
      )
    );
  }

  load(font) {
    if (this.googleFonts.includes(font))
      googleFonts.add({ [font]: font === 'Open Sans Condensed' ? 300 : true });
    const fontObserver = new FontFaceObserver(font);
    return fontObserver.load().catch(err => console.log(font, err));
  }

  getGoogleFonts() {
    $('.font-upload-input').prepend(
      $('<option id="loading"></option>').html('Loading...')
    );
    $('.chosen-select').chosen({ width: '80%' });
    const self = this;
    $.getJSON(
      'https://www.googleapis.com/webfonts/v1/webfonts?key=AIzaSyB8KuYtjqbe02qTwEmbFC9gNneVhEkbOhM',
      ({ items }) => {
        $.each(items, (_, font) => {
          $('.font-upload-input').append(`
            <option value="${font.family}" 
              ${self.isBasic(font.family) ? 'disabled' : ''}>
              ${font.family}
            </option>
          `);
        });
        $('.font-upload-input')
          .find('#loading')
          .remove();
        $('.chosen-select')
          .chosen()
          .trigger('chosen:updated');
      }
    );
  }

  static uploadGoogleFont(family) {
    WebFont.load({
      google: {
        families: [family],
      },
    });
  }

  /**
   * @param menuData
   * @property {array}   menuData.pages
   * @property {string}  menuData.pages.id
   * @property {string}  menuData.pages.serializedCanvas
   * @returns {Set<string>}  set of fonts
   */
  setUsedFonts(menuData) {
    menuData.pages.forEach(({ serializedCanvas }) =>
      JSON.parse(serializedCanvas).objects.forEach(obj =>
        obj.fontFamily ? this.usedFonts.add(obj.fontFamily) : false
      )
    );
  }

  loadUsedFonts() {
    return Promise.all(
      Array.from(this.usedFonts).map(usedFont => this.load(ucfirst(usedFont)))
    ).catch(() => console.log('Failed to load used fonts'));
  }
}

export const font = new Font();
