import { canvasPage } from './canvasPage';
import { canvas } from './canvasManager';

export class CanvasHistory {
  constructor() {
    this.back = [];
    this.forward = [];
    this.state = null;
  }

  addState(state) {
    this.forward = [];
    if (this.state) {
      this.back = this.back.length > 9 ? this.back.slice(1, 10) : this.back;
      this.back.push({ id: this.state.id, state: this.state.json });
    }
    this.state = { id: canvas.current().get('id'), json: state };
  }

  undo() {
    if (this.back.length > 0) {
      this.forward =
        this.forward.length > 9 ? this.forward.slice(1, 10) : this.forward;
      this.forward.push({ id: this.state.id, state: this.state.json });
      this.replay(this.back.pop());
    }
  }

  redo() {
    if (this.forward.length > 0) {
      this.back = this.back.length > 9 ? this.back.slice(1, 10) : this.back;
      this.back.push({ id: this.state.id, state: this.state.json });
      this.replay(this.forward.pop());
    }
  }

  replay(current) {
    this.state.id = current.id;
    this.state.json = current.state;
    const fabricObj = canvasPage.findFabricObj(current.id);
    return window.customCanvas.restoreFromJSON(fabricObj, this.state.json);
  }
}

export const canvasHistory = new CanvasHistory();
