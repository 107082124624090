import cookies from 'js-cookie';
import { templatesManager } from '../editor/menu_templates/templatesManager';
import { paidIcon } from '../editor/paidIcon';
import { getSupportedLanguageCode } from './index';

const getPaidItems = () => {
  const languageCode = getSupportedLanguageCode(cookies.get('lang'));
  const paidItems = Array.from(paidIcon.getUsedIcons()).map(icon => {
    const iconName = `${icon.slice(0, icon.indexOf('.svg'))}.png`;
    return {
      id: `line_${languageCode}_product_icon_${iconName}`,
      product_id: `${languageCode}_product_icon_${iconName}`,
      product_variant_id: `variant_icon_${iconName}`,
      quantity: 1,
      price: 1,
    };
  });
  if (
    templatesManager.usedTemplateName &&
    templatesManager.isPaidAndNonPurchased
  ) {
    paidItems.push({
      id: `line_${languageCode}_product_template_${templatesManager.id}`,
      product_id: `${languageCode}_product_template_${templatesManager.id}`,
      product_variant_id: `variant_template_${templatesManager.id}`,
      quantity: Number(1),
      price: templatesManager.templatePrice,
    });
  }
  return paidItems;
};

export function addOrUpdateCart(menuId, checkoutURL) {
  if (!getPaidItems().length) return;
  $.post('/mailchimp/cart', { menuId, checkoutURL, lines: getPaidItems() });
}
