import { templatesManager } from './menu_templates/templatesManager';
import { canvasPage } from './canvasPage';

const getDefaultTexts = configurations => {
  const defaultText = new Set();

  function walkThroughConfiguration(configuration) {
    Object.keys(configuration).forEach(key => {
      if (configuration[key].defaultValue) {
        defaultText.add(configuration[key].defaultValue);
        return configuration[key].defaultValue;
      }
      if (typeof configuration[key] === 'object') {
        walkThroughConfiguration(configuration[key]);
      }
    });
  }

  configurations.forEach(configuration =>
    walkThroughConfiguration(configuration)
  );
  return defaultText;
};

function isTemplateFilled() {
  const standardTexts = [
    ...[
      'waitron',
      'www.waitron.menu',
      'www.restaurantname.com',
      '/ restaurantname',
      '/restaurantname',
      '+12-01212212',
      'menuwifi/pas123',
      'restaurant name',
      'many friends',
      'a lot of food',
    ],
    ...Array.from(getDefaultTexts(templatesManager.categoryConfigurations)),
  ];

  let defaultTexts = 0;
  let allTexts = 0;

  canvasPage.getFabricObjects().forEach(fabricObj => {
    if (fabricObj._iTextInstances) {
      fabricObj._iTextInstances.forEach(elem => {
        if (elem.class === 'price' || elem.class === 'weight') return;
        const text = elem.text.toLowerCase();
        if (
          standardTexts.includes(text) ||
          text.includes('lorem ipsum') ||
          text.includes('your text')
        )
          defaultTexts++;
        allTexts++;
      });
    }
  });

  if (defaultTexts) {
    const percentOfUnusedText = (defaultTexts * 100) / allTexts;
    return percentOfUnusedText < 20;
  }
  return allTexts > 10;
}

function isMenuFromScratchFilled() {
  const objects = [];

  canvasPage.getFabricObjects().forEach(fabricObj => {
    fabricObj.getObjects().forEach(obj => {
      objects.push(obj);
    });
  });

  return objects.length > 10;
}

export function isMenuFilled() {
  if (templatesManager.getUsedTemplate() != null) {
    return isTemplateFilled();
  }
  return isMenuFromScratchFilled();
}

export function convertPtToPx(pt) {
  return (4 / 3) * pt;
}

export function clearValues(slider, valueContainer, initialValue) {
  $(slider).val(0);
  $(slider).css('background', '#e5e5e5');

  if ($(valueContainer) && initialValue) {
    $(valueContainer).text(initialValue);
  }
}

export function sliderGradient() {
  let value = this.value;

  if ($(this).attr('id') === 'zoom-range') {
    value = this.value * 100 - 80;
    if (this.value <= 0) {
      value = Math.abs(this.value) * 100;
    }
  }

  $(this).css(
    'background',
    `linear-gradient(to right, #d71f49 0%, #d71f49 ${value}%, #e5e5e5 ${value}%, #e5e5e5 100%)`
  );
}

export function dataURItoBlob(dataURI, contentType) {
  const byteString = atob(dataURI);
  const ab = new ArrayBuffer(byteString.length);
  const byteArray = new Uint8Array(ab);

  for (let i = 0; i < byteString.length; i += 1) {
    byteArray[i] = byteString.charCodeAt(i);
  }

  return new Blob([ab], { type: contentType });
}

export function ucfirst(str) {
  return str.charAt(0).toUpperCase() + str.slice(1);
}

export function setCustomTextEditingBorder(obj) {
  obj.set({
    editingBorderColor: '#d71f49',
    cursorColor: '#d71f49',
    cursorWidth: 2,
  });
}
