import 'bootstrap-notify';

const HIDE_DELAY = 3000;

const template = `
    <div data-notify="container" class="alert alert-{0}" role="alert">
        <button type="button" aria-hidden="true" class="close" data-notify="dismiss">
        ×
        </button>

        <div class="icon-title-container">
            <span data-notify="icon"></span>
            <span data-notify="title">{1}</span>
        </div>
        <div class="message-container">
            <span data-notify="message">{2}</span>
        </div>
    </div>;
`;

const buildNotification = (title, message, icon, type, delay) =>
  $.notify(
    { title, message, icon },
    {
      type,
      newest_on_top: true,
      placement: {
        from: 'bottom',
        align: 'right',
      },
      delay,
      template,
    }
  );

export function success(message, options = { title: 'Success' }) {
  buildNotification(
    options.title,
    message,
    'icon-waitron-alert-good',
    'success-custom',
    HIDE_DELAY
  );
}

export function error(message, options = { title: 'Error' }) {
  buildNotification(
    options.title,
    message,
    'icon-waitron-alert-fatal-error',
    'error-custom',
    HIDE_DELAY
  );
}

export function warning(message, options = { title: 'Warning' }) {
  buildNotification(
    options.title,
    message,
    'icon-waitron-alert-error',
    'warning-custom',
    HIDE_DELAY
  );
}

export function info(message, id) {
  $.notify(
    { message },
    {
      type: 'info-custom',
      newest_on_top: true,
      placement: {
        from: 'bottom',
        align: 'right',
      },
      delay: 0,
      template: `
            <div data-notify="container" id="${id}" class="alert alert-{0}" role="alert">
                <button type="button" aria-hidden="true" class="close" data-notify="dismiss">
                    ×
                </button>
                <div class="icon-container">
                    <img src="/img/editor/logo.svg" alt="" width="30px" />
                </div>
                <span data-notify="title">Info</span>
                <span data-notify="message">{2}</span>
            </div>`,
    }
  );
}

export function showLoader() {
  $('main').css('opacity', 0.5);
  $('#loading-notification').show();
}

export function hideLoader() {
  $('main').css('opacity', 1);
  $('#loading-notification').hide();
}
