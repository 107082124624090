import mustache from 'mustache';
import * as notification from '../common/notifications';
import startBootstrapTour from '../editor/bootstrapTour';
import i18nClient from '../i18nextClient';

export function copyLink(domain, slug) {
  const $temp = $('<input>');
  $('body').append($temp);
  $temp.val(`${domain}/menu/${slug}`).select();
  document.execCommand('copy');
  $temp.remove();
  notification.success(i18nClient.t('Copied'));
}

export function setMenuFormat() {
  if ($(this).hasClass('disabled')) return;
  $(this)
    .parent()
    .find('.menu-format')
    .removeClass('selected');
  $(this).addClass('selected');
  $('input[name="menuFormat"]').attr('value', $(this).attr('id'));
}

export function resizeTempalte(menuData) {
  $.ajax({
    url: `/menu_template/${menuData.id}/resize`,
    method: 'POST',
    data: {
      selectedFormatName: menuData.selectedFormatName,
    },
    success(templateId) {
      $(location).attr(
        'href',
        `/app/designer_mode?format=${menuData.selectedFormatName}&template=${templateId}`
      );
    },
  });
}

export function startTour() {
  $.post(
    '/profile/seen_editor_tutorial',
    { seenEditorTutorial: 1 },
    startBootstrapTour
  );
}

export function createDesign() {
  $.post('menu_design')
    .done(design => {
      const template = $('#menu-design-template').html();
      const mustacheTemplate = mustache.render(template, {
        design: {
          id: design.id,
          status: design.status,
          statusTranslated: i18nClient.t(design.status),
        },
        status: i18nClient.t('Status'),
        createMenu: i18nClient.t('Create a menu'),
        statusButtonTranslated: i18nClient.t('Submit to review'),
        deleteButtonTranslated: 'Delete',
      });
      $('.designs tbody').prepend(mustacheTemplate);
    })
    .fail(() => notification.warning(i18nClient.t('Something went wrong')));
}

export function sendToReview(menuDesignId, statusText) {
  return $.get(`/menu_design/${menuDesignId}`).done(design => {
    if (design.templates.length) {
      $.ajax({
        url: `/menu_design/${menuDesignId}`,
        method: 'PUT',
        data: {
          param: 'status',
          value: 'under-review',
        },
        success() {
          statusText.removeClass('in-progress').addClass('under-review');
          statusText.text(
            `${i18nClient.t('Status')}: ${i18nClient.t('under-review')}`
          );

          notification.success(i18nClient.t('Submitted to review'));
        },
        error() {
          notification.warning(i18nClient.t('Something went wrong'));
        },
      });
    } else {
      notification.warning(i18nClient.t('Something went wrong'));
    }
  });
}

export function deleteDesign(menuDesignId, row) {
  $.ajax({
    url: `menu_design/${menuDesignId}`,
    method: 'DELETE',
    success() {
      row.remove();
      notification.success('Deleted');
    },
    error() {
      notification.warning(i18nClient.t('Something went wrong'));
    },
  });
}

export function deleteTemplate(tempalteId, template) {
  $.ajax({
    url: `/menu_template/${tempalteId}`,
    method: 'DELETE',
    success() {
      template.remove();
      notification.success('Deleted');
    },
    error() {
      notification.warning(i18nClient.t('Something went wrong'));
    },
  });
}

export function approveDesign(designId, name) {
  return new Promise((resolve, reject) =>
    $.post(`/menu_design/${designId}/approve`, { name })
      .done(() => resolve())
      .fail(() => reject())
  );
}

export function denyDesign(designId) {
  return new Promise((resolve, reject) =>
    $.post(`/menu_design/${designId}/deny`)
      .done(() => resolve())
      .fail(() => reject())
  );
}

export function createTemplate(menuDesignId, formatName) {
  if (!formatName) {
    notification.warning(i18nClient.t('Choose a template size'));
    return;
  }
  $.post('template', { menuDesignId, formatName })
    .done(menuTemplate => {
      $(window.location).attr(
        'href',
        `/app/designer_mode?format=${formatName}&template=${menuTemplate.id}`
      );
    })
    .fail(() => notification.warning(i18nClient.t('Something went wrong')));
}

export function disableUsedFormats(container) {
  const resizeFormats = $('.resize-formats');
  resizeFormats.find('.disabled').removeClass('disabled');
  resizeFormats.find('.selected').removeClass('selected');
  const usedFormats = $.map(container.find('[data-formatName]'), elem =>
    $(elem).attr('data-formatName')
  );
  usedFormats.forEach(usedFormat =>
    resizeFormats.find(`[data-format=${usedFormat}]`).addClass('disabled')
  );
}
