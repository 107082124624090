import 'bootstrap/dist/js/bootstrap.min';
import 'bootstrap/dist/css/bootstrap.min.css';
import setUpCommonEvents from './events';

$(document).ready(setUpCommonEvents);

export function updateModalContent(slug) {
  $('#twitter-link').attr(
    'href',
    `https://twitter.com/share?url=${window.location.origin}/menu/${slug}`
  );
  $('#facebook-link').attr(
    'href',
    `http://www.facebook.com/sharer.php?u=${window.location.origin}/menu/${slug}`
  );
  $('#pinterest-link').attr(
    'href',
    `http://www.pinterest.com/pin/create/button/?url=${window.location.origin}/menu/${slug}`
  );
  $('#vk-link').attr(
    'href',
    `http://vkontakte.ru/share.php?url=${window.location.origin}/menu/${slug}`
  );
  $('#mail-link').attr(
    'href',
    `mailto:?body=${window.location.origin}/menu/${slug}`
  );
  $('.menu-link').val(`${window.location.origin}/menu/${slug}`);
  $('.embed-code').val(
    `<div class='embed-responsive embed-responsive-4by3'><iframe class='embed-responsive-item' referrerpolicy='no-referrer-when-downgrade' src='${window.location.origin}/menu/${slug}'></iframe></div>`
  );
}

export function selectText(target) {
  const rng = document.createRange();
  const sel = window.getSelection();
  rng.selectNode(target);
  sel.removeAllRanges();
  sel.addRange(rng);
}

export function getSupportedLanguageCode(languageCode) {
  return (
    ['en', 'ru', 'uk'].find(supportedCode => supportedCode === languageCode) ||
    'en'
  );
}

export function isValidName(name) {
  return /^[a-zA-Z0-9ЁёА-я ()]+$/.test(name);
}
