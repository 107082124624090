/* eslint-disable no-alert */
import withinviewport from 'withinviewport';
import cookies from 'js-cookie';
import confirmPassword from './validation';
import {
  deleteUser,
  loginAs,
  uploadPhoto,
  sendEmailVerification,
} from './profile';
import * as dashboard from './dashboard';
import * as GA from '../common/GAevents';
import i18nClient from '../i18nextClient';
import * as notification from '../common/notifications';
import { menuStorage } from '../editor/menuStorage';
import { updateModalContent, selectText, isValidName } from '../common/index';
import { copyLink } from '../editor/generalEditing';

export default function() {
  $('input[name=newPassword], input[name=confirmNewPassword]').on(
    'input',
    () => {
      confirmPassword(
        $('input[name=newPassword]'),
        $('input[name=confirmNewPassword]')
      );
    }
  );
  $("a[href='#reset-password']").on('click', () => {
    $('.nav-tabs li').removeClass('active');
    $('.nav-reset-password')
      .removeClass('hidden')
      .addClass('active');
    $('.nav-sign-up').addClass('hidden');
  });
  $("a[href='#log-in']").on('click', () => {
    $('.nav-tabs li').removeClass('active');
    $('.nav-reset-password').addClass('hidden');
    $('.nav-sign-up').removeClass('hidden');
  });
  $('.for-submit-dropdown li a').click(function() {
    $("input[name='dropdown']").val($(this).text());
    $(this)
      .parents('.dropdown')
      .find('.icon-text')
      .html($(this).text());
  });

  $('#languages-list').on('click', () => {
    $('#language').val($('#chosen-language').text());
  });
  $('.copy-menu-link').on('click', function() {
    let value = $(this)
      .val()
      .split('-');
    if ($(this).prop('tagName') === 'A') {
      value = $(this)
        .parent()
        .attr('id')
        .split('-');
    }
    dashboard.copyLink(value[0], value[1]);
  });
  $('.design-templates').on('click', '.delete-design-template', function() {
    if (window.confirm(i18nClient.t('Are you sure'))) {
      dashboard.deleteTemplate(
        $(this).attr('data-templateId'),
        $(this).parents('.template')
      );
    }
  });
  $('.template-link').on('click', function(e) {
    GA.addToCart(
      $(this).attr('data-menuId'),
      'template',
      $(this).data('price')
    );
    GA.checkoutOption(1, {
      id: $(this).attr('data-menuId'),
      name: `template: ${$(this).attr('data-menuId')}`,
      price: $(this).data('price'),
    });
  });
  $('.menu-format').on('click', dashboard.setMenuFormat);
  $('.delete-user').on('click', function() {
    if (window.confirm('Are you sure you want to delete this user?')) {
      deleteUser($(this).attr('data-userId'), $(this));
    }
  });
  $('#verify-email-btn').on('click', () => {
    sendEmailVerification();
  });
  $('.login-as-user').on('click', function() {
    loginAs($(this).attr('data-userId'), $(this).attr('data-userProvider'));
  });
  $('.design-action-btn[data-action="approve"]').on('click', function() {
    $('#approve-menu-design').attr(
      'data-menu-design-id',
      $(this).attr('data-designId')
    );
    $('#design-name').val(
      $(this)
        .parent('td')
        .siblings('td:first-of-type')
        .contents()[0]
        .textContent.trim()
    );
  });
  $('#approve-menu-design').on('click', function() {
    const name = $('#design-name');
    const categoryPreviewsConfirm = $('#category-previews-confirm');
    let valid = true;
    if (!isValidName(name.val())) {
      name.parent().css('border-bottom-color', 'red');
      valid = false;
    }
    if (!categoryPreviewsConfirm.prop('checked')) {
      categoryPreviewsConfirm
        .siblings('span')
        .css('border-color', 'red')
        .parent()
        .css('color', 'red');
      valid = false;
    }
    if (!valid) return;
    dashboard
      .approveDesign($(this).attr('data-menu-design-id'), name.val())
      .then(() => {
        $('#under-review-tab')
          .find(
            `.design-action-btn[data-designId=${$(this).attr(
              'data-menu-design-id'
            )}]`
          )
          .parents('tr')
          .remove();
        $('#design-options-modal').modal('hide');
        notification.success('Success');
      })
      .catch(() => notification.warning(i18nClient.t('Something went wrong')));
  });
  $('.design-action-btn[data-action="deny"]').on('click', function() {
    const action = $(this).attr('data-action');
    const totalDesigns = $('.total-designs');
    if (window.confirm(`Are you sure you want to ${action} this design?`)) {
      dashboard
        .denyDesign($(this).attr('data-designId'))
        .then(() => {
          $(this)
            .parents('tr')
            .remove();
          totalDesigns.text(totalDesigns.text() - 1);
          notification.success('Success');
        })
        .catch(() =>
          notification.warning(i18nClient.t('Something went wrong'))
        );
    }
  });

  $('.menu-name').focusout(function() {
    if (!$(this).val())
      return notification.warning(i18nClient.t('This field cannot be blank'));
    if (!isValidName($(this).val())) {
      return notification.warning(
        i18nClient.t('This field cannot contain special signs')
      );
    }
    $(this).attr('readonly', true);
    menuStorage.constructor.rename(
      $(this)
        .attr('id')
        .split('-')[1],
      $(this).val()
    );
  });
  $('.toggle-test-user').on('click', function() {
    $.post(`/admin/test/${$(this).attr('data-userId')}`, {
      test: $(this).attr('data-isTest'),
    })
      .done(() => notification.success(i18nClient.t('Saved')))
      .fail(() => notification.error(i18nClient.t('Something went wrong')));
  });
  $('#user-search').focusout(() => {
    $('#search-form').submit();
  });
  $('.active-user-between-dates').on('click', () => {
    const fromDate = $('.from-date-active-users').val();
    const toDate = $('.to-date-active-users').val();
    const moreThan = $('.more-than-active-days').val();
    if (!fromDate || !toDate || !moreThan) {
      return $('#active-users-error-message').text(
        'please fill in all the fields'
      );
    }
    $('#active-users-error-message').text('');
    $.get(`/admin/user_activity/${fromDate}/${toDate}/${moreThan}`, count => {
      $('#count-of-active-users-between-dates').text(count);
    });
  });
  $('.param-value').on('click', function() {
    $('.param-text').text($(this).text());
  });
  $('.registrations-between-dates').on('click', () => {
    const fromDate = $('.from-date-registrations').val();
    const toDate = $('.to-date-registrations').val();
    const paramColumn = $('.param-text').text();
    let paramValue = $('.by-param').val();
    console.log(paramColumn, paramValue);
    if (!fromDate || !toDate) {
      return $('#registrations-error-message').text(
        'please fill in all the fields'
      );
    }
    if (!paramValue) paramValue = 'false';
    $('#registrations-error-message').text('');
    $.get(
      `/admin/registrations/${fromDate}/${toDate}/${paramColumn}/${paramValue}`,
      count => {
        $('#count-of-registrations-between-dates').text(count);
      }
    );
  });
  $('.sm-modal-trigger').on('click', function() {
    const value = $(this)
      .parent()
      .attr('id')
      .split('-');
    updateModalContent(value[1]);
  });

  $('#upload-profile-photo').change(e => {
    uploadPhoto(e.target.files[0]);
  });
  $('.resize-template-prop').on('click', function() {
    $('.menus .resize-template-prop').removeClass('format-to-resize');
    dashboard.disableUsedFormats($(this).parents('.design-templates'));
    $('#resize-menu-parameters')
      .find('.confirm-create-template')
      .attr('id', 'resize-template-btn');
    $(this).addClass('format-to-resize');
  });
  $('.resize-menu-prop').on('click', function() {
    $('.menus .resize-menu-prop').removeClass('format-to-resize');
    $('#resize-menu-parameters')
      .find('.confirm-create-template')
      .attr('id', 'resize-menu-btn');
    $(this).addClass('format-to-resize');
  });
  $('#create-design').on('click', dashboard.createDesign);
  $('tbody')
    .on('click', '.submit-to-review', function() {
      $(this).attr('disabled', true);
      $(this)
        .siblings('.delete-design-btn')
        .attr('disabled', true);
      const designId = $(this).attr('data-menuDesignId');
      dashboard.sendToReview(
        $(this).attr('data-menuDesignId'),
        $(`#status-text-${designId}`)
      );
    })
    .on('click', '.delete-design-btn', function() {
      if (window.confirm('Are you sure you want to delete this design?')) {
        dashboard.deleteDesign(
          $(this).attr('data-menuDesignId'),
          $(this).parents('td')
        );
      }
    })
    .on('click', '.create-template', function() {
      if ($(this).hasClass('disabled')) return;
      dashboard.disableUsedFormats(
        $(this)
          .parent()
          .parent()
      );
      $('#resize-menu-parameters')
        .find('.confirm-create-template')
        .attr('id', 'create-template')
        .attr('data-menuDesignId', $(this).attr('data-menuDesignId'));
    });
  $('#resize-menu-parameters')
    .on('click', '#resize-template-btn', () => {
      dashboard.resizeTempalte({
        id: $('.format-to-resize').attr('data-templateId'),
        selectedFormatName: $(
          '#resize-menu-parameters .menu-format.selected'
        ).attr('data-format'),
      });
    })
    .on('click', '#create-template', function() {
      dashboard.createTemplate(
        $(this).attr('data-menuDesignId'),
        $('#resize-menu-parameters .menu-format.selected').attr('data-format')
      );
    });

  $('.menu-template').on('click', '.menu-name', function() {
    copyLink($(this).text());
    selectText(this);
    notification.success(i18nClient.t('Copied'));
  });

  function GAmarkTemplates() {
    $('.template-link').each(function() {
      if (
        withinviewport($(this)) &&
        !$(this).hasClass('seen') &&
        $(this).find('.price-placeholder').length
      ) {
        $(this).addClass('seen');
        GA.itemImpression({
          id: $(this).attr('data-menuId'),
          name: `template: ${$(this).attr('data-menuId')}`,
          price: $(this).data('price'),
          category: 'template',
        });
      }
    });
  }

  $('.menu-templates').on('mousewheel DOMMouseScroll', () => {
    GAmarkTemplates();
  });
  $(document).ready(() => {
    if (window.location.pathname === '/template') GAmarkTemplates();
  });
  $('#signUpSimple').on('click', e => {
    e.preventDefault();
    if (!$('#accept-privacy-policy').prop('checked')) {
      $('#accept-privacy-policy')
        .siblings('span')
        .css('border-color', 'red')
        .parent()
        .css('color', 'red');
      return;
    }
    $.post('/signup/simple', {
      signUpEmail: $('#simple-sign-up-email').val(),
      sendNewsletters: $('#send-newsletters').val(),
    }).done(res => {
      if (!res.email) {
        $('#email-confirmation-request-modal').modal('show');
        setTimeout(() => window.location.reload(), 4000);
      } else {
        $('#auth-simple').removeClass('active');
        $('#password-simple').addClass('active in');
        $('#log-in-email').val(res.email);
      }
    });
  });
  $('#simple-log-in').on('click', () => {
    $.post('/login', {
      logInEmail: $('#log-in-email').val(),
      logInPassword: $('#log-in-password').val(),
    }).done(() => window.location.reload());
  });
  $('.design-list tr').hover(
    function() {
      $(this)
        .find('.template-sizes')
        .show();
    },
    function() {
      $(this)
        .find('.template-sizes')
        .hide();
    }
  );
  $('.admin-actions .dropdown-menu')
    .on('click', '.toggle-panel', function(e) {
      e.stopPropagation();
      $(this)
        .siblings('.bonus-balance-panel')
        .removeClass('hidden')
        .addClass('open')
        .slideDown('slow');
      $(this).addClass('expanded');
    })
    .on('click', '.toggle-panel.expanded', function(e) {
      e.stopPropagation();
      $(this)
        .siblings('.bonus-balance-panel')
        .removeClass('open')
        .addClass('hidden')
        .slideUp('slow');
      $(this).removeClass('expanded');
    })
    .on('click', '.checkbox-container', e => e.stopPropagation());
  $('.set-bonus-balance').on('click', function() {
    const sum = $(this)
      .siblings()
      .children('.bonus-balance-sum');
    const expiration = $(this)
      .siblings()
      .children('.bonus-balance-expiration');
    const applyToAllOption = $(this)
      .siblings()
      .children()
      .children('.apply-bonus-balance-to-all');
    let setBonusBalance = null;
    let valid = true;
    if (!sum.val() || !expiration.val()) {
      valid = false;
      notification.warning(i18nClient.t('This field cannot be blank'));
    }
    if (!/^[1-9]\d*$/.test(sum.val())) {
      valid = false;
      sum.parent().css('border-bottom-color', 'red');
      notification.warning(i18nClient.t('Something went wrong'));
    }
    if (!valid) return;
    if (applyToAllOption.prop('checked')) {
      setBonusBalance = $.post('/bonus_balance/all', {
        sum: sum.val(),
        expiration: expiration.val(),
      });
    } else {
      setBonusBalance = $.post('/bonus_balance', {
        userProfileId: $(this).attr('data-userId'),
        sum: sum.val(),
        expiration: expiration.val(),
      });
    }
    setBonusBalance
      .done(() => notification.success(i18nClient.t('Saved')))
      .fail(() => notification.error(i18nClient.t('Something went wrong')));
  });
  $(window).bind('load', () => {
    if (cookies.get('sm_account_exists')) {
      cookies.remove('sm_account_exists');
      $('#social-media-modal').modal('show');
    }
  });
}
