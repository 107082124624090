import _ from 'lodash';

export class MenuChange {
  /**
   * @property {array}  changedPages
   * @property {string} changedPages.pageId - canvas id
   * @property {number} changedPages.numberOfChanges - number of changes on a specific page
   */
  constructor() {
    this.changedPages = [];
    this.changedMenuStructure = 0;
  }

  getChangedPagesList() {
    return this.changedPages;
  }

  findPageChange(pageId) {
    return this.changedPages.find(obj => obj.pageId === pageId);
  }

  pushPageChange(fabricObj) {
    const pageId = fabricObj.get('id');
    const pageChange = this.findPageChange(pageId);
    if (pageChange) {
      const index = this.changedPages.findIndex(
        change => change.pageId === pageId
      );
      this.changedPages[index] = {
        pageId,
        numberOfChanges: pageChange.numberOfChanges + 1,
      };
    } else {
      this.changedPages.push({
        pageId,
        numberOfChanges: 1,
      });
    }
  }

  dropChangedPage(pageId) {
    const pageChange = this.findPageChange(pageId);
    if (!pageChange) return;
    _.remove(this.changedPages, change => change.pageId === pageId);
  }

  triggerChangedStructure() {
    this.changedMenuStructure += 1;
  }

  resetChangedPages() {
    this.changedPages = [];
  }

  resetChangedMenuStructure() {
    this.changedMenuStructure = 0;
  }

  resetChanges() {
    this.resetChangedPages();
    this.resetChangedMenuStructure();
  }
}

export const menuChange = new MenuChange();
