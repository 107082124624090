import { canvasPage } from './canvasPage';

export class CanvasManager {
  static getVisibleCanvases(canvases) {
    const visibleCanvases = [];
    canvases.each((index, element) => {
      if (
        !$(element)
          .attr('class')
          .includes('upper-canvas')
      ) {
        // if ($(element).visible(true)) {
        const visibleCanvasHeight =
          $(window).height() - Math.abs(element.getBoundingClientRect().top);
        visibleCanvases.push({
          canvasId: $(element).attr('id'),
          visibleCanvasHeight,
        });
        // }
      }
    });
    return visibleCanvases;
  }

  getActiveCanvas(canvases) {
    const visibleCanvases = this.constructor.getVisibleCanvases(canvases);
    return visibleCanvases.reduce((prev, current) =>
      prev.visibleCanvasHeight > current.visibleCanvasHeight ? prev : current
    );
  }

  current() {
    const activeCanvas = this.getActiveCanvas($('canvas'));
    return canvasPage.findFabricObj(activeCanvas.canvasId);
  }

  highlightActive() {
    const canvases = $('canvas');
    canvases.css('box-shadow', 'none');
    $(`#${this.getActiveCanvas(canvases).canvasId}`).css(
      'box-shadow',
      '0px 1px 30px 0 rgba(46,61,89, 0.7)'
    );
  }
}

export const canvas = new CanvasManager();
