import i18nClient from '../i18nextClient';

export default function confirmPassword(passwordField, confirmedPasswordField) {
  let customValidity = '';

  if (passwordField.val() !== confirmedPasswordField.val()) {
    customValidity = i18nClient.t("Passwords don't match");
  }

  confirmedPasswordField.get(0).setCustomValidity(customValidity);
}
