import { canvasPage } from '../canvasPage';

export function getMinusSign(page, top, left, index, identifier) {
  const minus = new fabric.IText('\u2715');
  minus.set({
    fontSize: 20,
    opacity: 0.5,
    fill: '#d71f49',
    top: top,
    left: left,
    class: 'removeItemBtn',
    identifier: +identifier,
    index: index,
    hasControls: false,
    hasBorders: false,
    lockMovementY: true,
    lockMovementX: true,
  });
  page.add(minus);
}

export function getPlusSign(page, top, left, identifier) {
  const plus = new fabric.Image(canvasPage.getPlusImg());
  plus.set({
    scaleX: 0.055,
    scaleY: 0.055,
    opacity: 0.8,
    top: top,
    left: left,
    class: 'addItemBtn',
    identifier: Number(identifier),
    hasControls: false,
    hasBorders: false,
    lockMovementY: true,
    lockMovementX: true,
  });
  page.add(plus);
}

export function addMinuses(fabricObj, categoryWrapper) {
  const dishNames = fabricObj
    .getObjects()
    .filter(
      obj =>
        obj.identifier === categoryWrapper.identifier &&
        obj.class === 'dishName'
    )
    .sort((a, b) => (a.top > b.top ? 1 : -1));
  dishNames.forEach((dishName, index) =>
    getMinusSign(
      fabricObj,
      dishName.top,
      categoryWrapper.left + categoryWrapper.width / 2 + 15,
      index,
      categoryWrapper.identifier
    )
  );
}

export function parseCategoryPath(path) {
  const categoryData = path
    .slice(path.lastIndexOf('/') + 1, path.lastIndexOf('.'))
    .split('-');
  return {
    type: categoryData[0],
    width: categoryData[1],
  };
}
