import queryString from 'query-string';
import { canvasPage } from './canvasPage';
import { canvas } from './canvasManager';
import { wayforpayCustom } from '../common/wayforpayCustom';
import { order } from './order';
import { addOrUpdateCart } from '../common/mailchimp';

export class PaidIcon {
  constructor() {
    this.usedIcons = new Set();
    this.price = 1;
  }

  static add(src, name) {
    fabric.loadSVGFromURL(src, (objects, options) => {
      const obj = fabric.util.groupSVGElements(objects, options);
      obj.set({
        top: canvas.current().height / 2 - (obj.height * obj.scaleY) / 2,
        left: canvas.current().width / 2 - (obj.width * obj.scaleX) / 2,
        class: 'paidIcon',
        name,
      });
      canvas
        .current()
        .add(obj)
        .setActiveObject(obj);
    });
  }

  setUsedIcon(paidIcon) {
    this.usedIcons.add(paidIcon);
    addOrUpdateCart(
      queryString.parse(window.location.search).menu,
      window.location.href
    );
  }

  getUsedIcons() {
    return this.usedIcons;
  }

  getPrice() {
    return this.price;
  }

  getTotal(currency) {
    return wayforpayCustom.convertCurrency(
      this.price * this.usedIcons.size,
      order.getBaseCurrency(),
      currency
    );
  }

  getPaidIconsFromMenu() {
    this.usedIcons.clear();
    canvasPage.getFabricObjects().forEach(fabricObj => {
      this.setUsedIconsFromPage(fabricObj);
    });
    addOrUpdateCart(
      queryString.parse(window.location.search).menu,
      window.location.href
    );
    return this.usedIcons;
  }

  paidIconsAreInUse() {
    return this.getPaidIconsFromMenu().size > 0;
  }

  setUsedIconsFromPage(fabricObj) {
    fabricObj.getObjects().forEach(obj => {
      if (obj.class === 'paidIcon') this.usedIcons.add(obj.name);
    });
  }

  markAsPurchased() {
    canvasPage.getFabricObjects().forEach(fabricObj => {
      fabricObj.getObjects().forEach(obj => {
        if (obj.class === 'paidIcon') obj.class = 'purchasedIcon';
      });
    });
    this.usedIcons.clear();
  }

  prependPurchasedIcons() {
    this.usedIcons.forEach(icon => {
      $('#free-icons ul').append(
        `<li><img src="/paid_icons/source/${icon}" class="add-icon" width="50" height="50" alt=""></li>`
      );
    });
  }

  getOrderedIconDetails() {
    return {
      names: Array.from(this.usedIcons),
      price: Number(
        (this.getTotal(order.getUserCurrency()) / this.usedIcons.size).toFixed(
          2
        )
      ),
      currency: order.getUserCurrency(),
    };
  }
}

export const paidIcon = new PaidIcon();
