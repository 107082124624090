/* eslint-disable max-classes-per-file */
import { category } from './menu_templates/category';

class ObjectSelection {
  constructor(currentFabricObjects, selectedObject) {
    this.currentFabricObjects = currentFabricObjects;
    this.selectedObject = selectedObject;
  }

  forward() {
    const currentObjIndex = this.currentFabricObjects.indexOf(
      this.selectedObject
    );
    const intersectsObjects = [];

    this.currentFabricObjects.map((obj, index) => {
      if (
        this.selectedObject.intersectsWithObject(obj) &&
        index !== currentObjIndex &&
        index > currentObjIndex
      ) {
        intersectsObjects.push({ obj, index });
      }
    });

    for (let i = 0; i < intersectsObjects.length; i++) {
      if (intersectsObjects[i].obj.identifier) {
        category.constructor
          .getCategoryItems(
            this.currentFabricObjects,
            intersectsObjects[i].obj.identifier
          )
          .forEach(item => item.moveTo(currentObjIndex));
        break;
      } else {
        this.selectedObject.moveTo(intersectsObjects[i].index);
        break;
      }
    }
  }

  backward() {
    const currentObjIndex = this.currentFabricObjects.indexOf(
      this.selectedObject
    );
    const intersectsObjects = [];

    this.currentFabricObjects.map((obj, index) => {
      if (
        this.selectedObject.intersectsWithObject(obj) &&
        index !== currentObjIndex &&
        index < currentObjIndex
      ) {
        intersectsObjects.push({ obj, index });
      }
    });

    for (let i = intersectsObjects.length - 1; i >= 0; i--) {
      if (intersectsObjects[i].obj.identifier) {
        category.constructor
          .getCategoryItems(
            this.currentFabricObjects,
            intersectsObjects[i].obj.identifier
          )
          .forEach(item => item.moveTo(currentObjIndex));
        break;
      } else {
        this.selectedObject.moveTo(intersectsObjects[i].index);
        break;
      }
    }
  }

  bringToFront() {
    this.selectedObject.bringToFront();
  }

  sendToBack() {
    this.selectedObject.sendToBack();
  }
}

class CategorySelection {
  constructor(currentFabricObjects, selectedObject) {
    this.currentFabricObjects = currentFabricObjects;
    this.selectedCategoryItems = category.constructor.getCategoryItems(
      currentFabricObjects,
      selectedObject.identifier
    );
  }

  getMinOrMaxIdxOfCategory(identifier, max) {
    const categoryObjs = [];
    this.currentFabricObjects.forEach((obj, index) => {
      if (obj.identifier === identifier) {
        categoryObjs.push(index);
      }
    });

    return max ? Math.max(...categoryObjs) : Math.min(...categoryObjs);
  }

  forward() {
    const intersectsObjects = [];
    this.currentFabricObjects.map((obj, index) => {
      if (
        this.selectedCategoryItems[0].intersectsWithObject(obj) &&
        this.selectedCategoryItems[0].identifier !== obj.identifier
      ) {
        intersectsObjects.push({ obj, index });
      }
    });

    const minCategoryIdx = this.getMinOrMaxIdxOfCategory(
      this.selectedCategoryItems[0].identifier,
      false
    );

    for (let i = 0; i < intersectsObjects.length; i++) {
      if (
        intersectsObjects[i].index > minCategoryIdx &&
        !intersectsObjects[i].obj.identifier
      ) {
        this.selectedCategoryItems.forEach(el =>
          el.moveTo(intersectsObjects[i].index)
        );
        break;
      } else if (intersectsObjects[i].index > minCategoryIdx) {
        const maxIdx = this.getMinOrMaxIdxOfCategory(
          intersectsObjects[i].obj.identifier,
          true
        );
        this.selectedCategoryItems.forEach(el => el.moveTo(maxIdx));
        break;
      }
    }
  }

  backward() {
    const intersectsObjects = [];
    this.currentFabricObjects.map((obj, index) => {
      if (
        this.selectedCategoryItems[0].intersectsWithObject(obj) &&
        this.selectedCategoryItems[0].identifier !== obj.identifier
      ) {
        intersectsObjects.push({ obj, index });
      }
    });

    const minCategoryIdx = this.getMinOrMaxIdxOfCategory(
      this.selectedCategoryItems[0].identifier,
      false
    );

    for (let i = intersectsObjects.length - 1; i >= 0; i--) {
      if (
        intersectsObjects[i].index < minCategoryIdx &&
        !intersectsObjects[i].obj.identifier
      ) {
        this.selectedCategoryItems.forEach(el =>
          el.moveTo(intersectsObjects[i].index)
        );
        break;
      } else if (intersectsObjects[i].index < minCategoryIdx) {
        const minIdx = this.getMinOrMaxIdxOfCategory(
          intersectsObjects[i].obj.identifier,
          false
        );
        this.selectedCategoryItems.forEach(el => el.moveTo(minIdx));
        break;
      }
    }
  }

  bringToFront() {
    this.selectedCategoryItems.forEach(item => item.bringToFront());
  }

  sendToBack() {
    for (let i = this.selectedCategoryItems.length - 1; i >= 0; i--) {
      this.selectedCategoryItems[i].sendToBack();
    }
  }
}

class GroupSelection {
  constructor(currentFabricObjects, selectedObject) {
    this.currentFabricObjects = currentFabricObjects;
    this.selectedObject = selectedObject;
  }

  forward() {
    const objInGroup = [];
    const intersectsObjects = [];
    this.currentFabricObjects.map((obj, index) => {
      if (obj.group) {
        objInGroup.push(index);
      }
    });
    const minElemIngroup = Math.min(...objInGroup);
    this.currentFabricObjects.map((obj, index) => {
      if (
        this.currentFabricObjects[index].intersectsWithObject(
          this.selectedObject
        ) &&
        index > minElemIngroup
      ) {
        intersectsObjects.push({ obj, index });
      }
    });

    for (let i = 0; i < intersectsObjects.length; i++) {
      if (intersectsObjects[i].obj.identifier) {
        const categoryObjs = [];
        this.currentFabricObjects.forEach((obj, index) => {
          if (obj.identifier === intersectsObjects[i].obj.identifier) {
            categoryObjs.push(index);
          }
        });
        const maxCategoryIdx = Math.max(...categoryObjs);
        this.selectedObject
          .getObjects()
          .forEach(el => el.moveTo(maxCategoryIdx));
        break;
      } else {
        this.selectedObject
          .getObjects()
          .forEach(el => el.moveTo(intersectsObjects[i].index));
        break;
      }
    }
  }

  backward() {
    const objInGroup = [];
    const intersectsObjects = [];
    this.currentFabricObjects.map((obj, index) => {
      if (obj.group) {
        objInGroup.push(index);
      }
    });
    const maxElemIngroup = Math.max(...objInGroup);
    this.currentFabricObjects.map((obj, index) => {
      if (
        this.currentFabricObjects[index].intersectsWithObject(
          this.selectedObject
        ) &&
        index < maxElemIngroup
      ) {
        intersectsObjects.push({ obj, index });
      }
    });

    for (let i = intersectsObjects.length - 1; i >= 0; i--) {
      if (intersectsObjects[i].obj.identifier) {
        const categoryObjs = [];
        this.currentFabricObjects.forEach((obj, index) => {
          if (obj.identifier === intersectsObjects[i].obj.identifier) {
            categoryObjs.push(index);
          }
        });
        const minCategoryIdx = Math.min(...categoryObjs);
        this.selectedObject
          .getObjects()
          .forEach(el => el.moveTo(minCategoryIdx));
        break;
      } else {
        this.selectedObject
          .getObjects()
          .forEach(el => el.moveTo(intersectsObjects[i].index));
        break;
      }
    }
  }

  bringToFront() {
    this.selectedObject.bringToFront();
  }

  sendToBack() {
    this.selectedObject.sendToBack();
  }
}

export function getSelectionType(currentFabricObjects, selectedObject) {
  if (selectedObject.class && selectedObject.class == 'categoryWrapper') {
    return new CategorySelection(currentFabricObjects, selectedObject);
  }
  if (selectedObject.type == 'activeSelection') {
    return new GroupSelection(currentFabricObjects, selectedObject);
  }
  return new ObjectSelection(currentFabricObjects, selectedObject);
}
