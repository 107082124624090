const getControl = type =>
  ({
    'i-text': '.text-properties',
    image: '#images-properties',
    rect: '#shapes-properties',
  }[type]);

export function hideControls() {
  $('.block-collapse').each((i, elem) => {
    $(elem).addClass('collapse-on');
  });
}

export function toggleControls(e) {
  hideControls();
  const control = getControl(
    e.target.identifier && e.target.type === 'rect' ? '' : e.target.type
  );
  $(control).removeClass('collapse-on');
}
