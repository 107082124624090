import cookies from 'js-cookie';
import * as Sentry from '@sentry/browser';
import setUpUserEvents from './events';
import setUpCaptcha from '../captcha';

$(document).ready(() => {
  Sentry.init({
    dsn:
      'https://47d6228a453346d38cefafcb43f465f0@o4504796840525824.ingest.sentry.io/4504796848586752',
    enabled: ENVIRONMENT === 'production',
    release: RELEASE,
  });
  setUpUserEvents();
  setUpCaptcha();

  if ($('#select-country').find('option')) {
    const selectCountry = $('#select-country');
    const selectedOption = Array.from($('option')).find(
      v => v.textContent === selectCountry.attr('data-value')
    );
    selectCountry.val(selectedOption ? selectedOption.value : null);
    $('.chosen-select').chosen({ width: '100%' });
  }

  $('#platform-support').on('click', '.close', () => {
    cookies.set('platform_support_banner', 'true');
    $('.info-banner').hide();
  });
});
