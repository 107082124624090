import i18nClient from '../i18nextClient';
import 'chosen-js/chosen.jquery.min';
import 'chosen-js/chosen.css';
import { sliderGradient } from '../editor/helpers';
import * as notification from './notifications';
import { copyLink } from '../editor/generalEditing';
import * as freeSample from './freeSample';

export default function() {
  $('#order-address-modal').on('shown.bs.modal', () => {
    const modalContent = $('#order-address-modal').find('.modal-content');
    $('.free-sample-video').animate({
      left: -$('.free-sample-video').width(),
    });
    modalContent.animate({
      left: $('.free-sample-video').width() / 2,
    });
  });

  $('#order-free-button').on('click', () => {
    freeSample.showOrderWindow();
  });

  $('#order-free-banner').on('click', () => {
    freeSample.showOrderWindow();
    $.post('/profile/seen_banner', () => {
      $('.fs-banner').hide();
    });
  });

  $('.print-validation-btn').on('click', e => {
    e.preventDefault();
    $('#print-validation-modal').modal('show');
  });

  $('#order-city').on('input', function() {
    freeSample.showAvailableWarehouses($(this).val());
  });
  $('#order-free-sample').on('submit', function(e) {
    e.preventDefault();
    freeSample.create($(this).serialize());
  });
  $('#close-fs-banner').on('click', () => {
    $.post('/profile/seen_banner', () => {
      $('.fs-banner').hide();
    });
  });
  $('.dropdown').on('click', '.dropdown-menu li a', function() {
    $(this)
      .parents('.dropdown')
      .find('.dropdown-toggle')
      .val($(this).text())
      .find('.icon-text')
      .html($(this).text());
  });
  $('.slider').on('input', sliderGradient);
  $('#copy-shared-link').on('click', () => {
    copyLink($('.menu-link').val());
    $('.menu-link').select();
    notification.success(i18nClient.t('Copied'));
  });
  $('#copy-embed-code').on('click', () => {
    copyLink($('.embed-code').val());
    $('.embed-code').select();
    notification.success(i18nClient.t('Copied'));
  });
  $('.shared-link').on('click', function() {
    copyLink($(this).val());
    $(this).select();
    notification.success(i18nClient.t('Copied'));
  });
  $('#switch-to-us-sizes').on('click', () => {
    $('#eu-sizes-tab')
      .hide(1)
      .find('.menu-format')
      .removeClass('selected');
    $('#us-sizes-tab').show(3);
    $('#us-legal-0f').addClass('selected');
  });
  $('#switch-to-eu-sizes').on('click', () => {
    $('#us-sizes-tab')
      .hide(1)
      .find('.menu-format')
      .removeClass('selected');
    $('#eu-sizes-tab').show(3);
    $('#a4-0f').addClass('selected');
  });
}
