import _ from 'lodash';
import { canvas } from './canvasManager';
import { canvasPage } from './canvasPage';
import { canvasHistory } from './canvasHistory';
import { drag, drop } from './dragAndDrop';
import { moveCategory } from './menu_templates/moveCategory';
import { categoryItemBtn } from './menu_templates/changeCategoryItem';
import { hideControls, toggleControls } from './editingControlsManipulation';
import { showCoordinates, showAllProperties } from './generalEditing';
import { clearValues } from './helpers';
import { category } from './menu_templates/category';
import { templatesManager } from './menu_templates/templatesManager';
import { grid } from './grid';
import { menuChange } from './menuChange';

let dragObj = {};
let mouseIsDown = false;
let objScaling = false;
let isSelecting = false;

const onSelection = function(e, fabricObj) {
  category.constructor.toggleConfigurationTab(e.target.identifier);
  showAllProperties();
  toggleControls(e);
  categoryItemBtn.constructor.show(e.target, fabricObj);
  showCoordinates(e.target);
};

const setUpCanvasPageEvents = fabricCanvasPageObj => {
  // set up events for each menu page
  fabricCanvasPageObj
    .on('object:modified', obj => {
      if (isSelecting) {
        isSelecting = false;
      } else {
        fabricCanvasPageObj.fire('page:modified', obj);
      }
    })
    .on('object:removed', obj => {
      fabricCanvasPageObj.fire('page:modified', obj);
    })
    .on('object:added', obj => {
      fabricCanvasPageObj.fire('page:modified', obj);
    })
    .on('object:scaling', () => {
      objScaling = true;
    })
    .on('object:moving', e => {
      if (grid.isOn()) grid.snapToGrid(e.target);
    })
    .on('page:modified', obj => {
      // eslint-disable-next-line no-mixed-operators
      const targerClass = (obj && obj.target && obj.target.class) || null;
      if (
        window.isLoadingFromJSON ||
        targerClass === 'addItemBtn' ||
        targerClass === 'removeItemBtn'
      )
        return;
      canvasHistory.addState(canvasPage.toJSON(fabricCanvasPageObj));
      menuChange.pushPageChange(fabricCanvasPageObj);
    });

  fabricCanvasPageObj
    .on('mouse:over', e => {
      if (e.target && e.target.class === 'removeItemBtn') {
        e.target.set('opacity', '1');
        canvas.current().requestRenderAll();
      }
      if (!_.isEmpty(dragObj) && mouseIsDown) {
        drop(e.e.toElement.offsetParent.firstChild.id); // get canvas id
      }
    })
    .on('mouse:out', e => {
      if (e.target && e.target.class === 'removeItemBtn') {
        e.target.set('opacity', '0.5');
        canvas.current().requestRenderAll();
      }
      if (
        mouseIsDown &&
        fabricCanvasPageObj.getActiveObjects() &&
        fabricCanvasPageObj.getActiveObjects().length === 1 &&
        !objScaling
      ) {
        // if not scaling
        if (_.isEmpty(dragObj)) dragObj = e.target;
        drag(dragObj, e.e.fromElement.offsetParent.firstChild.id); // get canvas id
      }
    })
    .on('mouse:down', function(e) {
      mouseIsDown = true;
      objScaling = false;
      moveCategory.mouseIsDown(this);
      categoryItemBtn.changeItem(e, this);
    })
    .on('mouse:move', function(e) {
      if (!moveCategory.isMouseDown()) return;
      showCoordinates(e.target);
      const activeObjects = fabricCanvasPageObj.getActiveObjects();
      const activeCategoryObjects = activeObjects.filter(obj => obj.identifier);

      if (activeCategoryObjects.length >= 1 && activeObjects.length >= 2) {
        const toBeSelected = fabricCanvasPageObj
          .getObjects()
          .filter(
            obj =>
              activeObjects.includes(obj) ||
              (obj.identifier &&
                _.find(activeCategoryObjects, { identifier: obj.identifier }))
          );

        if (toBeSelected.length === activeObjects.length) return;

        const emptySelection = new fabric.ActiveSelection([], {
          canvas: fabricCanvasPageObj,
        });
        fabricCanvasPageObj.setActiveObject(emptySelection);
        const selection = new fabric.ActiveSelection(toBeSelected, {
          canvas: fabricCanvasPageObj,
        });
        fabricCanvasPageObj.setActiveObject(selection);
      }
      moveCategory.move(this);
    })
    .on('mouse:up', function() {
      mouseIsDown = false;
      dragObj = {};
      moveCategory.mouseIsUP(this);
    });

  fabricCanvasPageObj.on('text:changed', e => {
    if (!templatesManager.isDesignerMode() || !e.target.identifier) return;
    category.edit(
      { property: 'defaultValue', value: e.target.text },
      (fabricObj, filteredItems, value) => {
        filteredItems.set('text', e.target.text);
      }
    );
  });
  fabricCanvasPageObj
    .on('selection:created', function(e) {
      isSelecting = true;
      onSelection(e, this);
    })
    .on('selection:updated', function(e) {
      isSelecting = true;
      onSelection(e, this);
      if (e.target.class === 'removeItemBtn') return;
      categoryItemBtn.constructor.hide(this);
      categoryItemBtn.constructor.show(e.target, this);
    })
    .on('selection:cleared', function() {
      isSelecting = false;
      $('.toggle-border').prop('checked', false);
      $('#toggle-shadow').prop('checked', false);
      $('#border').hide();
      $('#shadow').hide();
      clearValues('#shadow-size', '#shadow-size-value', '0px');
      clearValues('.border-width', '.border-width-value', '0px');
      $('.top-coordinate').val('0');
      $('.left-coordinate').val('0');
      categoryItemBtn.constructor.hide(this);
      hideControls();
    });

  fabricCanvasPageObj.on('text:changed', e => {
    if (!templatesManager.isDesignerMode() || !e.target.identifier) return;
    category.edit(
      { property: 'defaultValue', value: e.target.text },
      (fabricObj, filteredItems, value) => {
        filteredItems.set('text', e.target.text);
      }
    );
  });
};

export default setUpCanvasPageEvents;
