import mustache from 'mustache';
import _ from 'lodash';
import { canvas } from '../canvasManager';
import { canvasPage } from '../canvasPage';
import { setCustomTextEditingBorder } from '../helpers';
import { templatesManager } from './templatesManager';
import * as notification from '../../common/notifications';
import i18nClient from '../../i18nextClient';

export class Category {
  constructor() {
    this.defaultConfigurations = {
      type1: {
        categoryWrapper: {
          fill: 'rgba(0, 0, 0, 0)',
          stroke: '',
          strokeDashArray: [],
          rx: 0,
          ry: 0,
          opacity: 0,
        },
        border1: {
          fill: '#000000',
          height: 4,
          stroke: '',
          strokeDashArray: [],
          strokeWidth: 1,
          topOffset: 0,
        },
        categoryName: {
          defaultValue: 'CATEGORY',
          fontSize: 15,
          fontFamily: 'Open Sans',
          fontStyle: '',
          fill: '#000000',
          fontWeight: 'bold',
          originX: 'center',
          charSpacing: 250,
          topOffset: 13,
          leftMultiplier: 0,
        },
        item: {
          separatingLine: {
            fill: '#000000',
            height: 1,
            stroke: '',
            strokeDashArray: [],
            topOffset: -7,
            widthMultiplier: 0,
          },
          dishName: {
            defaultValue: 'Name',
            fontSize: 11,
            fontWeight: 'bold',
            fontStyle: 'normal',
            fontFamily: 'Roboto',
            lineHeight: 1.15,
            fill: '#000000',
            originX: 'left',
            leftMultiplier: 0,
          },
          weight: {
            defaultValue: '100',
            fontSize: 8,
            fontStyle: 'italic',
            fontFamily: 'Roboto',
            fill: '#000000',
            originX: 'right',
            topOffset: 2,
            leftMultiplier: -6.8,
          },
          price: {
            defaultValue: '25',
            fontSize: 13,
            fontWeight: 'bold',
            fontFamily: 'Roboto',
            fill: '#000000',
            topOffset: -2,
            originX: 'right',
            leftMultiplier: -33,
          },
          description: {
            defaultValue: 'one-line description',
            fontSize: 8,
            fontFamily: 'Roboto',
            lineHeight: 1.15,
            fill: '#000000',
            fontStyle: 'italic',
            topOffset: 13,
            originX: 'left',
            leftMultiplier: 0,
          },
          step: 40,
          topOffset: 45,
          leftOffset: 0,
          widthOffset: 0,
        },
        width: 228,
        remainingHeight: 1.25 * 40,
        baseType: 'withUnderlinedName',
        customType: 'type1',
      },
      type2: {
        categoryWrapper: {
          fill: 'rgba(0, 0, 0, 0)',
          stroke: '',
          strokeDashArray: [],
          rx: 0,
          ry: 0,
          opacity: 0,
        },
        border1: {
          fill: '#000000',
          height: 1,
          stroke: '',
          strokeDashArray: [],
          strokeWidth: 1,
          topOffset: 36,
        },
        categoryName: {
          defaultValue: 'CATEGORY',
          fontSize: 15,
          fontFamily: 'Open Sans',
          fontStyle: 'normal',
          fontWeight: 'bold',
          fill: '#000000',
          charSpacing: 250,
          originX: 'center',
          topOffset: 13,
          leftMultiplier: 0,
        },
        item: {
          dishName: {
            defaultValue: 'Name',
            fontSize: 10,
            fontWeight: 'normal',
            fontStyle: 'italic',
            fontFamily: 'Roboto',
            lineHeight: 1.15,
            fill: '#000000',
            originX: 'left',
            leftMultiplier: 0,
          },
          weight: {
            defaultValue: '100',
            fontSize: 8,
            fontStyle: 'italic',
            fontFamily: 'Roboto',
            fill: '#000000',
            originX: 'right',
            topOffset: 1,
            leftMultiplier: -6.8,
          },
          price: {
            defaultValue: '25',
            fontSize: 11,
            fontWeight: 'bold',
            fontFamily: 'Roboto',
            fill: '#000000',
            topOffset: -1,
            originX: 'right',
            leftMultiplier: -33,
          },
          step: 18,
          topOffset: 45,
          leftOffset: 0,
          widthOffset: 0,
        },
        width: 228,
        remainingHeight: 3.5 * 18,
        baseType: 'withUnderlinedName',
        customType: 'type2',
      },
      type3: {
        categoryWrapper: {
          fill: 'rgba(0, 0, 0, 0)',
          stroke: '',
          strokeDashArray: [],
          rx: 0,
          ry: 0,
          opacity: 0,
        },
        border1: {
          fill: '#000000',
          height: 4,
          stroke: '',
          strokeDashArray: [],
          topOffset: 0,
          strokeWidth: 1,
        },
        border2: {
          fill: '#000000',
          height: 1,
          stroke: '',
          strokeDashArray: [],
          topOffset: 48,
        },
        categoryName: {
          defaultValue: 'CATEGORY',
          fontSize: 15,
          fontFamily: 'Open Sans',
          fontStyle: 'normal',
          fontWeight: 'bold',
          fill: '#000000',
          charSpacing: 250,
          originX: 'center',
          topOffset: 15,
          leftMultiplier: 0,
        },
        item: {
          dishName: {
            defaultValue: 'Name',
            fontSize: 11,
            fontWeight: 'bold',
            fontStyle: 'normal',
            fontFamily: 'Roboto',
            lineHeight: 1.15,
            fill: '#000000',
            originX: 'left',
            leftMultiplier: 0,
          },
          weight: {
            defaultValue: '100',
            fontSize: 8,
            fontStyle: 'italic',
            fontFamily: 'Roboto',
            fill: '#000000',
            originX: 'right',
            topOffset: 2,
            leftMultiplier: -6.8,
          },
          price: {
            defaultValue: '25',
            fontSize: 13,
            fontWeight: 'bold',
            topOffset: -2,
            fontFamily: 'Roboto',
            fill: '#000000',
            originX: 'right',
            leftMultiplier: -33,
          },
          step: 24,
          topOffset: 55,
          leftOffset: 0,
          widthOffset: 0,
        },
        width: 228,
        remainingHeight: 2.9 * 24,
        baseType: 'withDoubleUnderlinedName',
        customType: 'type3',
      },
      type4: {
        categoryWrapper: {
          fill: 'rgba(0, 0, 0, 0)',
          stroke: '#000000',
          strokeDashArray: [1, 3],
          rx: 5,
          ry: 5,
          opacity: 1,
        },
        item: {
          dishName: {
            defaultValue: 'name',
            fontSize: 10,
            fontWeight: 'normal',
            fontStyle: 'normal',
            fontFamily: 'Roboto',
            lineHeight: 1.15,
            fill: '#000000',
            originX: 'left',
            leftMultiplier: 10,
          },
          weight: {
            defaultValue: '25',
            fontSize: 8,
            fontStyle: 'italic',
            fontFamily: 'Roboto',
            fill: '#000000',
            originX: 'right',
            topOffset: 1,
            leftMultiplier: -4,
          },
          price: {
            defaultValue: '25',
            fontSize: 11,
            fontWeight: 'normal',
            fontFamily: 'Roboto',
            fill: '#000000',
            topOffset: -1,
            originX: 'right',
            leftMultiplier: -9.5,
          },
          step: 18,
          topOffset: 15,
          leftOffset: 0,
          widthOffset: 0,
        },
        width: 220,
        remainingHeight: 1.25 * 18,
        baseType: 'simple',
        customType: 'type4',
      },
      type5: {
        categoryWrapper: {
          fill: 'rgba(0, 0, 0, 0)',
          stroke: '',
          strokeDashArray: [],
          rx: 0,
          ry: 0,
          opacity: 0,
        },
        categoryName: {
          defaultValue: 'Category',
          fontSize: 17,
          fontFamily: 'Pattaya',
          fontStyle: 'italic',
          fill: '#000000',
          fontWeight: '500',
          originX: 'left',
          charSpacing: 100,
          topOffset: 4,
          leftMultiplier: 0,
        },
        item: {
          dishName: {
            defaultValue: 'Name',
            fontSize: 13,
            fontWeight: 'normal',
            fontStyle: 'normal',
            fontFamily: 'Roboto',
            fill: '#000000',
            originX: 'left',
            leftMultiplier: 0,
          },
          weight: {
            defaultValue: '100',
            fontSize: 10,
            fontStyle: 'italic',
            fontFamily: 'Roboto',
            fill: '#000000',
            originX: 'right',
            topOffset: 2,
            leftMultiplier: -6.8,
          },
          price: {
            defaultValue: '25',
            fontSize: 16,
            fontWeight: 'normal',
            fontFamily: 'Roboto',
            fill: '#000000',
            topOffset: -4,
            originX: 'right',
            leftMultiplier: -40,
          },
          description: {
            defaultValue: 'one-line description',
            fontSize: 10,
            topOffset: 15,
            fontFamily: 'Roboto',
            lineHeight: 1.15,
            fill: '#000000',
            fontStyle: 'italic',
            originX: 'left',
            leftMultiplier: 0,
          },
          step: 38,
          topOffset: 40,
          leftOffset: 0,
          widthOffset: 0,
        },
        width: 230,
        remainingHeight: 1.27 * 38,
        baseType: 'justWithName',
        customType: 'type5',
      },
      type6: {
        categoryWrapper: {
          fill: 'rgba(0, 0, 0, 0)',
          stroke: '',
          strokeDashArray: [],
          rx: 0,
          ry: 0,
          opacity: 0,
        },
        item: {
          dishName: {
            defaultValue: 'Name',
            fontSize: 13,
            fontWeight: 'normal',
            fontStyle: 'normal',
            fontFamily: 'Roboto',
            fill: '#000000',
            originX: 'left',
            leftMultiplier: 0,
          },
          weight: {
            defaultValue: '100',
            fontSize: 10,
            fontStyle: 'italic',
            fontFamily: 'Roboto',
            fill: '#000000',
            originX: 'right',
            topOffset: 2,
            leftMultiplier: -6.8,
          },
          price: {
            defaultValue: '25',
            fontSize: 16,
            fontWeight: 'normal',
            fontFamily: 'Roboto',
            fill: '#000000',
            topOffset: -4,
            originX: 'right',
            leftMultiplier: -40,
          },
          description: {
            defaultValue: 'one-line description',
            fontSize: 8,
            topOffset: 15,
            fontFamily: 'Roboto',
            lineHeight: 1.15,
            fill: '#000000',
            fontStyle: 'italic',
            originX: 'left',
            leftMultiplier: 0,
          },
          step: 38,
          topOffset: 10,
          leftOffset: 0,
          widthOffset: 0,
        },
        width: 230,
        remainingHeight: 0.7 * 38,
        baseType: 'simple',
        customType: 'type6',
      },
      type7: {
        categoryWrapper: {
          fill: 'rgba(0, 0, 0, 0)',
          stroke: '',
          strokeDashArray: [],
          rx: 0,
          ry: 0,
          opacity: 0,
        },
        categoryName: {
          defaultValue: 'CATEGORY',
          fontSize: 18,
          fontFamily: 'Neucha',
          fontStyle: 'normal',
          fill: '#000000',
          fontWeight: 'normal',
          charSpacing: '',
          topOffset: 12,
          originX: 'center',
          leftMultiplier: 0,
        },
        item: {
          dishName: {
            defaultValue: 'Name',
            fontSize: 13,
            fontWeight: 'normal',
            fontStyle: 'normal',
            fontFamily: 'Neucha',
            fill: '#000000',
            originX: 'right',
            leftMultiplier: -32,
          },
          weight: {
            defaultValue: '0.33 / 0.5 / 1  -',
            fontSize: 11,
            fontStyle: 'normal',
            fontFamily: 'Neucha',
            fill: '#000000',
            originX: 'right',
            topOffset: 20,
            leftMultiplier: -2.25,
          },
          price: {
            defaultValue: '15 / 25 / 35',
            fontSize: 11,
            fontWeight: 'normal',
            fontFamily: 'Neucha',
            fill: '#000000',
            topOffset: 20,
            originX: 'right',
            leftMultiplier: -32,
          },
          step: 43,
          topOffset: 40,
          leftOffset: 0,
          widthOffset: 0,
        },
        width: 180,
        remainingHeight: 1.35 * 43,
        baseType: 'justWithName',
        customType: 'type7',
      },
      type8: {
        categoryWrapper: {
          fill: 'rgba(0, 0, 0, 0)',
          stroke: '',
          strokeDashArray: [],
          rx: 0,
          ry: 0,
          opacity: 0,
        },
        item: {
          dishName: {
            defaultValue: 'Name',
            fontSize: 13,
            fontWeight: 'normal',
            fontStyle: 'normal',
            fontFamily: 'Neucha',
            fill: '#000000',
            originX: 'right',
            leftMultiplier: -32,
          },
          weight: {
            defaultValue: '0.33 / 0.5 / 1  -',
            fontSize: 11,
            fontStyle: 'normal',
            fontFamily: 'Neucha',
            fill: '#000000',
            originX: 'right',
            topOffset: 20,
            leftMultiplier: -2.25,
          },
          price: {
            defaultValue: '15 / 25 / 35',
            fontSize: 11,
            fontWeight: 'normal',
            fontFamily: 'Neucha',
            fill: '#000000',
            topOffset: 20,
            originX: 'right',
            leftMultiplier: -32,
          },
          step: 43,
          topOffset: 10,
          leftOffset: 0,
          widthOffset: 0,
        },
        width: 180,
        remainingHeight: 0.7 * 43,
        baseType: 'simple',
        customType: 'type8',
      },
      type9: {
        categoryWrapper: {
          fill: 'rgba(0, 0, 0, 0)',
          stroke: '',
          strokeDashArray: [],
          rx: 0,
          ry: 0,
          opacity: 0,
        },
        categoryName: {
          defaultValue: 'Category',
          fontSize: 24,
          fontFamily: 'Open Sans',
          fontStyle: 'italic',
          fill: '#000000',
          fontWeight: '100',
          originX: 'left',
          charSpacing: 100,
          topOffset: 4,
          leftMultiplier: 0,
        },
        item: {
          dishName: {
            defaultValue: 'Name',
            fontSize: 14,
            fontWeight: 'bold',
            fontStyle: 'normal',
            fontFamily: 'Open Sans',
            fill: '#000000',
            originX: 'left',
            leftMultiplier: 0,
          },
          weight: {
            defaultValue: '100',
            fontSize: 14,
            fontStyle: 'italic',
            fontFamily: 'Open Sans',
            fill: '#000000',
            originX: 'right',
            topOffset: 40,
            leftMultiplier: -6.8,
          },
          price: {
            defaultValue: '25',
            fontSize: 14,
            fontWeight: 'normal',
            fontFamily: 'Open Sans',
            fill: '#000000',
            topOffset: 40,
            originX: 'right',
            leftMultiplier: -40,
          },
          description: {
            defaultValue: 'one-line description',
            fontSize: 14,
            topOffset: 20,
            fontFamily: 'Open Sans',
            lineHeight: 1.15,
            fill: '#000000',
            fontStyle: 'italic',
            originX: 'left',
            leftMultiplier: 0,
          },
          step: 60,
          topOffset: 40,
          leftOffset: 0,
          widthOffset: 0,
        },
        width: 230,
        remainingHeight: 50 + 10,
        baseType: 'justWithName',
        customType: 'type9',
      },
      type10: {
        categoryWrapper: {
          fill: 'rgba(0, 0, 0, 0)',
          stroke: '',
          strokeDashArray: [],
          rx: 0,
          ry: 0,
          opacity: 0,
        },
        categoryName: {
          defaultValue: 'Category',
          fontSize: 15,
          fontFamily: 'Open Sans',
          fontStyle: 'normal',
          fill: '#000000',
          fontWeight: '500',
          originX: 'center',
          charSpacing: 100,
          topOffset: 4,
          leftMultiplier: 0,
        },
        item: {
          dishName: {
            defaultValue: 'Name',
            fontSize: 11,
            fontWeight: 'normal',
            fontStyle: 'normal',
            fontFamily: 'Open Sans',
            fill: '#000000',
            originX: 'left',
            leftMultiplier: 0,
          },
          price: {
            defaultValue: '25',
            fontSize: 14,
            fontWeight: 'normal',
            fontFamily: 'Open Sans',
            fill: '#000000',
            topOffset: -3,
            originX: 'right',
            leftMultiplier: -40,
          },
          description: {
            defaultValue: 'one-line description',
            fontSize: 8,
            topOffset: 15,
            fontFamily: 'Open Sans',
            lineHeight: 1.15,
            fill: '#000000',
            fontStyle: 'italic',
            originX: 'left',
            leftMultiplier: 0,
          },
          step: 37,
          topOffset: 30,
          leftOffset: 0,
          widthOffset: 0,
        },
        width: 230,
        remainingHeight: 1.27 * 37,
        baseType: 'justWithName',
        customType: 'type10',
      },
      type11: {
        categoryWrapper: {
          fill: 'rgba(0, 0, 0, 0)',
          stroke: '',
          strokeDashArray: [],
          rx: 0,
          ry: 0,
          opacity: 0,
        },
        border1: {
          fill: '#000000',
          height: 3,
          stroke: '',
          strokeDashArray: [],
          topOffset: 30,
          strokeWidth: 1,
        },
        categoryName: {
          defaultValue: 'Category',
          fontSize: 18,
          fontFamily: 'Open Sans',
          fontStyle: 'normal',
          fill: '#000000',
          fontWeight: '500',
          originX: 'left',
          charSpacing: 100,
          topOffset: 4,
          leftMultiplier: 0,
        },
        item: {
          dishName: {
            defaultValue: 'Name',
            fontSize: 11,
            fontWeight: 'normal',
            fontStyle: 'normal',
            fontFamily: 'Open Sans',
            fill: '#000000',
            originX: 'left',
            leftMultiplier: 0,
          },
          price: {
            defaultValue: '25',
            fontSize: 14,
            fontWeight: 'normal',
            fontFamily: 'Open Sans',
            fill: '#000000',
            topOffset: -2,
            originX: 'right',
            leftMultiplier: -40,
          },
          description: {
            defaultValue: 'one-line description',
            fontSize: 8,
            topOffset: 13,
            fontFamily: 'Open Sans',
            lineHeight: 1.15,
            fill: '#000000',
            fontStyle: 'italic',
            originX: 'left',
            leftMultiplier: 0,
          },
          step: 35,
          topOffset: 40,
          leftOffset: 0,
          widthOffset: 0,
        },
        width: 230,
        remainingHeight: 1.27 * 35,
        baseType: 'withUnderlinedName',
        customType: 'type11',
      },
      type12: {
        categoryWrapper: {
          fill: 'rgba(0, 0, 0, 0)',
          stroke: '',
          strokeDashArray: [],
          rx: 0,
          ry: 0,
          opacity: 0,
        },
        item: {
          dishName: {
            defaultValue: 'Name',
            fontSize: 13,
            fontWeight: 'normal',
            fontStyle: 'normal',
            fontFamily: 'Marmelad',
            fill: '#000000',
            originX: 'left',
            leftMultiplier: 0,
          },
          weight: {
            defaultValue: '100',
            fontSize: 10,
            fontStyle: 'normal',
            fontFamily: 'Marmelad',
            fill: '#000000',
            originX: 'right',
            topOffset: 3,
            leftMultiplier: -9,
          },
          price: {
            defaultValue: '25',
            fontSize: 13,
            fontWeight: 'normal',
            fontFamily: 'Marmelad',
            fill: '#000000',
            topOffset: 0,
            originX: 'right',
            leftMultiplier: 0,
          },
          description: {
            defaultValue:
              'Lorem ipsum dolor sit amet, vide admodum \ntemporibus qui et, eam ad populo doctus',
            fontSize: 11,
            fontFamily: 'Marmelad',
            fill: '#333434',
            fontStyle: 'normal',
            lineHeight: 1.15,
            topOffset: 17,
            originX: 'left',
            leftMultiplier: 0,
          },
          step: 55,
          topOffset: 10,
          leftOffset: 0,
          widthOffset: 0,
        },
        width: 230,
        remainingHeight: 0.5 * 55,
        baseType: 'simple',
        customType: 'type12',
      },
      type13: {
        categoryWrapper: {
          fill: 'rgba(0, 0, 0, 0)',
          stroke: '',
          strokeDashArray: [],
          rx: 0,
          ry: 0,
          opacity: 0,
        },
        categoryName: {
          defaultValue: 'CATEGORY',
          fontSize: 15,
          fontFamily: 'Open Sans',
          fontStyle: 'normal',
          fontWeight: 'bold',
          fill: '#000000',
          charSpacing: 250,
          originX: 'center',
          topOffset: 5,
          leftMultiplier: 0,
        },
        item: {
          dishName: {
            defaultValue: 'Name',
            fontSize: 11,
            fontWeight: 'bold',
            fontStyle: 'normal',
            fontFamily: 'Roboto',
            lineHeight: 1.15,
            fill: '#000000',
            originX: 'left',
            leftMultiplier: 0,
          },
          price: {
            defaultValue: '25',
            fontSize: 13,
            fontWeight: 'bold',
            topOffset: -2,
            fontFamily: 'Roboto',
            fill: '#000000',
            originX: 'right',
            leftMultiplier: -33,
          },
          step: 26,
          topOffset: 40,
          leftOffset: 0,
          widthOffset: 0,
        },
        width: 228,
        remainingHeight: 1.8 * 26,
        baseType: 'justWithName',
        customType: 'type13',
      },
      type14: {
        categoryWrapper: {
          fill: 'rgba(0, 0, 0, 0)',
          stroke: '',
          strokeDashArray: [],
          rx: 0,
          ry: 0,
          opacity: 0,
        },
        item: {
          dishName: {
            defaultValue: 'Name',
            fontSize: 11,
            fontWeight: 'bold',
            fontStyle: 'normal',
            fontFamily: 'Roboto',
            lineHeight: 1.15,
            fill: '#000000',
            originX: 'left',
            leftMultiplier: 0,
          },
          price: {
            defaultValue: '25',
            fontSize: 13,
            fontWeight: 'bold',
            topOffset: -2,
            fontFamily: 'Roboto',
            fill: '#000000',
            originX: 'right',
            leftMultiplier: -33,
          },
          step: 26,
          topOffset: 10,
          leftOffset: 0,
          widthOffset: 0,
        },
        width: 228,
        remainingHeight: 0.8 * 26,
        baseType: 'simple',
        customType: 'type14',
      },
      type15: {
        categoryWrapper: {
          fill: 'rgba(0, 0, 0, 0)',
          stroke: '',
          strokeDashArray: [],
          rx: 0,
          ry: 0,
          opacity: 0,
        },
        item: {
          dishName: {
            defaultValue: 'Name',
            fontSize: 11,
            fontWeight: 'bold',
            fontStyle: 'normal',
            fontFamily: 'Open Sans',
            fill: '#000000',
            originX: 'center',
            leftMultiplier: 0,
          },
          price: {
            defaultValue: '25',
            fontSize: 13,
            fontWeight: 'normal',
            fontFamily: 'Open Sans',
            fill: '#000000',
            topOffset: 30,
            originX: 'center',
            leftMultiplier: 0,
          },
          description: {
            defaultValue: 'one-line description',
            fontSize: 11,
            topOffset: 13,
            fontFamily: 'Open Sans',
            lineHeight: 1.15,
            fill: '#000000',
            fontStyle: 'italic',
            originX: 'center',
            leftMultiplier: 0,
          },
          step: 50,
          topOffset: 10,
          leftOffset: 0,
          widthOffset: 0,
        },
        width: 230,
        remainingHeight: 0.5 * 50,
        baseType: 'simple',
        customType: 'type15',
      },
      type16: {
        categoryWrapper: {
          fill: 'rgba(0, 0, 0, 0)',
          stroke: '',
          strokeDashArray: [],
          rx: 0,
          ry: 0,
          opacity: 0,
        },
        item: {
          dishName: {
            defaultValue: 'Name',
            fontSize: 13,
            lineHeight: 1.15,
            fontWeight: 'normal',
            fontStyle: 'normal',
            fontFamily: 'Roboto',
            fill: '#000000',
            originX: 'left',
            leftMultiplier: 0,
          },
          weight: {
            defaultValue: '100',
            fontSize: 10,
            fontStyle: 'italic',
            fontFamily: 'Roboto',
            fill: '#000000',
            originX: 'right',
            topOffset: 2,
            leftMultiplier: -6.8,
          },
          price: {
            defaultValue: '25',
            fontSize: 16,
            fontWeight: 'normal',
            fontFamily: 'Roboto',
            fill: '#000000',
            topOffset: -4,
            originX: 'right',
            leftMultiplier: -40,
          },
          step: 35,
          topOffset: 10,
          leftOffset: 0,
          widthOffset: 0,
        },
        width: 230,
        remainingHeight: 0.5 * 35,
        baseType: 'simple',
        customType: 'type16',
      },
      type17: {
        categoryWrapper: {
          fill: 'rgba(0, 0, 0, 0)',
          stroke: '',
          strokeDashArray: [],
          rx: 0,
          ry: 0,
          opacity: 0,
        },
        item: {
          dishName: {
            defaultValue: 'Name',
            fontSize: 11,
            fontWeight: 'normal',
            fontStyle: 'normal',
            fontFamily: 'Open Sans',
            fill: '#000000',
            originX: 'left',
            leftMultiplier: 0,
          },
          price: {
            defaultValue: '25',
            fontSize: 14,
            fontWeight: 'normal',
            fontFamily: 'Open Sans',
            fill: '#000000',
            topOffset: -3,
            originX: 'right',
            leftMultiplier: -40,
          },
          description: {
            defaultValue: 'one-line description',
            fontSize: 9,
            topOffset: 15,
            fontFamily: 'Open Sans',
            lineHeight: 1.15,
            fill: '#000000',
            fontStyle: 'normal',
            originX: 'left',
            leftMultiplier: 0,
          },
          step: 37,
          topOffset: 7,
          leftOffset: 0,
          widthOffset: 0,
        },
        width: 230,
        remainingHeight: 0.5 * 37,
        baseType: 'simple',
        customType: 'type17',
      },
      type18: {
        categoryWrapper: {
          fill: 'rgba(0, 0, 0, 0)',
          stroke: '',
          strokeDashArray: [],
          rx: 0,
          ry: 0,
          opacity: 0,
        },
        border1: {
          fill: '#000000',
          height: 1,
          stroke: '',
          strokeDashArray: [],
          strokeWidth: 1,
          topOffset: 20,
        },
        categoryName: {
          defaultValue: 'CATEGORY',
          fontSize: 15,
          fontFamily: 'Open Sans',
          fontStyle: '',
          fill: '#000000',
          backgroundColor: '#ffffff',
          fontWeight: 'bold',
          originX: 'center',
          charSpacing: 250,
          topOffset: 13,
          leftMultiplier: 0,
        },
        item: {
          dishName: {
            defaultValue: 'Name',
            fontSize: 11,
            fontWeight: 'bold',
            fontStyle: 'normal',
            fontFamily: 'Roboto',
            lineHeight: 1.15,
            fill: '#000000',
            originX: 'left',
            leftMultiplier: 0,
          },
          weight: {
            defaultValue: '100',
            fontSize: 8,
            fontStyle: 'italic',
            fontFamily: 'Roboto',
            fill: '#000000',
            originX: 'right',
            topOffset: 2,
            leftMultiplier: -6.8,
          },
          price: {
            defaultValue: '25',
            fontSize: 13,
            fontWeight: 'bold',
            fontFamily: 'Roboto',
            fill: '#000000',
            topOffset: -2,
            originX: 'right',
            leftMultiplier: -33,
          },
          description: {
            defaultValue: 'one-line description',
            fontSize: 8,
            fontFamily: 'Roboto',
            lineHeight: 1.15,
            fill: '#000000',
            fontStyle: 'italic',
            topOffset: 13,
            originX: 'left',
            leftMultiplier: 0,
          },
          step: 40,
          topOffset: 45,
          leftOffset: 0,
          widthOffset: 0,
        },
        width: 228,
        remainingHeight: 1.25 * 40,
        baseType: 'withUnderlinedName',
        customType: 'type18',
      },
      type19: {
        categoryWrapper: {
          fill: 'rgba(0, 0, 0, 0)',
          stroke: '',
          strokeDashArray: [],
          rx: 0,
          ry: 0,
          opacity: 0,
        },
        border1: {
          fill: '#000000',
          height: 2,
          stroke: '',
          strokeDashArray: [],
          topOffset: 40,
          strokeWidth: 1,
        },
        categoryName: {
          defaultValue: 'CATEGORY',
          fontSize: 18,
          fontFamily: 'Open Sans',
          fontStyle: 'normal',
          fill: '#000000',
          fontWeight: '500',
          originX: 'left',
          charSpacing: 100,
          topOffset: 2,
          leftMultiplier: 0,
        },
        item: {
          dishName: {
            defaultValue: 'Name',
            fontSize: 11,
            fontWeight: 'normal',
            fontStyle: 'normal',
            fontFamily: 'Open Sans',
            fill: '#000000',
            originX: 'left',
            leftMultiplier: 0,
          },
          price: {
            defaultValue: '25',
            fontSize: 14,
            fontWeight: 'normal',
            fontFamily: 'Open Sans',
            fill: '#000000',
            topOffset: -2,
            originX: 'right',
            leftMultiplier: -40,
          },
          description: {
            defaultValue:
              'Lorem ipsum dolor sit amet, consectetur\nadipiscing elit, sed do eiusmod',
            fontSize: 9,
            topOffset: 13,
            fontFamily: 'Open Sans',
            lineHeight: 1.15,
            fill: '#000000',
            fontStyle: 'normal',
            originX: 'left',
            leftMultiplier: 0,
          },
          step: 45,
          topOffset: 50,
          leftOffset: 0,
          widthOffset: 0,
        },
        width: 230,
        remainingHeight: 1.27 * 45,
        baseType: 'withUnderlinedName',
        customType: 'type19',
      },
      type20: {
        categoryWrapper: {
          fill: 'rgba(0, 0, 0, 0)',
          stroke: '',
          strokeDashArray: [],
          rx: 0,
          ry: 0,
          opacity: 0,
        },
        border1: {
          fill: '#000000',
          height: 2,
          stroke: '',
          strokeDashArray: [],
          strokeWidth: 1,
          topOffset: 2 * 18,
        },
        categoryName: {
          defaultValue: 'CATEGORY',
          fontSize: 15,
          fontFamily: 'Open Sans',
          fontStyle: 'normal',
          fontWeight: 'bold',
          fill: '#000000',
          charSpacing: 0,
          originX: 'center',
          topOffset: 2,
          leftMultiplier: 0,
        },
        item: {
          dishName: {
            defaultValue: 'Name',
            fontSize: 12,
            fontWeight: 'normal',
            fontStyle: 'normal',
            fontFamily: 'Roboto',
            lineHeight: 1.15,
            fill: '#000000',
            originX: 'left',
            leftMultiplier: 0,
          },
          price: {
            defaultValue: '25',
            fontSize: 11,
            fontWeight: 'bold',
            fontFamily: 'Roboto',
            fill: '#000000',
            topOffset: -1,
            originX: 'right',
            leftMultiplier: -33,
          },
          step: 25,
          topOffset: 50,
          leftOffset: 0,
          widthOffset: 0,
        },
        width: 228,
        remainingHeight: 2.3 * 25,
        baseType: 'withUnderlinedName',
        customType: 'type20',
      },
      type21: {
        categoryWrapper: {
          fill: 'rgba(0, 0, 0, 0)',
          stroke: '',
          strokeDashArray: [],
          rx: 0,
          ry: 0,
          opacity: 0,
        },
        categoryName: {
          defaultValue: 'CATEGORY',
          fontSize: 18,
          fontFamily: 'Open Sans',
          fontStyle: 'normal',
          fill: '#000000',
          fontWeight: '500',
          originX: 'left',
          charSpacing: 0,
          topOffset: 2,
          leftMultiplier: 10,
        },
        item: {
          dishName: {
            defaultValue: 'Name',
            fontSize: 11,
            fontWeight: 'normal',
            fontStyle: 'normal',
            fontFamily: 'Open Sans',
            fill: '#000000',
            originX: 'left',
            leftMultiplier: 0,
          },
          price: {
            defaultValue: '25',
            fontSize: 14,
            fontWeight: 'normal',
            fontFamily: 'Open Sans',
            fill: '#000000',
            topOffset: -2,
            originX: 'right',
            leftMultiplier: -40,
          },
          description: {
            defaultValue:
              'Lorem ipsum dolor sit amet, consectetur\nadipiscing elit, sed do eiusmod',
            fontSize: 9,
            topOffset: 13,
            fontFamily: 'Open Sans',
            lineHeight: 1.15,
            fill: '#000000',
            fontStyle: 'normal',
            originX: 'left',
            leftMultiplier: 0,
          },
          step: 45,
          topOffset: 40,
          leftOffset: 0,
          widthOffset: 0,
        },
        width: 230,
        remainingHeight: 1.17 * 45,
        baseType: 'justWithName',
        customType: 'type21',
      },
      type22: {
        categoryWrapper: {
          fill: 'rgba(0, 0, 0, 0)',
          stroke: '',
          strokeDashArray: [],
          rx: 0,
          ry: 0,
          opacity: 0,
        },
        categoryName: {
          defaultValue: 'CATEGORY',
          fontSize: 18,
          fontFamily: 'Open Sans',
          fontStyle: 'normal',
          fill: '#000000',
          fontWeight: '500',
          originX: 'left',
          charSpacing: 0,
          topOffset: 125,
          leftMultiplier: -8,
          angle: 270,
        },
        item: {
          dishName: {
            defaultValue: 'Name',
            fontSize: 11,
            fontWeight: 'normal',
            fontStyle: 'normal',
            fontFamily: 'Open Sans',
            fill: '#000000',
            originX: 'left',
            leftMultiplier: 0,
          },
          price: {
            defaultValue: '25',
            fontSize: 14,
            fontWeight: 'normal',
            fontFamily: 'Open Sans',
            fill: '#000000',
            topOffset: -2,
            originX: 'right',
            leftMultiplier: -40,
          },
          description: {
            defaultValue:
              'Lorem ipsum dolor sit amet, consectetur\nadipiscing elit, sed do eiusmod',
            fontSize: 9,
            topOffset: 13,
            fontFamily: 'Open Sans',
            lineHeight: 1.15,
            fill: '#000000',
            fontStyle: 'normal',
            originX: 'left',
            leftMultiplier: 0,
          },
          step: 45,
          topOffset: 5,
          leftOffset: 0,
          widthOffset: 0,
        },
        width: 230,
        remainingHeight: 0.5 * 45,
        baseType: 'justWithName',
        customType: 'type22',
      },
      type23: {
        categoryWrapper: {
          fill: 'rgba(0, 0, 0, 0)',
          stroke: '',
          strokeDashArray: [],
          rx: 0,
          ry: 0,
          opacity: 0,
        },
        item: {
          dishName: {
            defaultValue: 'Name',
            fontSize: 11,
            fontWeight: 'bold',
            fontStyle: 'normal',
            fontFamily: 'Open Sans',
            fill: '#000000',
            originX: 'center',
            leftMultiplier: 0,
          },
          description: {
            defaultValue: 'one-line description',
            fontSize: 11,
            topOffset: 13,
            fontFamily: 'Open Sans',
            lineHeight: 1.15,
            fill: '#000000',
            fontStyle: 'italic',
            originX: 'center',
            leftMultiplier: 0,
          },
          step: 42,
          topOffset: 10,
          leftOffset: 0,
          widthOffset: 0,
        },
        width: 180,
        remainingHeight: 0.5 * 42,
        baseType: 'simple',
        customType: 'type23',
      },
      type24: {
        categoryWrapper: {
          fill: 'rgba(0, 0, 0, 0)',
          stroke: '',
          strokeDashArray: [],
          rx: 0,
          ry: 0,
          opacity: 0,
        },
        categoryName: {
          defaultValue: 'CATEGORY',
          fontSize: 17,
          fontFamily: 'Roboto',
          fontStyle: 'normal',
          fill: '#000000',
          fontWeight: 'bold',
          originX: 'left',
          charSpacing: 100,
          topOffset: 4,
          leftMultiplier: 0,
        },
        item: {
          dishName: {
            defaultValue: 'Name',
            fontSize: 12,
            fontWeight: 'normal',
            fontStyle: 'normal',
            fontFamily: 'Roboto',
            fill: '#000000',
            originX: 'left',
            leftMultiplier: 0,
          },
          weight: {
            defaultValue: '25',
            fontSize: 12,
            fontStyle: 'normal',
            fontFamily: 'Roboto',
            fill: '#000000',
            originX: 'right',
            topOffset: 0,
            leftMultiplier: -5.8,
          },
          price: {
            defaultValue: '100',
            fontSize: 12,
            fontWeight: 'normal',
            fontFamily: 'Roboto',
            fill: '#000000',
            topOffset: 0,
            originX: 'right',
            leftMultiplier: 0,
          },
          description: {
            defaultValue: '25',
            fontSize: 12,
            topOffset: 0,
            fontFamily: 'Roboto',
            lineHeight: 1.15,
            fill: '#000000',
            fontStyle: 'normal',
            originX: 'right',
            leftMultiplier: -2.8,
          },
          step: 28,
          topOffset: 40,
          leftOffset: 0,
          widthOffset: 0,
        },
        width: 230,
        remainingHeight: 1.9 * 28,
        baseType: 'justWithName',
        customType: 'type24',
      },
      type25: {
        categoryWrapper: {
          fill: 'rgba(0, 0, 0, 0)',
          stroke: '',
          strokeDashArray: [],
          rx: 0,
          ry: 0,
          opacity: 0,
        },
        categoryName: {
          defaultValue: 'CATEGORY',
          fontSize: 18,
          fontFamily: 'Open Sans',
          fontStyle: 'normal',
          fill: '#000000',
          fontWeight: '500',
          originX: 'left',
          charSpacing: 0,
          topOffset: 2,
          leftMultiplier: 10,
        },
        item: {
          dishName: {
            defaultValue: 'Name',
            fontSize: 11,
            fontWeight: 'normal',
            fontStyle: 'normal',
            fontFamily: 'Open Sans',
            fill: '#000000',
            originX: 'left',
            leftMultiplier: 0,
          },
          price: {
            defaultValue: '25',
            fontSize: 14,
            fontWeight: 'normal',
            fontFamily: 'Open Sans',
            fill: '#000000',
            topOffset: -2,
            originX: 'right',
            leftMultiplier: -40,
          },
          description: {
            defaultValue:
              'Lorem ipsum dolor sit amet, consectetur\nadipiscing elit, sed do eiusmod',
            fontSize: 9,
            topOffset: 13,
            fontFamily: 'Open Sans',
            lineHeight: 1.15,
            fill: '#000000',
            fontStyle: 'normal',
            originX: 'left',
            leftMultiplier: 0,
          },
          step: 45,
          topOffset: 10,
          leftOffset: 0,
          widthOffset: 0,
        },
        width: 230,
        remainingHeight: 0.5 * 45,
        baseType: 'simple',
        customType: 'type25',
      },
      type26: {
        categoryWrapper: {
          fill: 'rgba(0, 0, 0, 0)',
          stroke: '',
          strokeDashArray: [],
          rx: 0,
          ry: 0,
          opacity: 0,
        },
        categoryName: {
          defaultValue: 'CATEGORY',
          fontSize: 18,
          fontFamily: 'Open Sans',
          fontStyle: 'normal',
          fill: '#000000',
          fontWeight: '500',
          originX: 'left',
          charSpacing: 0,
          topOffset: 2,
          leftMultiplier: 0,
        },
        item: {
          dishName: {
            defaultValue: 'Name',
            fontSize: 11,
            fontWeight: 'normal',
            fontStyle: 'normal',
            fontFamily: 'Open Sans',
            fill: '#000000',
            originX: 'left',
            leftMultiplier: 0,
          },
          price: {
            defaultValue: '25',
            fontSize: 14,
            fontWeight: 'normal',
            fontFamily: 'Open Sans',
            fill: '#000000',
            topOffset: -2,
            originX: 'right',
            leftMultiplier: -40,
          },
          description: {
            defaultValue:
              'Lorem ipsum dolor sit amet, consectetur\nadipiscing elit, sed do eiusmod',
            fontSize: 9,
            topOffset: 13,
            fontFamily: 'Open Sans',
            lineHeight: 1.15,
            fill: '#000000',
            fontStyle: 'normal',
            originX: 'left',
            leftMultiplier: 0,
          },
          step: 45,
          topOffset: 40,
          leftOffset: 0,
          widthOffset: 0,
        },
        width: 230,
        remainingHeight: 1.17 * 45,
        baseType: 'justWithName',
        customType: 'type26',
      },
      type27: {
        categoryWrapper: {
          fill: 'rgba(0, 0, 0, 0)',
          stroke: '',
          strokeDashArray: [],
          rx: 0,
          ry: 0,
          opacity: 0,
        },
        item: {
          dishName: {
            defaultValue: 'Name',
            fontSize: 15,
            fontWeight: 'bold',
            fontStyle: 'normal',
            fontFamily: 'Open Sans',
            fill: '#000000',
            originX: 'left',
            leftMultiplier: 0,
          },
          price: {
            defaultValue: '25',
            fontSize: 15,
            fontWeight: 'normal',
            fontFamily: 'Open Sans',
            fill: '#000000',
            topOffset: 0,
            originX: 'right',
            leftMultiplier: 0,
          },
          description: {
            defaultValue:
              'Lorem ipsum dolor sit amet, vide admodum \ntemporibus qui et, eam ad populo doctus',
            fontSize: 11,
            fontFamily: 'Open Sans',
            fill: '#333434',
            fontStyle: 'normal',
            lineHeight: 1.15,
            topOffset: 23,
            originX: 'left',
            leftMultiplier: 0,
          },
          step: 65,
          topOffset: 10,
          leftOffset: 0,
          widthOffset: 0,
        },
        width: 230,
        remainingHeight: 0.5 * 65,
        baseType: 'simple',
        customType: 'type27',
      },
      type28: {
        categoryWrapper: {
          fill: 'rgba(0, 0, 0, 0)',
          stroke: '',
          strokeDashArray: [],
          rx: 0,
          ry: 0,
          opacity: 0,
        },
        categoryName: {
          defaultValue: 'Category',
          fontSize: 18,
          fontFamily: 'Open Sans',
          fontStyle: 'normal',
          fill: '#000000',
          fontWeight: '500',
          originX: 'left',
          charSpacing: 0,
          topOffset: 2,
          leftMultiplier: 0,
        },
        item: {
          dishName: {
            defaultValue: 'Name',
            fontSize: 11,
            fontWeight: 'normal',
            fontStyle: 'normal',
            fontFamily: 'Open Sans',
            fill: '#000000',
            originX: 'left',
            leftMultiplier: 0,
          },
          price: {
            defaultValue: '25',
            fontSize: 14,
            fontWeight: 'normal',
            fontFamily: 'Open Sans',
            fill: '#000000',
            topOffset: 11,
            originX: 'right',
            leftMultiplier: -40,
          },
          description: {
            defaultValue:
              'Lorem ipsum dolor sit amet, consectetur\nadipiscing elit, sed do eiusmod',
            fontSize: 10,
            topOffset: 15,
            fontFamily: 'Open Sans',
            lineHeight: 1.15,
            fill: '#000000',
            fontStyle: 'normal',
            originX: 'left',
            leftMultiplier: 0,
          },
          step: 45,
          topOffset: 40,
          leftOffset: 0,
          widthOffset: 0,
        },
        width: 230,
        remainingHeight: 1.17 * 45,
        baseType: 'justWithName',
        customType: 'type28',
      },
      type29: {
        categoryWrapper: {
          fill: 'rgba(0, 0, 0, 0)',
          stroke: '',
          strokeDashArray: [],
          rx: 0,
          ry: 0,
          opacity: 0,
        },
        border1: {
          fill: '#000000',
          height: 1,
          stroke: '',
          strokeDashArray: [],
          strokeWidth: 1,
          topOffset: 20,
        },
        categoryName: {
          defaultValue: 'CATEGORY',
          fontSize: 15,
          fontFamily: 'Open Sans',
          fontStyle: '',
          fill: '#000000',
          backgroundColor: '#ffffff',
          fontWeight: 'bold',
          originX: 'center',
          charSpacing: 250,
          topOffset: 13,
          leftMultiplier: 0,
        },
        item: {
          dishName: {
            defaultValue: 'Name',
            fontSize: 11,
            fontWeight: 'bold',
            fontStyle: 'normal',
            fontFamily: 'Roboto',
            lineHeight: 1.15,
            fill: '#000000',
            originX: 'left',
            leftMultiplier: 0,
          },
          weight: {
            defaultValue: '100',
            fontSize: 10,
            fontStyle: 'italic',
            fontFamily: 'Roboto',
            fill: '#000000',
            originX: 'right',
            topOffset: 0,
            leftMultiplier: -6.8,
          },
          price: {
            defaultValue: '25',
            fontSize: 13,
            fontWeight: 'bold',
            fontFamily: 'Roboto',
            fill: '#000000',
            topOffset: -2,
            originX: 'right',
            leftMultiplier: -33,
          },
          step: 30,
          topOffset: 50,
          leftOffset: 0,
          widthOffset: 0,
        },
        width: 228,
        remainingHeight: 1.25 * 30,
        baseType: 'withUnderlinedName',
        customType: 'type29',
      },
      type30: {
        categoryWrapper: {
          fill: 'rgba(0, 0, 0, 0)',
          stroke: '',
          strokeDashArray: [],
          rx: 0,
          ry: 0,
          opacity: 0,
        },
        item: {
          dishName: {
            defaultValue: 'Name',
            fontSize: 11,
            fontWeight: 'bold',
            fontStyle: 'normal',
            fontFamily: 'Roboto',
            lineHeight: 1.15,
            fill: '#000000',
            originX: 'left',
            leftMultiplier: 0,
          },
          weight: {
            defaultValue: '100',
            fontSize: 10,
            fontStyle: 'italic',
            fontFamily: 'Roboto',
            fill: '#000000',
            originX: 'right',
            topOffset: 0,
            leftMultiplier: -6.8,
          },
          price: {
            defaultValue: '25',
            fontSize: 13,
            fontWeight: 'bold',
            fontFamily: 'Roboto',
            fill: '#000000',
            topOffset: -2,
            originX: 'right',
            leftMultiplier: -33,
          },
          step: 30,
          topOffset: 10,
          leftOffset: 0,
          widthOffset: 0,
        },
        width: 228,
        remainingHeight: 0.5 * 30,
        baseType: 'simple',
        customType: 'type30',
      },
      type31: {
        categoryWrapper: {
          fill: 'rgba(0, 0, 0, 0)',
          stroke: '',
          strokeDashArray: [],
          rx: 0,
          ry: 0,
          opacity: 0,
        },
        item: {
          dishName: {
            defaultValue: 'Name',
            fontSize: 11,
            fontWeight: 'bold',
            fontStyle: 'normal',
            fontFamily: 'Roboto',
            lineHeight: 1.15,
            fill: '#000000',
            originX: 'left',
            leftMultiplier: 0,
          },
          weight: {
            defaultValue: '100',
            fontSize: 10,
            fontStyle: 'italic',
            fontFamily: 'Roboto',
            fill: '#000000',
            originX: 'right',
            topOffset: 0,
            leftMultiplier: -6.8,
          },
          price: {
            defaultValue: '25',
            fontSize: 13,
            fontWeight: 'bold',
            fontFamily: 'Roboto',
            fill: '#000000',
            topOffset: -2,
            originX: 'right',
            leftMultiplier: -33,
          },
          description: {
            defaultValue: 'one-line description',
            fontSize: 8,
            fontFamily: 'Roboto',
            lineHeight: 1.15,
            fill: '#000000',
            fontStyle: 'italic',
            topOffset: 13,
            originX: 'left',
            leftMultiplier: 0,
          },
          step: 40,
          topOffset: 10,
          leftOffset: 0,
          widthOffset: 0,
        },
        width: 228,
        remainingHeight: 0.5 * 40,
        baseType: 'simple',
        customType: 'type31',
      },
      type32: {
        categoryWrapper: {
          fill: 'rgba(0, 0, 0, 0)',
          stroke: '',
          strokeDashArray: [],
          rx: 0,
          ry: 0,
          opacity: 0,
        },
        border1: {
          fill: '#000000',
          height: 1,
          stroke: '',
          strokeDashArray: [],
          strokeWidth: 1,
          topOffset: 20,
        },
        categoryName: {
          defaultValue: 'CATEGORY',
          fontSize: 15,
          fontFamily: 'Open Sans',
          fontStyle: '',
          fill: '#000000',
          backgroundColor: '#ffffff',
          fontWeight: 'bold',
          originX: 'center',
          charSpacing: 250,
          topOffset: 13,
          leftMultiplier: 0,
        },
        item: {
          dishName: {
            defaultValue: 'Name',
            fontSize: 11,
            fontWeight: 'bold',
            fontStyle: 'normal',
            fontFamily: 'Roboto',
            lineHeight: 1.15,
            fill: '#000000',
            originX: 'left',
            leftMultiplier: 0,
          },
          weight: {
            defaultValue: '100',
            fontSize: 10,
            fontStyle: 'italic',
            fontFamily: 'Roboto',
            fill: '#000000',
            originX: 'right',
            topOffset: 0,
            leftMultiplier: -6.8,
          },
          price: {
            defaultValue: '25',
            fontSize: 13,
            fontWeight: 'bold',
            fontFamily: 'Roboto',
            fill: '#000000',
            topOffset: -2,
            originX: 'right',
            leftMultiplier: -33,
          },
          step: 28,
          topOffset: 50,
          leftOffset: 0,
          widthOffset: 0,
        },
        width: 228,
        remainingHeight: 1.25 * 28,
        baseType: 'withUnderlinedName',
        customType: 'type32',
      },
      type33: {
        categoryWrapper: {
          fill: 'rgba(0, 0, 0, 0)',
          stroke: '',
          strokeDashArray: [],
          rx: 0,
          ry: 0,
          opacity: 0,
        },
        border1: {
          fill: '#000000',
          height: 1,
          stroke: '',
          strokeDashArray: [],
          strokeWidth: 1,
          topOffset: 20,
        },
        categoryName: {
          defaultValue: 'CATEGORY',
          fontSize: 15,
          fontFamily: 'Open Sans',
          fontStyle: '',
          fill: '#000000',
          backgroundColor: '#ffffff',
          fontWeight: 'bold',
          originX: 'center',
          charSpacing: 250,
          topOffset: 13,
          leftMultiplier: 0,
        },
        item: {
          dishName: {
            defaultValue: 'Name',
            fontSize: 12,
            fontWeight: 'normal',
            fontStyle: 'normal',
            fontFamily: 'Roboto',
            fill: '#000000',
            originX: 'left',
            leftMultiplier: 0,
          },
          weight: {
            defaultValue: '25',
            fontSize: 12,
            fontStyle: 'normal',
            fontFamily: 'Roboto',
            fill: '#000000',
            originX: 'right',
            topOffset: 0,
            leftMultiplier: -5.8,
          },
          price: {
            defaultValue: '100',
            fontSize: 12,
            fontWeight: 'normal',
            fontFamily: 'Roboto',
            fill: '#000000',
            topOffset: 0,
            originX: 'right',
            leftMultiplier: 0,
          },
          description: {
            defaultValue: '25',
            fontSize: 12,
            topOffset: 0,
            fontFamily: 'Roboto',
            lineHeight: 1.15,
            fill: '#000000',
            fontStyle: 'normal',
            originX: 'right',
            leftMultiplier: -2.8,
          },
          step: 25,
          topOffset: 50,
          leftOffset: 0,
          widthOffset: 0,
        },
        width: 230,
        remainingHeight: 1.9 * 25,
        baseType: 'withUnderlinedName',
        customType: 'type33',
      },
    };
  }

  static getWidthOffsetAccordingToAlignment(originX, width) {
    switch (originX) {
      case 'left': {
        return -(width / 2);
      }
      case 'center': {
        return 0;
      }
      case 'right': {
        return width / 2;
      }
    }
  }

  createCategoryItem(itemType, itemsTop, left, width, identifier) {
    let separatingLine = null;
    let description = null;
    let weight = null;
    let price = null;
    if (itemType.separatingLine) {
      separatingLine = new fabric.Rect({
        originX: 'center',
        fill: itemType.separatingLine.fill,
        width:
          width +
          width / (Number(itemType.separatingLine.widthMultiplier) || width),
        height: Number(itemType.separatingLine.height),
        stroke: itemType.separatingLine.stroke,
        strokeDashArray: itemType.separatingLine.strokeDashArray,
        strokeWidth: Number(itemType.separatingLine.strokeWidth) || 1, // note: old categories do not have this property
        opacity: Number(itemType.separatingLine.opacity) || 1, // note: old categories do not have this property
        top: itemsTop + Number(itemType.separatingLine.topOffset),
        left,
        hasControls: false,
        lockMovementY: true,
        lockMovementX: true,
        class: 'separatingLine',
        identifier,
      });
    }
    const dishName = new fabric.IText(itemType.dishName.defaultValue, {
      fontSize: Number(itemType.dishName.fontSize),
      fontFamily: itemType.dishName.fontFamily,
      fill: itemType.dishName.fill,
      fontWeight: itemType.dishName.fontWeight,
      fontStyle: itemType.dishName.fontStyle,
      opacity: Number(itemType.dishName.opacity) || 1, // note: old categories do not have this property
      stroke: itemType.dishName.stroke,
      strokeWidth: Number(itemType.dishName.strokeWidth) || 1, // note: old categories do not have this property
      lineHeight: Number(itemType.dishName.lineHeight) || 1.5, // note: old categories do not have this property
      textAlign: Number(itemType.dishName.textAlign) || 'left', // note: old categories do not have this property
      originX: itemType.dishName.originX,
      top: itemsTop,
      left:
        left +
        this.constructor.getWidthOffsetAccordingToAlignment(
          itemType.dishName.originX,
          width
        ) +
        width / (Number(itemType.dishName.leftMultiplier) || width),
      hasControls: false,
      lockMovementY: true,
      lockMovementX: true,
      class: 'dishName',
      identifier,
    });
    setCustomTextEditingBorder(dishName);
    if (itemType.weight) {
      weight = new fabric.IText(itemType.weight.defaultValue, {
        fontSize: Number(itemType.weight.fontSize),
        fontStyle: itemType.weight.fontStyle,
        fontFamily: itemType.weight.fontFamily,
        fill: itemType.weight.fill,
        opacity: Number(itemType.weight.opacity) || 1, // note: old categories do not have this property
        stroke: itemType.weight.stroke,
        strokeWidth: Number(itemType.weight.strokeWidth) || 1, // note: old categories do not have this property
        lineHeight: Number(itemType.weight.lineHeight) || 1.5, // note: old categories do not have this property
        textAlign: Number(itemType.weight.textAlign) || 'left', // note: old categories do not have this property
        originX: itemType.weight.originX,
        top: itemsTop + Number(itemType.weight.topOffset),
        left:
          left +
          this.constructor.getWidthOffsetAccordingToAlignment(
            itemType.weight.originX,
            width
          ) +
          width / (Number(itemType.weight.leftMultiplier) || width),
        hasControls: false,
        lockMovementY: true,
        lockMovementX: true,
        class: 'weight',
        identifier,
      });
      setCustomTextEditingBorder(weight);
    }
    if (itemType.price) {
      price = new fabric.IText(itemType.price.defaultValue, {
        fontSize: Number(itemType.price.fontSize),
        fontFamily: itemType.price.fontFamily,
        fontWeight: itemType.price.fontWeight,
        fill: itemType.price.fill,
        opacity: Number(itemType.price.opacity) || 1, // note: old categories do not have this property
        stroke: itemType.price.stroke,
        strokeWidth: Number(itemType.price.strokeWidth) || 1, // note: old categories do not have this property
        lineHeight: Number(itemType.price.lineHeight) || 1.5, // note: old categories do not have this property
        textAlign: Number(itemType.price.textAlign) || 'left', // note: old categories do not have this property
        originX: itemType.price.originX,
        top: itemsTop + Number(itemType.price.topOffset),
        left:
          left +
          this.constructor.getWidthOffsetAccordingToAlignment(
            itemType.price.originX,
            width
          ) +
          width / (Number(itemType.price.leftMultiplier) || width),
        hasControls: false,
        lockMovementY: true,
        lockMovementX: true,
        class: 'price',
        identifier,
      });
      setCustomTextEditingBorder(price);
    }
    if (itemType.description) {
      description = new fabric.IText(itemType.description.defaultValue, {
        fontSize: Number(itemType.description.fontSize),
        fontFamily: itemType.description.fontFamily,
        fill: itemType.description.fill,
        fontStyle: itemType.description.fontStyle,
        opacity: Number(itemType.description.opacity) || 1, // note: old categories do not have this property
        stroke: itemType.description.stroke,
        strokeWidth: Number(itemType.description.strokeWidth) || 1, // note: old categories do not have this property
        lineHeight: Number(itemType.description.lineHeight) || 1.5, // note: old categories do not have this property
        textAlign: Number(itemType.description.textAlign) || 'left', // note: old categories do not have this property
        originX: itemType.description.originX,
        top: itemsTop + Number(itemType.description.topOffset),
        left:
          left +
          this.constructor.getWidthOffsetAccordingToAlignment(
            itemType.description.originX,
            width
          ) +
          width / (Number(itemType.description.leftMultiplier) || width),
        hasControls: false,
        lockMovementY: true,
        lockMovementX: true,
        class: 'description',
        identifier,
      });
      setCustomTextEditingBorder(description);
    }
    const currentCanvas = canvas.current();
    currentCanvas.renderOnAddRemove = false;
    currentCanvas.add(dishName);
    if (separatingLine) currentCanvas.add(separatingLine);
    if (description) currentCanvas.add(description);
    if (weight) currentCanvas.add(weight);
    if (price) currentCanvas.add(price);
    currentCanvas.renderOnAddRemove = true;
  }

  static categoryWrapper(
    itemsCount,
    categoryConfig,
    top,
    left,
    width,
    identifier
  ) {
    return new fabric.Rect({
      originX: 'center',
      width,
      height:
        categoryConfig.item.step * itemsCount +
        Number(categoryConfig.remainingHeight),
      top,
      left,
      opacity: Number(categoryConfig.categoryWrapper.opacity),
      fill: categoryConfig.categoryWrapper.fill,
      stroke: categoryConfig.categoryWrapper.stroke,
      strokeDashArray: categoryConfig.categoryWrapper.strokeDashArray,
      strokeWidth: Number(categoryConfig.categoryWrapper.strokeWidth) || 1, // note: old categories do not have this property
      rx: Number(categoryConfig.categoryWrapper.rx),
      ry: Number(categoryConfig.categoryWrapper.ry),
      hasControls: false,
      class: 'categoryWrapper',
      categoryName: categoryConfig.customType,
      step: categoryConfig.item.step,
      identifier,
    });
  }

  static border1(border1Config, top, left, width, identifier) {
    return new fabric.Rect({
      originX: 'center',
      fill: border1Config.fill,
      stroke: border1Config.stroke,
      strokeDashArray: border1Config.strokeDashArray,
      strokeWidth: Number(border1Config.strokeWidth),
      opacity: Number(border1Config.opacity) || 1, // note: old categories do not have this property
      width,
      height: Number(border1Config.height),
      top: top + Number(border1Config.topOffset),
      left,
      hasControls: false,
      lockMovementY: true,
      lockMovementX: true,
      class: 'border1',
      identifier,
    });
  }

  static border2(border2Config, top, left, width, identifier) {
    return new fabric.Rect({
      originX: 'center',
      fill: border2Config.fill,
      stroke: border2Config.stroke,
      strokeWidth: Number(border2Config.strokeWidth) || 1, // note: old categories do not have this property
      opacity: Number(border2Config.opacity) || 1, // note: old categories do not have this property
      width,
      height: Number(border2Config.height),
      top: top + Number(border2Config.topOffset),
      left,
      hasControls: false,
      lockMovementY: true,
      lockMovementX: true,
      class: 'border2',
      identifier,
    });
  }

  categoryName(categoryNameConfig, top, left, width, identifier) {
    const categoryName = new fabric.IText(categoryNameConfig.defaultValue, {
      fontSize: Number(categoryNameConfig.fontSize),
      fontFamily: categoryNameConfig.fontFamily,
      fontStyle: categoryNameConfig.fontStyle,
      fontWeight: categoryNameConfig.fontWeight,
      fill: categoryNameConfig.fill,
      backgroundColor: categoryNameConfig.backgroundColor || 'transparent',
      charSpacing: Number(categoryNameConfig.charSpacing),
      opacity: Number(categoryNameConfig.opacity) || 1, // note: old categories do not have this property
      stroke: categoryNameConfig.stroke,
      strokeWidth: Number(categoryNameConfig.strokeWidth) || 1, // note: old categories do not have this property
      lineHeight: Number(categoryNameConfig.lineHeight) || 1.5, // note: old categories do not have this property
      textAlign: Number(categoryNameConfig.textAlign) || 'left', // note: old categories do not have this property
      originX: categoryNameConfig.originX,
      top: top + Number(categoryNameConfig.topOffset),
      left:
        left +
        this.constructor.getWidthOffsetAccordingToAlignment(
          categoryNameConfig.originX,
          width
        ) +
        width / (Number(categoryNameConfig.leftMultiplier) || width),
      angle: Number(categoryNameConfig.angle) || 0, // note: old categories do not have this property,
      hasControls: false,
      lockMovementY: true,
      lockMovementX: true,
      class: 'categoryName',
      identifier,
    });
    setCustomTextEditingBorder(categoryName);
    return categoryName;
  }

  withUnderlinedName(itemsCount, categoryConfig, top, left, width) {
    const identifier = Date.now();
    const categoryWrapper = this.constructor.categoryWrapper(
      itemsCount,
      categoryConfig,
      top,
      left,
      width,
      identifier
    );
    const border1 = this.constructor.border1(
      categoryConfig.border1,
      top,
      left,
      width,
      identifier
    );
    const categoryName = this.categoryName(
      categoryConfig.categoryName,
      top,
      left,
      width,
      identifier
    );
    canvas.current().add(categoryWrapper, categoryName, border1);
    canvas.current().sendBackwards(border1);
    for (let i = 0; i < itemsCount; i += 1) {
      this.createCategoryItem(
        categoryConfig.item,
        top +
          Number(categoryConfig.item.topOffset) +
          i * Number(categoryConfig.item.step),
        left,
        width,
        identifier
      );
    }
    return {
      bottom: Number(categoryWrapper.height) + Number(categoryWrapper.top),
      right: Number(categoryWrapper.width) + Number(categoryWrapper.left),
    };
  }

  withDoubleUnderlinedName(itemsCount, categoryConfig, top, left, width) {
    const identifier = Date.now();
    const categoryWrapper = this.constructor.categoryWrapper(
      itemsCount,
      categoryConfig,
      top,
      left,
      width,
      identifier
    );
    const border1 = this.constructor.border1(
      categoryConfig.border1,
      top,
      left,
      width,
      identifier
    );
    const border2 = this.constructor.border2(
      categoryConfig.border2,
      top,
      left,
      width,
      identifier
    );
    const categoryName = this.categoryName(
      categoryConfig.categoryName,
      top,
      left,
      width,
      identifier
    );
    canvas.current().add(categoryWrapper, categoryName, border1, border2);
    for (let i = 0; i < itemsCount; i += 1) {
      this.createCategoryItem(
        categoryConfig.item,
        top +
          Number(categoryConfig.item.topOffset) +
          i * categoryConfig.item.step,
        left,
        width,
        identifier
      );
    }
    return {
      bottom: Number(categoryWrapper.height) + Number(categoryWrapper.top),
      right: Number(categoryWrapper.width) + Number(categoryWrapper.left),
    };
  }

  justWithName(itemsCount, categoryConfig, top, left, width) {
    const identifier = Date.now();
    const categoryWrapper = this.constructor.categoryWrapper(
      itemsCount,
      categoryConfig,
      top,
      left,
      width,
      identifier
    );
    const categoryName = this.categoryName(
      categoryConfig.categoryName,
      top,
      left,
      width,
      identifier
    );
    canvas.current().add(categoryWrapper, categoryName);
    for (let i = 0; i < itemsCount; i += 1) {
      this.createCategoryItem(
        categoryConfig.item,
        top +
          Number(categoryConfig.item.topOffset) +
          i * Number(categoryConfig.item.step),
        left,
        width,
        identifier
      );
    }
    return {
      bottom: Number(categoryWrapper.height) + Number(categoryWrapper.top),
      right: Number(categoryWrapper.width) + Number(categoryWrapper.left),
    };
  }

  simple(itemsCount, categoryConfig, top, left, width) {
    const identifier = Date.now();
    const categoryWrapper = this.constructor.categoryWrapper(
      itemsCount,
      categoryConfig,
      top,
      left,
      width,
      identifier
    );
    canvas.current().add(categoryWrapper);
    for (let i = 0; i < itemsCount; i += 1) {
      this.createCategoryItem(
        categoryConfig.item,
        top +
          Number(categoryConfig.item.topOffset) +
          i * Number(categoryConfig.item.step),
        left,
        width,
        identifier
      );
    }
    return {
      bottom: Number(categoryWrapper.height) + Number(categoryWrapper.top),
      right: Number(categoryWrapper.width) + Number(categoryWrapper.left),
    };
  }

  showBasicCategories() {
    const template = $('#basic-category-template').html();
    const mustacheTemplate = mustache.render(template, {
      basicCategoryNames: Object.keys(this.defaultConfigurations),
    });
    $('#basic-categories')
      .empty()
      .append(mustacheTemplate);
    $('#basic-categories-modal').modal('show');
  }

  static appendCategoryButton(categoryName) {
    const template = $('#category-button-template').html();
    const categoriesList = $('#categories-list');
    const mustacheTemplate = mustache.render(template, {
      categoryName,
      index:
        (Number(
          categoriesList
            .children('li')
            .last()
            .children('button')
            .attr('data-categoryIndex')
        ) || 0) + 1,
    });
    categoriesList.append(mustacheTemplate);
  }

  create(configuration) {
    return new Promise((resolve, reject) => {
      $.post('/category_configuration', {
        categoryConfiguration: configuration,
        templateId: templatesManager.id,
      })
        .done(res => {
          templatesManager.categoryConfigurations.push({
            id: res.id,
            name: res.name,
            configuration: JSON.parse(res.configuration),
          });
          this.constructor.appendCategoryButton(res.name);
          resolve(JSON.parse(res.configuration));
        })
        .fail(err => reject(err));
    });
  }

  static toggleConfigurationTab(identifier) {
    if (!templatesManager.isDesignerMode()) return;
    if (identifier) {
      $('.category-configuration').show();
    } else {
      $('.category-configuration').hide();
    }
  }

  static getCategoryName(fabricObj, identifier) {
    return fabricObj
      .getObjects()
      .find(
        item =>
          item.class === 'categoryWrapper' && item.identifier === identifier
      ).categoryName;
  }

  static isItemClass(className) {
    return (
      className === 'separatingLine' ||
      className === 'dishName' ||
      className === 'weight' ||
      className === 'price' ||
      className === 'description'
    );
  }

  applyChangesToAllObjectsThisType(
    objectClass,
    categoryName,
    fnToApply,
    value
  ) {
    // 3.73 ms → 3.54 ms
    const allObjects = _.map(canvasPage.getFabricObjects(), fabricObj => ({
      fabricObj,
      objects: fabricObj.getObjects(),
    }));
    _(allObjects).forEach(({ fabricObj, objects }) => {
      fabricObj.renderOnAddRemove = false;
      _(objects)
        .filter(
          obj => obj.class === 'categoryWrapper' || obj.class === objectClass
        )
        .groupBy('identifier')
        .filter(groupedObjects =>
          _.find(groupedObjects, { class: 'categoryWrapper', categoryName })
        )
        .flatten()
        .filter(obj => obj.class === objectClass)
        .forEach(filteredItems => fnToApply(fabricObj, filteredItems, value));
      fabricObj.renderOnAddRemove = true;
    });
  }

  getPropertyPath(objectClass, property) {
    return `${
      this.constructor.isItemClass(objectClass) ? 'item.' : ''
    }${objectClass}.${property}`;
  }

  edit({ property, value }, fnToApply = () => {}) {
    const currentCanvas = canvas.current();
    const activeObject = currentCanvas.getActiveObject();
    const categoryName = this.constructor.getCategoryName(
      currentCanvas,
      activeObject.identifier
    );
    const objectClass = activeObject.class;
    _.set(
      templatesManager.getCategoryConfig(categoryName),
      this.getPropertyPath(objectClass, property),
      value
    );
    this.applyChangesToAllObjectsThisType(
      objectClass,
      categoryName,
      fnToApply,
      value
    );
  }

  isCategoryUsed(categoryName) {
    return canvasPage
      .getFabricObjects()
      .some(
        fabricObj =>
          fabricObj
            .getObjects()
            .some(
              obj =>
                obj.class === 'categoryWrapper' &&
                obj.categoryName === categoryName
            ) === true
      );
  }

  remove(categoryName) {
    templatesManager.removeCategoryConfiguration(categoryName);
    return templatesManager
      .save(templatesManager.getId())
      .then(() => {
        const categoriesListElem = $('#categories-list');
        categoriesListElem
          .find(`[data-categoryName="${categoryName}"]`)
          .parent('li')
          .remove();
        categoriesListElem
          .children('li')
          .find('button')
          .each((i, elem) => {
            $(elem)
              .attr('data-categoryIndex', i + 1)
              .text(`Category ${i + 1}`);
          });
      })
      .catch(() => notification.warning(i18nClient.t('Something went wrong')));
  }

  get(id) {
    return new Promise((resolve, reject) => {
      $.get(`/category_configuration/${id}`)
        .done(res => resolve(res))
        .fail(err => reject(err));
    });
  }

  getPropertyValue(objectClass, property, categoryConfig) {
    return _.get(categoryConfig, this.getPropertyPath(objectClass, property));
  }

  changeWidth(value) {
    const allObjects = _.flatMap(canvasPage.getFabricObjects(), fabricObj =>
      fabricObj.getObjects()
    );
    const currentCanvas = canvas.current();
    const { identifier: activeIdentifier } = currentCanvas.getActiveObject();
    const activeCategoryWrapper = _.find(currentCanvas.getObjects(), {
      identifier: activeIdentifier,
      class: 'categoryWrapper',
    });
    const activeCategoryName =
      activeCategoryWrapper.categoryName ||
      this.constructor.getCategoryName(currentCanvas, activeIdentifier);
    const widthDifference = value - activeCategoryWrapper.width;
    const filteredObjects = _(allObjects)
      .filter('identifier')
      .groupBy('identifier')
      .filter(objects =>
        _.find(objects, {
          class: 'categoryWrapper',
          categoryName: activeCategoryName,
        })
      )
      .flatten()
      .value();
    filteredObjects.forEach(obj => {
      if (obj.class === 'categoryWrapper') {
        obj.width = value;
      }
      if (
        obj.originX === 'left' &&
        obj.class !== 'addItemBtn' &&
        obj.class !== 'removeItemBtn'
      ) {
        obj.left -= widthDifference / 2;
      } else if (obj.originX === 'right') {
        obj.left += widthDifference / 2;
      }
      if (
        obj.class === 'separatingLine' ||
        obj.class === 'border1' ||
        obj.class === 'border2'
      ) {
        obj.width += widthDifference;
      }
      if (obj.class === 'removeItemBtn') {
        obj.left += widthDifference / 2;
      }
      obj.cacheWidth = obj.width;
      obj.setCoords();
    });
    templatesManager.getCategoryConfig(activeCategoryName).width = value;
    canvasPage.getFabricObjects().forEach(fabricObj => {
      fabricObj.requestRenderAll();
    });
  }

  static getItemsCount(fabricObj, identifier) {
    return fabricObj
      .getObjects()
      .filter(obj => obj.identifier === identifier && obj.class === 'dishName')
      .length;
  }

  static getCategoryItems(fabricObj, identifier) {
    return fabricObj.filter(obj => obj.identifier === identifier);
  }
}

export const category = new Category();
