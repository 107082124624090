import { templatesManager } from './templatesManager';
import { category } from './category';

const INITIAL_DATA = {
  categoryWrapper: {
    top: 0,
    left: 0,
  },
  border1: {
    top: 0,
    left: 0,
  },
  border2: {
    top: 0,
    left: 0,
  },
  categoryName: {
    top: 0,
    left: 0,
  },
  dishName: {
    top: 0,
    left: 0,
  },
  price: {
    top: 0,
    left: 0,
  },
  weight: {
    top: 0,
    left: 0,
  },
  separatingLine: {
    top: 0,
    left: 0,
    x1: 0,
    y1: 0,
    x2: 0,
    y2: 0,
  },
  description: {
    top: 0,
    left: 0,
  },
  step: 0,
};

export class MoveCategory {
  constructor() {
    this.isDown = null;
    this.initialData = _.cloneDeep(INITIAL_DATA);
  }

  getCoordinatesDiff(block, axis) {
    return (
      this.initialData[block][axis] - this.initialData.categoryWrapper[axis]
    );
  }

  setPosition(obj, top, left, topDiffValue) {
    const topDiff = topDiffValue || this.getCoordinatesDiff(obj.class, 'top');
    obj.set({
      top: top + topDiff,
      left: left + this.getCoordinatesDiff(obj.class, 'left'),
    });
    obj.setCoords();
  }

  getTopDiffItemPosition() {
    return {
      dishName: this.getCoordinatesDiff('dishName', 'top'),
      description: this.getCoordinatesDiff('description', 'top'),
      weight: this.getCoordinatesDiff('weight', 'top'),
      price: this.getCoordinatesDiff('price', 'top'),
      separatingLine: this.getCoordinatesDiff('separatingLine', 'top'),
    };
  }

  getMainData() {
    return {
      step: this.initialData.step,
      top: this.initialData.categoryWrapper.top,
      left: this.initialData.categoryWrapper.left,
    };
  }

  setInitialCoordinates(fabricObj, activeObject) {
    if (activeObject) {
      fabricObj.getObjects().forEach(obj => {
        if (
          obj.identifier !== activeObject.identifier ||
          obj.class === 'addItemBtn' ||
          obj.class === 'removeItemBtn'
        )
          return;
        if (
          this.initialData[obj.class].top === 0 ||
          this.initialData[obj.class].top > obj.top
        ) {
          this.initialData[obj.class].top = obj.top;
          this.initialData[obj.class].left = obj.left;
        }
        if (obj.class === 'categoryWrapper' && obj.step) {
          this.initialData.step = obj.step;
        }
      });
      if (templatesManager.categoryConfigurations.length !== 0) {
        this.initialData.step = Number(
          templatesManager.getCategoryConfig(
            category.constructor.getCategoryName(
              fabricObj,
              activeObject.identifier
            )
          ).item.step
        );
      }
    }
    return this.getMainData();
  }

  clearInitialCoordinates() {
    this.initialData = _.cloneDeep(INITIAL_DATA);
  }

  mouseIsDown(fabricObj) {
    this.isDown = true;
    if (
      fabricObj.getActiveObject() &&
      fabricObj.getActiveObject().class === 'categoryWrapper'
    ) {
      this.setInitialCoordinates(fabricObj, fabricObj.getActiveObject());
    }
  }

  mouseIsUP() {
    this.isDown = false;
    this.clearInitialCoordinates();
  }

  isMouseDown() {
    return this.isDown;
  }

  move(fabricObj) {
    // 4.91 ms → 0.35 ms
    if (
      fabricObj.getActiveObject() &&
      fabricObj.getActiveObject().class === 'categoryWrapper'
    ) {
      const categoryWrapper = fabricObj.getActiveObject();
      let itemsTopOffset;
      const activeCategoryType = category.constructor.getCategoryName(
        fabricObj,
        categoryWrapper.identifier
      );
      if (templatesManager.categoryConfigurations.length !== 0) {
        itemsTopOffset = Number(
          templatesManager.getCategoryConfig(activeCategoryType).item.topOffset
        );
      } else {
        itemsTopOffset =
          window[templatesManager.getUsedTemplate()].itemTypes[
            activeCategoryType
          ].topOffset;
      }
      const topDiff = this.getTopDiffItemPosition();
      fabricObj.getObjects().forEach(obj => {
        if (obj.identifier === fabricObj.getActiveObject().identifier) {
          if (obj.class === 'categoryName' || obj.class === 'border1') {
            this.setPosition(obj, categoryWrapper.top, categoryWrapper.left);
          } else if (
            obj.class === 'dishName' ||
            obj.class === 'price' ||
            obj.class === 'description' ||
            obj.class === 'weight' ||
            obj.class === 'separatingLine' ||
            obj.class === 'border2'
          ) {
            this.setPosition(
              obj,
              categoryWrapper.top,
              categoryWrapper.left,
              topDiff[obj.class]
            );
            topDiff[obj.class] += this.initialData.step;
          } else if (obj.class === 'addItemBtn') {
            obj.set({
              top: categoryWrapper.top + categoryWrapper.height - 8.3,
              left: categoryWrapper.left - 8.3,
            });
            obj.setCoords();
          } else if (obj.class === 'removeItemBtn') {
            obj.set({
              top:
                categoryWrapper.top +
                obj.index * this.initialData.step +
                itemsTopOffset,
              left: categoryWrapper.left + categoryWrapper.width / 2 + 15,
            });
            obj.setCoords();
          }
        }
      });
    }
  }
}

export const moveCategory = new MoveCategory();
