import { canvasPage } from '../canvasPage';
import { wayforpayCustom } from '../../common/wayforpayCustom';
import { order } from '../order';
import { categoryItemBtn } from './changeCategoryItem';
import { grid } from '../grid';
import { font } from '../font';
import { menuChange } from '../menuChange';

export class TemplatesManager {
  constructor() {
    this.discount = 0.7;
    this.id = null;
    this.designId = null;
    this.usedTemplateName = null;
    this.templatePrice = null;
    this.isPaidAndNonPurchased = false;
    this.designerMode = false;
    this.euBundlePrice = 25;
    this.usBundlePrice = 30;
    this.bundlePrice = null;
    this.bundle = false;
    this.designFonts = null;
    this.formatName = null;
    this.categoryConfigurations = null;
    this.designIcons = [];
    this.predefinedColors = [];
    this.isDiscountEnabled = false;
  }

  static getAllTemplatesData(id) {
    return new Promise((resolve, reject) => {
      $.get(`/template/${id}?getAll=true`)
        .done(data => resolve(data))
        .fail(err => reject(err));
    });
  }

  setUsedTemplate(menuId, templateID) {
    return this.constructor.getAllTemplatesData(templateID).then(template => {
      this.id = template.id;
      this.isDiscountEnabled = template.isDiscountEnabled;
      this.usedTemplateName = template.design.name;
      this.templatePrice = template.price;
      this.isPaidAndNonPurchased = template.isPaidAndNonPurchased;
      this.designFonts = template.design.fonts;
      this.formatName = template.format_name;
      this.isInUSFormat = template.isInUSFormat;
      this.bundlePrice = this.isInUSFormat
        ? this.usBundlePrice
        : this.euBundlePrice;
      this.predefinedColors = JSON.parse(
        JSON.parse(template.design.predefined_colors).length
          ? template.design.predefined_colors
          : template.predefined_colors
      );
      this.setCategoryConfigurations(template.category_configurations);
    });
  }

  getPredefinedColors() {
    return this.predefinedColors;
  }

  getBundlePrice() {
    return this.bundlePrice;
  }

  isDesignerMode() {
    return this.designerMode;
  }

  isTemplatePaid() {
    return this.isPaidAndNonPurchased;
  }

  getUsedTemplate() {
    return this.usedTemplateName;
  }

  getId() {
    return this.id;
  }

  getDesignId() {
    return this.designId;
  }

  isBundle() {
    return this.bundle;
  }

  getPrice() {
    return this.bundle
      ? this.isDiscountEnabled
        ? (this.bundlePrice * this.discount).toFixed(0)
        : this.bundlePrice
      : this.isDiscountEnabled
      ? (this.templatePrice * this.discount).toFixed(0)
        : this.templatePrice;
  }

  getTotal(currency) {
    this.userCurrency = currency;
    return this.isTemplatePaid()
      ? wayforpayCustom.convertCurrency(
          this.getPrice(),
          order.getBaseCurrency(),
          currency
        )
      : 0;
  }

  getDesignFontsArray() {
    if (!this.designFonts) return [];
    return this.designFonts.map(({ font_name }) => font_name);
  }

  toggleBundle(isBundle) {
    this.bundle = isBundle;
    if (isBundle) {
      $('#bundle-icon').css('display', 'block');
      $('#template-icon').css('display', 'none');
      $('#template-price').text(
        `${
          this.isDiscountEnabled
            ? (this.bundlePrice * this.discount).toFixed(0)
            : this.bundlePrice
        } ${order.baseCurrency}`
      );
    } else {
      $('#template-icon').css('display', 'block');
      $('#bundle-icon').css('display', 'none');
      $('#template-price').text(
        `${
          this.isDiscountEnabled
            ? (this.templatePrice * this.discount).toFixed(0)
            : this.templatePrice
        } ${order.baseCurrency}`
      );
    }
  }

  static insertWatermark(fabricObj) {
    fabricObj.setOverlayImage('/img/editor/watermark/watermark-a3.png', () => {
      fabricObj.overlayImage.set({
        originX: 'center',
        originY: 'center',
        left: fabricObj.width / 2,
        top: fabricObj.height / 2,
      });
      fabricObj.overlayImage.scaleToWidth(fabricObj.width);
      fabricObj.overlayImage.scaleToHeight(fabricObj.height);
      fabricObj.requestRenderAll();
    });
  }

  static removeWatermarks() {
    canvasPage.getFabricObjects().forEach(fabricObj => {
      fabricObj.overlayImage = null;
      fabricObj.renderAll.bind(fabricObj);
    });
  }

  getOrderedTemplateDetails() {
    return this.isPaidAndNonPurchased
      ? {
          id: this.id,
          price: this.getTotal(order.getUserCurrency()),
          currency: order.getUserCurrency(),
          isBundle: this.isBundle(),
          isInUSFormat: this.isInUSFormat,
        }
      : {};
  }

  setDesignFonts() {
    this.getDesignFontsArray().forEach(fontFamily => {
      $('.designer-fonts').append(`
                <li class="list-item ${fontFamily}">
                    <a class="font-family text" data-font="${fontFamily}">${fontFamily}</a>
                    <a class="font-remove" data-font="${fontFamily}">-</a>
                </li>
            `);
      font.constructor.uploadGoogleFont(fontFamily);
    });
  }

  getCategoryConfig(name) {
    return this.categoryConfigurations.find(config => config.name === name)
      .configuration;
  }

  static getTemplateImagesData(fabricObjects) {
    return fabricObjects.map(fabricObj => ({
      pageId: fabricObj.id,
      dataURL: fabricObj
        .toDataURL({
          format: 'jpeg',
          multiplier: 2,
          backStoreOnly: true,
        })
        .replace('data:image/jpeg;base64,', ''),
    }));
  }

  save(templateId) {
    const listOfAllPageIds = canvasPage.getPageIds();
    const changedFabricObjects = canvasPage
      .getFabricObjects()
      .filter(fabricObj =>
        _.find(menuChange.getChangedPagesList(), { pageId: fabricObj.id })
      );
    const changedPageObjects = changedFabricObjects.map(obj => ({
      id: obj.id,
      serializedCanvas: canvasPage.toJSON(obj),
    }));
    changedFabricObjects.forEach(fabricObj => {
      if (grid.isOn()) {
        grid.constructor.remove(fabricObj);
      }
      categoryItemBtn.constructor.hide(fabricObj);
    });
    return Promise.all(
      this.constructor.getTemplateImagesData(changedFabricObjects)
    ).then(imagesData => {
      changedFabricObjects.forEach(fabricObj => {
        if (grid.isOn()) {
          grid.show(fabricObj);
        }
      });
      return this.update(
        templateId,
        listOfAllPageIds,
        changedPageObjects,
        imagesData
      );
    });
  }

  update(templateId, allPageIds, changedPageObjects, imagesData) {
    return new Promise((resolve, reject) => {
      $.ajax({
        url: `/template/${templateId}`,
        method: 'PUT',
        data: {
          allPageIds,
          changedPageObjects,
          imagesData,
          categoryConfigurations: this.categoryConfigurations,
        },
        success(res) {
          resolve(res);
        },
        error(err) {
          reject(err);
        },
      });
    });
  }

  setCategoryConfigurations(configs) {
    this.categoryConfigurations = configs.map(category => ({
      id: category.id,
      name: category.name,
      configuration: JSON.parse(category.configuration),
    }));
  }

  removeCategoryConfiguration(categoryName) {
    _.remove(
      this.categoryConfigurations,
      config => config.name === categoryName
    );
  }

  addIcon(src) {
    $('#free-icons ul').append(`<li class="free-icon">
            <button type="button" class="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
            <img src="${src}" width="50" height="50" class="add-icon free-icon">
        </li>`);
    this.designIcons.push(src);
  }

  addPicture(src) {
    $('#source-images-list').append(
      `<li class="add-text-img">
                    <button type="button" class="icon-btn image-btn add-image">
                        <img src="${src}" class="icon-btn image-btn add-image add-text-img">
                    </button>
                </li>`
    );
    this.designIcons.push(src);
  }

  updateDesignIcons() {
    return new Promise((resolve, reject) => {
      $.ajax({
        url: `/menu_design/${this.designId}`,
        method: 'PUT',
        data: {
          param: 'icons',
          value: JSON.stringify(this.designIcons),
        },
        success() {
          resolve();
        },
        error(err) {
          reject(err);
        },
      });
    });
  }

  removeIcon(src) {
    this.designIcons = this.designIcons.filter(icon => icon !== src);
    return this.updateDesignIcons();
  }

  updatePredefinedColors() {
    return new Promise((resolve, reject) => {
      $.ajax({
        url: `/menu_design/${this.designId}`,
        method: 'PUT',
        data: {
          param: 'predefinedColors',
          value: JSON.stringify(this.predefinedColors),
        },
        success() {
          resolve();
        },
        error(err) {
          reject(err);
        },
      });
    });
  }

  addColor(color) {
    this.predefinedColors.push(color);
    return this.updatePredefinedColors();
  }

  removeColor(color) {
    this.predefinedColors = this.predefinedColors.filter(
      predefinedColor => predefinedColor !== color
    );
    return this.updatePredefinedColors();
  }
}

export const templatesManager = new TemplatesManager();
