import { getCities, getWarehouses } from './novaPoshta';
import i18nClient from '../i18nextClient';

export function showOrderWindow() {
  const orderCityTag = $('#order-city');
  if (orderCityTag.find('option').length > 1) return;
  orderCityTag.prepend($('<option id="loading"></option>').html('Loading...'));
  $('.chosen-select').chosen({ width: '100%' });
  getCities()
    .then(cities => {
      const options = cities
        .map(
          city =>
            `<option value="${city.Description}">${city.Description}</option>`
        )
        .join('');
      orderCityTag.find('#loading').remove();
      $('#order-city').append(options);
      $('.chosen-select')
        .chosen()
        .trigger('chosen:updated');
    })
    .catch(() => {
      $('#order-city')
        .find('#loading')
        .remove();
      $('#city-list')
        .parent()
        .addClass('has-error');
    });
}

export function showAvailableWarehouses(userText) {
  const orderWarehouse = $('#order-warehouse');
  orderWarehouse.children().remove();
  getWarehouses(userText)
    .then(warehouses => {
      orderWarehouse.append('<option></option>');
      const options = warehouses
        .map(warehouse => `<option>${warehouse.Description}</option>`)
        .join('');
      orderWarehouse.append(options);
      $('.chosen-select')
        .chosen()
        .trigger('chosen:updated');
    })
    .catch(() => orderWarehouse.parent().addClass('has-error'));
}

export function sayThankYouModal() {
  const gfsModal = $('#order-address-modal');
  const size = gfsModal.find('form').height();
  gfsModal.find('form > div').remove();
  gfsModal.find('form').append(`
        <div style="height: ${size}px; line-height: ${size}px;" class="thanks-wrapper">
        <div class="img-wrapper">
        <img src="/img/editor/new-menu-icons.svg" alt="" height="180px">
        </div>
        <span class="thank-you-note">
          ${i18nClient.t('Thank you for your order!')}</span>
        </div>
    `);
  setTimeout(() => {
    $('#order-address-modal .close').click();
  }, 2000);
}

export function errorModal() {
  const gfsModal = $('#order-address-modal');
  const size = gfsModal.find('form').height();
  gfsModal.find('form > div').remove();
  gfsModal.find('form').append(`
        <div style="height: ${size}px; line-height: ${size}px;" class="thanks-wrapper">
        <span class="thank-you-note">
        ${i18nClient.t('Something went wrong')}</span>
        </div>
    `);
}

export function create(data) {
  $.ajax({
    url: '/fulfillment/free_sample_order',
    method: 'POST',
    data,
    success(response) {
      console.log(response);
      sayThankYouModal();
    },
    error(error) {
      console.log(error);
      errorModal();
    },
  });
}
