import axios from 'axios';
import * as notification from './notifications';
import i18nClient from '../i18nextClient';

const moveToFront = (arr, searchValue) => {
  let i;
  let exists;
  for (i = 0; i < arr.length; i++) {
    exists = Object.keys(arr[i]).some(key => arr[i][key] === searchValue);
    if (exists) break;
  }
  arr.unshift(arr[i]);
  arr.splice(i + 1, 1);
};

const validateRate = rate => rate !== 0 && rate >= 0.004 && rate <= 1000;

export class WayforpayCustom {
  constructor() {
    this.rates = null;
  }

  setCurrencyRate() {
    return new Promise((resolve, reject) => {
      $.get('/wayforpay/currency_rate')
        .done(res => {
          this.rates = Object.keys(res.rates)
            .map(i => ({ currency: i, rate: res.rates[i] }))
            .filter(obj => {
              if (validateRate(obj.rate)) return obj;
            });
          this.rates.push({ currency: 'UAH', rate: 1 });
          ['RUB', 'UAH', 'EUR', 'USD'].forEach(obj =>
            moveToFront(this.rates, obj)
          );
          resolve(this.rates);
        })
        .fail(err => {
          reject(err);
        });
    });
  }

  getRateByCurrency(currency) {
    return Number(
      this.rates.find(rate => rate.currency === currency).rate.toFixed(2)
    );
  }

  pay(params, newBonusBalance) {
    return new Promise((resolve, reject) => {
      const wayforpay = new Wayforpay();
      wayforpay.run(
        { ...params, newBonusBalance: newBonusBalance },
        response => {
          console.log('approved callback');
          location.reload();
          resolve(response);
        },
        response => {
          console.log('decline callback');
          reject(response);
        },
        response => {
          console.log('pending callback');
          console.log(response);
        }
      );
    });
  }

  async freePayment(orderId, userId, newBonusBalance) {
    const approved = axios({
      method: 'put',
      url: `/freemium_order/approve`,
      data: {
        id: orderId,
      },
    });
    const updated = axios({
      method: 'put',
      url: `/bonus_balance/update`,
      data: {
        sum: newBonusBalance,
        userProfileId: userId,
      },
    });

    return Promise.all([approved, updated])
      .then(() => {
        notification.info(i18nClient.t('Free payment complited.'));
        location.reload();
      })
      .catch(err => notification.info(i18nClient.t('Something went wrong')));
  }

  getParams({
    reference,
    amount,
    currency,
    productName,
    productCount,
    productPrice,
    serviceURL,
  }) {
    return new Promise((resolve, reject) => {
      $.post('/wayforpay/params', {
        reference,
        amount,
        currency,
        productName,
        productCount,
        productPrice,
        serviceURL,
      })
        .done(params => resolve(params))
        .fail(err => reject(err));
    });
  }

  convertCurrency(number, originalCurrency, finalCurrency) {
    const originalCurrencyRate = this.getRateByCurrency(originalCurrency);
    const totalUAH = Number((number * originalCurrencyRate).toFixed(2));
    return Number(
      (totalUAH / this.getRateByCurrency(finalCurrency)).toFixed(2)
    );
  }
}

export const wayforpayCustom = new WayforpayCustom();
