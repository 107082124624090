import i18nClient from '../i18nextClient';

require('../../vendor/bootstrap-tour');

const template = `<div class='popover tour custom-tour'>
                        <p class='popover-title custom-title'></p>
                        <div class='popover-content custom-content'></div>
                        <div class='popover-navigation custom-navigation'>
                            <button class='btn btn-default custom-button' data-role='prev'>${i18nClient.t(
                              'Prev'
                            )}</button>
                            <button class='btn btn-default custom-button' data-role='next'>${i18nClient.t(
                              'Next'
                            )}</button>
                        </div>
                        <button class='btn btn-default custom-button skip' data-role='end'>${i18nClient.t(
                          'End tour'
                        )}</button>
                    </div>`;

const steps = [
  {
    element: '#step1',
    title: i18nClient.t('Toolbar'),
    content: i18nClient.t('Toolbar description'),
    animation: false,
  },
  {
    element: '#step2',
    title: i18nClient.t('Properties Panel'),
    content: i18nClient.t('Properties Panel description'),
    animation: false,
  },
  {
    element: '#canvas1-wrapper',
    title: i18nClient.t('Design area'),
    content: i18nClient.t('Design area description'),
    animation: false,
  },
  {
    element: '#step4',
    title: i18nClient.t('Project Management Panel'),
    content: i18nClient.t('Project Management Panel description'),
    animation: false,
  },
];

export default function() {
  const tour = new Tour({
    backdrop: true,
    storage: false,
    template,
    steps,
    onShown() {
      if (tour.getCurrentStep() === 1) {
        $('.custom-tour')
          .css('left', 'calc(100% - 400px)')
          .css('top', 'calc(100% - 450px)');
      } else if (tour.getCurrentStep() === 3) {
        $('.custom-tour').css('top', '50px');
      }
    },
  });

  tour.init();
  tour.start();
}
