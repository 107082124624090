import mustache from 'mustache';
import randomstring from 'randomstring';
import { canvas } from './canvasManager';
import setUpCanvasPageEvents from './canvasPageEvents';
import { templatesManager } from './menu_templates/templatesManager';
import { grid } from './grid';
import { menuChange } from './menuChange';

export default class CanvasPage {
  /**
   * @property {HTMLImageElement}   plusImg
   * @property {HTMLImageElement}   minusImg
   * @property {number}  lastUpdateTimestamp
   *
   * @property {number}  canvasIdentifier
   *
   * @property {object}  menuData
   * @property {object}  menuData.meta
   * @property {string}  menuData.meta.name
   * @property {object}  menuData.meta.parameters
   * @property {number}  menuData.meta.parameters.width
   * @property {number}  menuData.meta.parameters.height
   * @property {number}  menuData.meta.parameters.folds
   * @property {number}  menuData.meta.parameters.printWidth
   * @property {string}  menuData.meta.parameters.format
   * @property {array}   menuData.pages
   * @property {string}  menuData.pages.id
   * @property {object}  menuData.pages.fabricObj
   *
   * @property {array}   fabricMetaProperties
   */
  constructor() {
    this.plusImg = null;
    this.minusImg = null;
    this.lastUpdateTimestamp = null;
    this.menuData = {
      meta: {},
      pages: [],
    };
    this.fabricMetaProperties = [
      'class',
      'identifier',
      'category',
      'step',
      'index',
      'hasControls',
      'lockMovementY',
      'lockMovementX',
      'categoryWidth',
      'editingBorderColor',
      'cursorWidth',
      'name',
      'selectable',
      'evented',
      'categoryName',
    ];
  }

  loadPlusImg() {
    const img = new Image();
    img.src = '/img/templates/plus-sign.png';
    this.plusImg = img;
  }

  getPlusImg() {
    return this.plusImg;
  }

  findFabricObj(id) {
    const page = this.menuData.pages.find(obj => obj.id === id);
    return page ? page.fabricObj : null;
  }

  getFabricObjects() {
    return this.menuData.pages.map(page => page.fabricObj);
  }

  getMenuParameters() {
    return this.menuData.meta.parameters;
  }

  getMenuName() {
    return this.menuData.meta.name;
  }

  getMenuData() {
    return this.menuData;
  }

  getLastUpdateTimestamp() {
    return this.lastUpdateTimestamp;
  }

  setMenuName(newName) {
    this.menuData.meta.name = newName;
  }

  setLastUpdateTimestamp(lastUpdateTimestamp) {
    this.lastUpdateTimestamp = lastUpdateTimestamp;
  }

  static setCanvasParameters(fabricObj, width, height, name, pageId) {
    $('.canvas-wrapper,.canvas-container').css({
      width: width,
      height: height,
    });
    fabricObj.setWidth(width);
    fabricObj.setHeight(height);
    fabricObj.set('name', name);
    fabricObj.set('id', pageId);
  }

  static drawFolds(fabricObj, folds) {
    if (folds === 0 || folds < 0) return;
    const x = fabricObj.getWidth() / (+folds + 1);
    for (let i = 1; i <= folds; i += 1) {
      fabricObj.add(
        new fabric.Line([x * i, 0, x * i, fabricObj.getHeight()], {
          stroke: '#ddd',
          shadow: '#ddd 0px 0px 15px',
          opacity: 0.5,
        })
      );
    }
  }

  static createFabricObject(canvasId) {
    const fabricObj = new fabric.Canvas(canvasId, {
      preserveObjectStacking: true,
      backgroundColor: '#fff',
    });
    fabric.Object.prototype.set({
      transparentCorners: false,
      borderDashArray: [7, 7],
      cornerColor: '#ffffff',
      cornerStrokeColor: '#d71f49',
      borderColor: '#d71f49',
      cornerStyle: 'circle',
      cornerSize: 12,
      padding: 5,
    });
    return fabricObj;
  }

  static createElements(canvasWidth, canvasHeight, folds, pageId, insertAfter) {
    const template = $('#canvas-page-template').html();
    const mustacheTemplate = mustache.render(template, {
      canvasId: pageId,
      canvasWidth,
      canvasHeight,
      folds,
    });

    if (insertAfter) {
      $(`#${insertAfter}-wrapper`).after(mustacheTemplate);
    } else {
      $('#canvases-container').append(mustacheTemplate);
    }
  }

  addNewPageObject(fabricObj, insertAfter = false) {
    const newPageObj = {
      id: fabricObj.get('id'),
      fabricObj,
    };
    const position = insertAfter
      ? this.menuData.pages.findIndex(page => page.id === insertAfter) + 1
      : this.menuData.pages.length + 1;
    this.menuData.pages.splice(position, 0, newPageObj);
    return newPageObj;
  }

  toJSON(fabricObj) {
    return JSON.stringify(fabricObj.toJSON(this.fabricMetaProperties));
  }

  findPageObj(id) {
    return this.menuData.pages.find(obj => obj.id === id);
  }

  saveInitialMenuData(parameters, name) {
    this.menuData = {
      meta: {
        parameters: {
          width: parameters.width,
          height: parameters.height,
          folds: parameters.folds,
          printWidth: parameters.printWidth,
          format: parameters.format,
          dataFormatVersion: MENU_DATA_FORMAT_VERSION,
        },
        name,
      },
      pages: [],
    };
  }

  static updatePageNumbers() {
    for (let i = 1; i <= $('.canvas-container').length; i += 1) {
      $('.page-number')[i - 1].innerHTML = `${i}/${
        $('.canvas-container').length
      }`;
    }
  }

  createNewPage(parameters, menuName, pageId, insertAfter = false) {
    this.constructor.createElements(
      parameters.width,
      parameters.height,
      parameters.folds,
      pageId,
      insertAfter
    );

    const fabricPageObj = this.constructor.createFabricObject(pageId);

    this.constructor.updatePageNumbers();
    this.constructor.setCanvasParameters(
      fabricPageObj,
      parameters.width,
      parameters.height,
      menuName,
      pageId
    );
    this.addNewPageObject(fabricPageObj, insertAfter);

    if (templatesManager.isTemplatePaid()) {
      templatesManager.constructor.insertWatermark(fabricPageObj);
    }

    if (grid.isOn()) grid.show(fabricPageObj);

    setUpCanvasPageEvents(fabricPageObj);
    fabricPageObj.requestRenderAll();
    return fabricPageObj;
  }

  getCanvasMultiplier() {
    const printWidth = this.menuData.meta.parameters.printWidth; // note: mm
    const screenWidth = this.menuData.meta.parameters.width; // note: px
    const screenPPI = 72;
    const printPPI = 300;
    const MPI = 25.4;
    return (
      (printWidth / ((screenWidth / screenPPI) * MPI)) * (printPPI / screenPPI)
    );
  }

  removeObjFromArray(arr, objId) {
    _.remove(arr, obj => obj.id === objId);
  }

  remove(id) {
    if ($('#canvases-container').find('canvas').length === 2) {
      canvas.current().remove(
        ...canvas
          .current()
          .getObjects()
          .concat()
      );
    } else {
      $(`#${id}-wrapper`).remove();
      this.removeObjFromArray(this.menuData.pages, id);
      this.constructor.updatePageNumbers();
      menuChange.triggerChangedStructure();
      menuChange.dropChangedPage(id);
    }
  }

  static getPagesDiff(direction) {
    let diff;
    if (direction === 'up') {
      diff = -1;
    } else if (direction === 'down') {
      diff = 1;
    }
    return diff;
  }

  move(direction, pages, id) {
    const diff = this.constructor.getPagesDiff(direction);
    const indexOfPageObj = pages.findIndex(obj => obj.id === id);
    [pages[indexOfPageObj + diff], pages[indexOfPageObj]] = [
      pages[indexOfPageObj],
      pages[indexOfPageObj + diff],
    ];
    this.constructor.updatePageNumbers();
  }

  moveUp(id) {
    const canvasesContainer = $('#canvases-container');
    const currentPage = canvasesContainer.find(`#${id}-wrapper`);
    const prevPage = canvasesContainer
      .find(`#${id}-wrapper`)
      .prev('.canvas-wrapper');
    if (id === $(canvasesContainer.find('canvas')).attr('id')) return;
    $(currentPage).insertBefore($(prevPage));
    this.move('up', this.menuData.pages, id);
    menuChange.triggerChangedStructure();
  }

  moveDown(id) {
    const canvasesContainer = $('#canvases-container');
    const canvases = canvasesContainer.find('canvas');
    const currentPage = canvasesContainer.find(`#${id}-wrapper`);
    const nextPage = canvasesContainer
      .find(`#${id}-wrapper`)
      .next('.canvas-wrapper');
    const lastCanvas = $(canvases)[canvases.length - 2];
    if (id === $(lastCanvas).attr('id')) return;
    $(currentPage).insertAfter($(nextPage));
    this.move('down', this.menuData.pages, id);
    menuChange.triggerChangedStructure();
  }

  duplicatePage(id) {
    const newPageId = this.generatePageId();
    const fabricObj = this.createNewPage(
      this.menuData.meta.parameters,
      $('#menu-name-input').val(),
      newPageId,
      id
    );
    window.customCanvas.restoreFromJSON(
      fabricObj,
      this.toJSON(this.findPageObj(id).fabricObj)
    );
    menuChange.triggerChangedStructure();
    menuChange.pushPageChange(fabricObj);
  }

  initialRenderFromMenuObj(menuObj) {
    menuObj.pages.map(async (page, i) => {
      const fabricObj = this.createNewPage(
        this.getMenuParameters(),
        menuObj.meta.name,
        page.id
      );
      this.menuData.pages[i].fabricObj = fabricObj;
      await window.customCanvas.restoreFromJSON(
        fabricObj,
        page.serializedCanvas
      );
    });
  }

  generatePageId() {
    const id = randomstring.generate({
      length: 5,
      charset: 'alphabetic',
      capitalization: 'lowercase',
    });
    if (this.findFabricObj(id)) this.generatePageId();
    return id;
  }

  getPageIds() {
    return this.menuData.pages.map(page => page.id);
  }
}

export const canvasPage = new CanvasPage();
