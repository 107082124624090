import { moveCategory } from './moveCategory';
import { canvasPage } from '../canvasPage';
import { templatesManager } from './templatesManager';
import { canvas } from '../canvasManager';
import { addMinuses, getPlusSign } from './templatesGeneral';
import { category } from './category';

export class CategoryItemBtn {
  static show(categoryWrapper, fabricObj) {
    if (categoryWrapper && categoryWrapper.class === 'categoryWrapper') {
      const top = categoryWrapper.top + categoryWrapper.height - 8.3;
      const left = categoryWrapper.left - 8.3;
      getPlusSign(fabricObj, top, left, categoryWrapper.identifier);
      addMinuses(fabricObj, categoryWrapper);
    }
  }

  static hide(fabricObj) {
    const plusesAndMinuses = fabricObj
      .getObjects()
      .filter(
        obj => obj.class === 'addItemBtn' || obj.class === 'removeItemBtn'
      );
    plusesAndMinuses.forEach(obj => fabricObj.remove(obj));
  }

  static resizeCategory(fabricObj, identifier, step, isAdding) {
    const categoryWrapper = fabricObj
      .getObjects()
      .find(
        item =>
          item.class === 'categoryWrapper' && item.identifier === identifier
      );

    categoryWrapper.set(
      isAdding
        ? { height: categoryWrapper.height + step }
        : { height: categoryWrapper.height - step }
    );
    categoryWrapper.setCoords();
  }

  moveItemsUp(categoryItems, selectedIndex, step) {
    for (let index = selectedIndex; index < categoryItems.length; index++) {
      categoryItems[index].set({
        top: categoryItems[index].top - step,
      });
      if (categoryItems[index].class === 'removeItemBtn') {
        categoryItems[index].set({
          index: categoryItems[index].index - 1,
        });
      }
      categoryItems[index].setCoords();
    }
  }

  addItem(e, fabricObj) {
    const categoryWrapper = fabricObj
      .getObjects()
      .find(
        obj =>
          obj.identifier === e.target.identifier &&
          obj.class === 'categoryWrapper'
      );
    const categoryData = moveCategory.setInitialCoordinates(
      fabricObj,
      e.target
    );
    categoryData.itemsCount = category.constructor.getItemsCount(
      fabricObj,
      e.target.identifier
    );
    categoryData.name = categoryWrapper.categoryName || e.target.category;
    let categoryConfig = {};
    // todo: get rid of passing itemType.leftOffset, itemType.widthOffset
    if (templatesManager.categoryConfigurations.length !== 0) {
      categoryConfig = templatesManager.getCategoryConfig(categoryData.name);
      categoryData.width = Number(categoryConfig.width);
      category.createCategoryItem(
        categoryConfig.item,
        categoryData.top +
          Number(categoryConfig.item.topOffset) +
          categoryData.itemsCount * categoryData.step,
        categoryData.left + Number(categoryConfig.item.leftOffset),
        categoryData.width + Number(categoryConfig.item.widthOffset),
        e.target.identifier
      );
    } else {
      categoryConfig.item =
        window[templatesManager.getUsedTemplate()].itemTypes[categoryData.name];
      categoryData.width = categoryWrapper.width;
      window[templatesManager.getUsedTemplate()].constructor.createCategoryItem(
        categoryConfig.item,
        categoryData.top +
          categoryConfig.item.topOffset +
          categoryData.itemsCount * categoryData.step,
        categoryData.left + categoryConfig.item.leftOffset,
        categoryData.width + Number(categoryConfig.item.widthOffset),
        e.target.identifier
      );
    }
    this.constructor.resizeCategory(
      fabricObj,
      e.target.identifier,
      categoryData.step,
      true
    );

    canvas
      .current()
      .discardActiveObject()
      .requestRenderAll();
  }

  removeItem(e, fabricObj) {
    const categoryData = moveCategory.setInitialCoordinates(
      fabricObj,
      e.target
    );
    const itemClasses = [
      'dishName',
      'description',
      'price',
      'weight',
      'separatingLine',
      'removeItemBtn',
    ];
    const allCategoryItems = [];
    const selectedIndex = e.target.get('index');
    const itemsCount = category.constructor.getItemsCount(
      fabricObj,
      e.target.identifier
    );

    if (itemsCount > 1) {
      // get category items and group them by className
      itemClasses.forEach(className => {
        allCategoryItems.push(
          fabricObj
            .getObjects()
            .filter(
              item =>
                item.class === className &&
                item.identifier === e.target.identifier
            )
        );
      });

      // remove selected items and move others up
      allCategoryItems.forEach(cetegoryItems => {
        canvas.current().remove(cetegoryItems.splice(selectedIndex, 1)[0]);
        this.moveItemsUp(cetegoryItems, selectedIndex, categoryData.step);
      });

      this.constructor.resizeCategory(
        fabricObj,
        e.target.identifier,
        categoryData.step,
        false
      );
    }
  }

  changeItem(e, fabricObj) {
    if (e.target && e.target.class === 'addItemBtn') {
      this.addItem(e, fabricObj);
    } else if (e.target && e.target.class === 'removeItemBtn') {
      this.removeItem(e, fabricObj);
    }
  }
}

export const categoryItemBtn = new CategoryItemBtn();
