import mustache from 'mustache';
import * as notification from '../common/notifications';
import i18nClient from '../i18nextClient';
import { templatesManager } from './menu_templates/templatesManager';
import { paidIcon } from './paidIcon';
import { iconPack } from './iconPack';
import { wayforpayCustom } from '../common/wayforpayCustom';
import { menuStorage } from './menuStorage';
import * as GA from '../common/GAevents';
import { userProfile } from '../user/profile';

export class Order {
  constructor() {
    this.baseCurrency = 'USD';
    this.userCurrency = 'USD';
  }

  getBaseCurrency() {
    return this.baseCurrency;
  }

  changeCurrency(currency) {
    this.userCurrency = currency;
  }

  getUserCurrency() {
    return this.userCurrency;
  }

  static isBonusBalanceApplied() {
    return userProfile.getBonusBalanceSum() > 0;
  }

  getSubtotal() {
    if (iconPack.getCurrentIconPack()) {
      const iconPackTotal = iconPack.getTotal(this.userCurrency);
      return iconPackTotal.toFixed(2);
    }
    const paidIconsTotal = paidIcon.getTotal(this.userCurrency);
    const paidTemplatesTotal = templatesManager.getTotal(this.userCurrency);
    return Number((paidIconsTotal + paidTemplatesTotal).toFixed(2));
  }

  getItemsTotalPrice() {
    return (paidIcon.getPrice() * paidIcon.getUsedIcons().size)(
      templatesManager.isPaidAndNonPurchased && templatesManager.getPrice()
        ? templatesManager.getPrice()
        : 0
    );
  }

  isBalanceBigger() {
    return userProfile.getBonusBalanceSum() - this.getItemsTotalPrice() > 0;
  }

  getTotal() {
    let orderTotal;
    let newBonusBalance;
    const paidItemsTotal = this.getSubtotal();
    const bonusBalanceByCurrency = wayforpayCustom.convertCurrency(
      userProfile.getBonusBalanceSum(),
      this.baseCurrency,
      this.userCurrency
    );

    if (this.constructor.isBonusBalanceApplied()) {
      newBonusBalance = this.isBalanceBigger()
        ? bonusBalanceByCurrency - paidItemsTotal
        : 0;
      orderTotal = this.isBalanceBigger()
        ? 0
        : paidItemsTotal - bonusBalanceByCurrency;
    } else {
      newBonusBalance = 0;
      orderTotal = paidItemsTotal;
    }

    return {
      totalWithDiscount: Number(orderTotal).toFixed(2),
      totalWithoutDiscount: paidItemsTotal,
      newBonusBalance,
    };
  }

  countOrderAndBalanceDiff() {
    if (this.isBalanceBigger()) {
      return `-${this.getItemsTotalPrice()} ${i18nClient.t('Coin Genetive')}`;
    }
    return `-${userProfile.getBonusBalanceSum()} ${i18nClient.t(
      'Coin Genetive'
    )}`;
  }

  updateBonusBalanceValue() {
    if (this.constructor.isBonusBalanceApplied()) {
      $('#used-bonus-balance').text(this.countOrderAndBalanceDiff());
    } else {
      $('#used-bonus-balance').text(`0 ${i18nClient.t('Coin Genetive')}`);
    }
  }

  renderLeftBonusBalanceValue(value) {
    if (Number(value) % 1 === 0) return value;
    return Number(value).toFixed(2);
  }

  updateTotal() {
    this.updateBonusBalanceValue();
    const totalValues = this.getTotal();

    $('#left-bonus-balance').text(
      `${this.renderLeftBonusBalanceValue(
        totalValues.newBonusBalance
      )} ${i18nClient.t('Coin Genetive')}`
    );
    $('#freemium-buy').text(
      `${totalValues.totalWithDiscount === '0.00' ? 'Get for free' : 'Buy'}`
    );
    $('#subtotal').text(`${this.getSubtotal()} ${this.userCurrency}`);
    $('#total').text(`${totalValues.totalWithDiscount} ${this.userCurrency}`);
  }

  showIconPackOrder(packId) {
    $('#purchase-modal').modal('show');
    const GAproducts = [];
    const pack = iconPack.getPackById(packId);
    const rates = wayforpayCustom.setCurrencyRate();
    Promise.all([pack, rates])
      .then(([pack, rates]) => {
        GAproducts.push({
          id: pack.id,
          name: `icon_pack: ${pack.id}`,
          price: 5,
        });
        GA.checkoutOption(2, GAproducts);
        $('#currency').empty();
        rates.forEach(obj => {
          $('#currency').append(
            `<li class="list-item"><a class="currency-val" id="${obj.currency}">${obj.currency}</a></li>`
          );
        });
        const template = $('#order-pack-template').html();
        const mustacheTemplate = mustache.render(template, {
          pack,
          icons: pack.paidIcons,
          price: iconPack.constructor.price,
        });
        this.updateTotal();
        $('#order')
          .empty()
          .append(mustacheTemplate);
      })
      .catch(() => notification.error(i18nClient.t('Something went wrong')));
  }

  showOrderInfo() {
    $('#purchase-modal').modal('show');
    let paidTemplate = null;
    const GAproducts = [];
    const paidIcons = Array.from(paidIcon.getUsedIcons()).map(icon => ({
      name: i18nClient.t('Icon'),
      source: `/paid_icons/source/${icon}`,
      price: `${paidIcon.getPrice()} ${this.baseCurrency}`,
    }));
    Array.from(paidIcon.getUsedIcons()).forEach(icon =>
      GAproducts.push({
        id: icon,
        name: `icon: ${icon}`,
        price: 1,
      })
    );
    if (templatesManager.isTemplatePaid()) {
      paidTemplate = {
        name: i18nClient.t('Template'),
        source: '/img/favicon/formats/icon-a4-cut.svg',
        bundle: '/img/favicon/formats/icon-formats-bundle-cut.svg',
        price: `${templatesManager.getPrice()} ${this.baseCurrency}`,
        checkedBundle: templatesManager.isBundle() ? 'checked' : '',
      };
      GAproducts.push({
        id: templatesManager.getId(),
        name: `template: ${templatesManager.getId()}`,
        price: paidTemplate.price,
      });
    }
    GA.checkoutOption(2, GAproducts);

    wayforpayCustom
      .setCurrencyRate()
      .then(rates => {
        $('#currency').empty();
        rates.forEach(obj => {
          $('#currency').append(
            `<li class="list-item"><a class="currency-val" id="${obj.currency}">${obj.currency}</a></li>`
          );
        });
      })
      .then(() => {
        const template = $('#order-items-template').html();
        const mustacheTemplate = mustache.render(template, {
          paidIcons,
          paidTemplate,
          bundlePrice: templatesManager.getBundlePrice(),
          getBundleText: i18nClient.t('getBundle', { returnObjects: true }),
        });
        this.updateTotal();
        $('#order')
          .empty()
          .append(mustacheTemplate);
      })
      .catch(() => notification.error(i18nClient.t('Something went wrong')));
  }

  submit({ orderedTemplate, orderedIcons, orderedIconPack }) {
    return new Promise((resolve, reject) => {
      $.post('/freemium_order/submit', {
        orderedTemplate,
        orderedIcons,
        orderedIconPack,
      })
        .done(res => resolve(res))
        .fail(err => reject(err));
    });
  }

  sendToGA(paidIcons, paidTemplate, paidIconPack) {
    const GAproducts = [];
    if (paidIcons !== null && Array.from(paidIcons).length)
      Array.from(paidIcons).forEach(icon => {
        GAproducts.push({ id: icon, name: `icon: ${icon}`, price: 1 });
      });
    if (paidTemplate !== null) {
      GAproducts.push({
        id: templatesManager.getId(),
        name:
          (templatesManager.isBundle() ? 'bundle: ' : 'template: ') +
          templatesManager.getId(),
        price: templatesManager.getPrice(),
      });
    }
    if (paidIconPack) {
      GAproducts.push({
        id: paidIconPack.id,
        name: `icon_pack: ${paidIconPack.id}`,
        price: 5,
      });
    }
    GA.checkoutOption(3, GAproducts);
  }

  startPaymentProcess(isFreePayment, params, userId, newBonusBalance, orderId) {
    if (isFreePayment) {
      return wayforpayCustom.freePayment(orderId, userId, newBonusBalance);
    }
    return wayforpayCustom.pay(params, newBonusBalance);
  }

  buy() {
    let orderId;
    const isUser1 = $('.account')
      .attr('src')
      .includes('/user_1/');
    const menuId = $('#save-to-db').attr('data-menuId');

    const orderAmountData = this.getTotal();
    const isFreePayment = Number(orderAmountData.totalWithDiscount) === 0;
    const total = isUser1 ? 1 : orderAmountData.totalWithDiscount;

    const userId = userProfile.getUserId();
    const paidIconPack = iconPack.getCurrentIconPack();
    if (paidIconPack) {
      this.sendToGA(null, null, paidIconPack);
      return menuStorage
        .saveToDB(menuId, true)
        .then(() =>
          this.submit({
            orderedIconPack: iconPack.getOrderedIconPackDetails(),
          })
        )
        .then(id => {
          orderId = id;
          return wayforpayCustom.getParams({
            reference: `${id}_freemium_order_menu-${menuId}`,
            amount: total,
            currency: isUser1 ? 'UAH' : this.userCurrency,
            productName: paidIconPack.name,
            productCount: 1,
            productPrice: total,
            serviceURL: '/wayforpay/freemium',
          });
        })
        .then(params =>
          this.startPaymentProcess(
            isFreePayment,
            params,
            userId,
            orderAmountData.newBonusBalance,
            orderId
          )
        )
        .catch(err => console.log(err));
    }
    const paidIcons = paidIcon.getUsedIcons();
    const paidTemplate = templatesManager.isTemplatePaid()
      ? templatesManager.getUsedTemplate()
      : null;
    const paidItemsCount = paidIcons.size + (paidTemplate ? 1 : 0);
    const productName = [...Array.from(paidIcons), paidTemplate].join(' ');
    this.sendToGA(paidIcons, paidTemplate);
    return menuStorage
      .saveToDB(menuId, true)
      .then(() =>
        this.submit({
          orderedTemplate: templatesManager.getOrderedTemplateDetails(),
          orderedIcons: paidIcon.getOrderedIconDetails(),
        })
      )
      .then(id => {
        orderId = id;
        return wayforpayCustom.getParams({
          reference: `${id}_freemium_order_menu-${menuId}`,
          amount: total,
          currency: isUser1 ? 'UAH' : this.userCurrency,
          productName,
          productCount: paidItemsCount,
          productPrice: Number((total / paidItemsCount).toFixed(2)),
          serviceURL: '/wayforpay/freemium',
        });
      })
      .then(params =>
        this.startPaymentProcess(
          isFreePayment,
          params,
          userId,
          orderAmountData.newBonusBalance,
          orderId
        )
      )
      .catch(() => notification.warning(i18nClient.t('Something went wrong')));
  }

  static usedPaidElements() {
    return paidIcon.paidIconsAreInUse() || templatesManager.isTemplatePaid();
  }

  static displayPremium() {
    $('#premium-icon').css('display', 'inline');
    if ($('#premium-notify').length < 1)
      notification.info(i18nClient.t('Paid items added'), 'premium-notify');
  }

  static hidePremium() {
    $('#premium-icon').css('display', 'none');
    $('#premium-notify').remove();
  }
}

export const order = new Order();
