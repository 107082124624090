import { canvasPage } from './canvasPage';

export class Grid {
  constructor() {
    this.on = false;
    this.step = 50;
    this.horizontalStep = this.step;
    this.verticalStep = this.step;
  }

  getLineData(menuSize) {
    const count = Math.floor(menuSize / this.step);
    const diff = menuSize - count * this.step;
    const step = this.step + diff / count;
    return {
      count,
      step,
      parameters: {
        stroke: '#ccc',
        selectable: false,
        evented: false,
        class: 'grid',
      },
    };
  }

  show(fabricObj) {
    const horizontalLine = this.getLineData(fabricObj.width);
    const verticalLine = this.getLineData(fabricObj.height);
    this.horizontalStep = horizontalLine.step;
    this.verticalStep = verticalLine.step;
    for (let i = 1; i < horizontalLine.count; i += 1) {
      fabricObj.add(
        new fabric.Line(
          [
            i * horizontalLine.step,
            0,
            i * horizontalLine.step,
            fabricObj.height,
          ],
          horizontalLine.parameters
        )
      );
    }
    for (let i = 1; i < verticalLine.count; i += 1) {
      fabricObj.add(
        new fabric.Line(
          [0, i * verticalLine.step, fabricObj.width, i * verticalLine.step],
          verticalLine.parameters
        )
      );
    }
  }

  static remove(fabricObj) {
    fabricObj.getObjects().forEach(obj => {
      if (obj.class === 'grid') {
        fabricObj.remove(obj);
      }
    });
  }

  toggle(val) {
    let toggle = null;
    if (val) {
      this.turnOn();
      toggle = this.show;
    } else {
      this.turnOff();
      toggle = this.constructor.remove;
    }
    canvasPage
      .getFabricObjects()
      .forEach(fabricObj => toggle.bind(this, fabricObj).call());
  }

  turnOn() {
    this.on = true;
    $('.toggle-grid').prop('checked', 'checked');
  }

  turnOff() {
    this.on = false;
    $('.toggle-grid').prop('checked', '');
  }

  isOn() {
    return this.on;
  }

  static isShown(fabricObj) {
    return fabricObj.getObjects().some(obj => obj.class === 'grid');
  }

  static isNearTheLine(coordinate, step) {
    return Math.round((coordinate / step) * 3) % 3 === 0;
  }

  static getLineCoordinate(coordinate, step) {
    return Math.round(coordinate / step) * step;
  }

  static getRemainingWidth(obj) {
    return (obj.originX === 'left' ? obj.width : obj.width / 2) * obj.scaleX;
  }

  static getRemainingHeight(obj) {
    return (obj.originY === 'top' ? obj.height : obj.height / 2) * obj.scaleY;
  }

  snapToGrid(movingObject) {
    const objPadding = 7;
    const width = this.constructor.getRemainingWidth(movingObject);
    const height = this.constructor.getRemainingHeight(movingObject);
    const right = movingObject.left + width;
    const bottom = movingObject.top + height;
    if (this.constructor.isNearTheLine(movingObject.left, this.verticalStep)) {
      movingObject
        .set({
          left:
            this.constructor.getLineCoordinate(
              movingObject.left,
              this.verticalStep
            ) + objPadding,
        })
        .setCoords();
    }
    if (this.constructor.isNearTheLine(movingObject.top, this.horizontalStep)) {
      movingObject
        .set({
          top:
            this.constructor.getLineCoordinate(
              movingObject.top,
              this.horizontalStep
            ) + 2,
        })
        .setCoords();
    }
    if (this.constructor.isNearTheLine(right, this.verticalStep)) {
      movingObject
        .set({
          left:
            this.constructor.getLineCoordinate(right, this.verticalStep) -
            width -
            2,
        })
        .setCoords();
    }
    if (this.constructor.isNearTheLine(bottom, this.horizontalStep)) {
      movingObject
        .set({
          top:
            this.constructor.getLineCoordinate(bottom, this.horizontalStep) -
            height -
            objPadding -
            2,
        })
        .setCoords();
    }
  }
}

export const grid = new Grid();
