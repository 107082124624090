import { canvas } from './canvasManager';
import { clearValues, setCustomTextEditingBorder, ucfirst } from './helpers';
import { order } from './order';
import { templatesManager } from './menu_templates/templatesManager';
import { category } from './menu_templates/category';
import * as notification from '../common/notifications';
import i18nClient from '../i18nextClient';
import { getSelectionType } from './controlLayers';

const alignObject = (object, originX, width) => {
  if (object.originX === originX) return object.left;
  switch (originX) {
    case 'left': {
      if (object.originX === 'center') {
        return object.left - Number(width) / 2;
      }
      return object.left - Number(width); // right only
    }
    case 'center': {
      if (object.originX === 'left') {
        return object.left + Number(width) / 2;
      }
      return object.left - Number(width) / 2; // right only
    }
    case 'right': {
      if (object.originX === 'center') {
        return object.left + Number(width) / 2;
      }
      return object.left + Number(width); // left only
    }
  }
};

const coordinatesInputeMode = () =>
  !(
    $('.top-coordinate').prop('readonly') &&
    $('.left-coordinate').prop('readonly')
  );

export function changeColor(fabricObj, object, colorHex) {
  window.customCanvas.setElementColor(fabricObj, object, 'fill', colorHex);
  fabricObj.fire('object:modified', { target: object });
}

export function changeBorderColor(fabricObj, object, color) {
  window.customCanvas.setProperty(fabricObj, object, 'stroke', color);
  fabricObj.fire('object:modified', { target: object });
}

export function removeObject() {
  const currentCanvas = canvas.current();
  const activeObject = currentCanvas.getActiveObject();
  if (coordinatesInputeMode() && templatesManager.isDesignerMode()) return;
  if ($('.chosen-search-input').is(':focus')) return;
  if (activeObject == null || activeObject.isEditing) return;
  if (
    activeObject.identifier &&
    activeObject.class !== 'categoryWrapper' &&
    templatesManager.isDesignerMode()
  )
    return;
  let categoryWrappers = [];
  currentCanvas.getActiveObjects().forEach(obj => {
    if (obj.class === 'categoryWrapper') {
      categoryWrappers = [...categoryWrappers, obj];
    } else {
      currentCanvas.remove(obj);
    }
  });
  if (categoryWrappers.length) {
    const categoryObjects = currentCanvas
      .getObjects()
      .filter(obj => _.find(categoryWrappers, { identifier: obj.identifier }));
    const objectsForRemoval = categoryObjects.length
      ? categoryObjects
      : categoryWrappers;
    objectsForRemoval.forEach(obj => currentCanvas.remove(obj));
    currentCanvas.fire('object:modified', { target: activeObject });
    if (templatesManager.isDesignerMode()) {
      const unusedCategories = categoryWrappers.filter(
        wrapper => !category.isCategoryUsed(wrapper.categoryName)
      );
      unusedCategories.forEach(unusedCategory =>
        category.remove(unusedCategory.categoryName)
      );
    }
  }
  if (!order.constructor.usedPaidElements()) order.constructor.hidePremium();
  currentCanvas.discardActiveObject();
  currentCanvas.requestRenderAll();
}

export function controlLayers(e) {
  const currentCanvas = canvas.current();
  const selectedObject = canvas.current().getActiveObject();

  if (!selectedObject) {
    return;
  }

  const selection = getSelectionType(
    currentCanvas.getObjects(),
    selectedObject
  );

  if (e.target.id === 'bring-to-front' || e.target.id === 'to-front-icon') {
    selection.forward();
  } else if (e.target.id === 'send-to-back' || e.target.id === 'to-back-icon') {
    selection.backward();
  } else if (
    e.target.id === 'move-to-stack-top' ||
    e.target.id === 'to-stack-top-icon'
  ) {
    selection.bringToFront();
  } else if (
    e.target.id === 'move-to-stack-bottom' ||
    e.target.id === 'to-stack-bottom-icon'
  ) {
    selection.sendToBack();
  }
  currentCanvas.fire('object:modified', { target: selection });
}

export function changeOpacity(fabricObj, object, value) {
  window.customCanvas.setProperty(fabricObj, object, 'opacity', value);
  $('.transparency-value').text(`${Math.round((1 - value) * 100)}%`);
  fabricObj.fire('object:modified', { target: object });
}

export function changeBorderWidth(fabricObj, object, value) {
  const color = object.get('stroke') || '#d71f49';
  window.customCanvas.setProperty(fabricObj, object, 'stroke', color);
  window.customCanvas.setProperty(fabricObj, object, 'strokeWidth', value);
  $('.border-width-value').text(`${value}px`);
  $('.border-color').css('background', color);
  fabricObj.fire('object:modified', { target: object });
}

export function onBorder(fabricObj, object) {
  if (object) {
    const color = object.get('stroke') || '#d71f49';
    window.customCanvas.setProperty(fabricObj, object, 'stroke', color);
    window.customCanvas.setProperty(fabricObj, object, 'strokeWidth', 1);
    $('.border-width-value').text('1px');
    $('.border-color').css('background', color);
  } else {
    $('#border').hide();
    $('.toggle-border').prop('checked', false);
  }
  fabricObj.fire('object:modified', { target: object });
}

export function offBorder(fabricObj, object) {
  window.customCanvas.setProperty(fabricObj, object, 'strokeWidth', 0);
  window.customCanvas.setProperty(fabricObj, object, 'stroke', '');
  clearValues('.border-width', '.border-width-value', '0px');
  fabricObj.fire('object:modified', { target: object });
}

export function toggleBorder(btn) {
  if (btn.prop('checked')) {
    onBorder(canvas.current(), canvas.current().getActiveObject());
  } else {
    offBorder(canvas.current(), canvas.current().getActiveObject());
  }
}

export function showCoordinates(obj) {
  if (!templatesManager.isDesignerMode() || !obj) return;
  $('.top-coordinate').val(Math.ceil(obj.top));
  $('.left-coordinate').val(Math.ceil(obj.left));
}

export function setCoordinates(axis, coordinate) {
  const currentCanvas = canvas.current();
  if (!currentCanvas.getActiveObject()) return;
  if (currentCanvas.getActiveObject().identifier) return;
  if (!coordinate) {
    notification.warning(i18nClient.t('This field cannot be blank'));
    return;
  }

  if (!/^[0-9]+$/.test(coordinate)) {
    notification.warning(
      i18nClient.t('This field cannot contain special signs')
    );
    return;
  }

  $(`.${axis}-coordinate`).prop('readonly', true);
  currentCanvas
    .getActiveObject()
    .set(axis, coordinate)
    .setCoords();
  currentCanvas.requestRenderAll();
}

export function showAllProperties() {
  const activeObject = canvas.current().getActiveObject();
  if (activeObject != null) {
    $('.fill-color').css('background', activeObject.get('fill') || '#d0d7dd');
    if (activeObject.get('fill')) {
      if (typeof activeObject.getObjects !== 'function') {
        $('.fill-color-class :input').val(
          activeObject.get('fill') || '#d0d7dd'
        );
        $('.fill-color-class :input').trigger('keyup');
      }
    }
    const transparency = Math.round(
      (1 - activeObject.get('opacity')) * 100 || 0
    );
    $('.transparency').val(transparency);
    $('.transparency').css(
      'background',
      `linear-gradient(to right, rgb(215, 31, 73) 0%, rgb(215, 31, 73) ${transparency}%, rgb(229, 229, 229) ${transparency}%, rgb(229, 229, 229) 100%)`
    );
    $('.transparency-value').text(`${transparency}%`);
    const stroke = activeObject.get('stroke');
    const borderWidth = stroke ? activeObject.get('strokeWidth') : 0;
    $('.border-color').css('background', stroke || '#d0d7dd');
    if (stroke) {
      $('.border-color-class :input').val(stroke || '#d0d7dd');
      $('.border-color-class :input').trigger('keyup');
      $('#border').show();
    }
    $('.border-width')
      .val(borderWidth * 10)
      .css(
        'background',
        `linear-gradient(to right, rgb(215, 31, 73) 0%, rgb(215, 31, 73) ${borderWidth *
          10}%, rgb(229, 229, 229) ${borderWidth *
          10}%, rgb(229, 229, 229) 100%)`
      );
    $('.border-width-value').text(`${borderWidth}px`);
    $('.toggle-border').prop('checked', !!stroke);
    const shadow = activeObject.get('shadow');
    $('#shadow-color').css('background', shadow ? shadow.color : '#d0d7dd');
    if (shadow) {
      $('.shadow-color-class :input').val(shadow ? shadow.color : '#d0d7dd');
      $('.shadow-color-class :input').trigger('keyup');
      $('#shadow').show();
    }
    const shadowVal = shadow ? shadow.offsetX * 10 : 0;
    $('#shadow-size').val(shadow ? shadow.offsetX * 10 : 0);
    $('#shadow-size').css(
      'background',
      `linear-gradient(to right, rgb(215, 31, 73) 0%, rgb(215, 31, 73) ${shadowVal}%, rgb(229, 229, 229) ${shadowVal}%, rgb(229, 229, 229) 100%)`
    );
    $('#shadow-size-value').text(`${shadowVal / 10}px`);
    $('#toggle-shadow').prop('checked', !!shadow);
    $('.font-family-value').text(
      ucfirst(activeObject.get('fontFamily') || 'Open Sans')
    );
    $('.font-size-value').text(
      Math.round(activeObject.get('fontSize') * (3 / 4)) || '14'
    );
    $('.line-height-value').text(activeObject.get('lineHeight'));
    if (activeObject.get('textAlign'))
      $(`#${activeObject.get('textAlign')}`).prop('checked', true);
    $('input[data-textDecoration="bold"]').prop(
      'checked',
      activeObject.get('fontWeight') === 'bold'
    );
    $('input[data-textDecoration="italic"]').prop(
      'checked',
      activeObject.get('fontStyle') === 'italic'
    );
    $('input[data-alignDirection="left"]').prop(
      'checked',
      activeObject.get('textAlign') === 'left'
    );
    $('input[data-alignDirection="center"]').prop(
      'checked',
      activeObject.get('textAlign') === 'center'
    );
    $('input[data-alignDirection="right"]').prop(
      'checked',
      activeObject.get('textAlign') === 'right'
    );
    $('.spacing').val(activeObject.get('charSpacing') / 10 || 0);
    $('.spacing-value').text(activeObject.get('charSpacing'));
    $('#border-radius').val(activeObject.get('rx') || 0);
    $('#border-radius-value').text(`${activeObject.get('rx') || 0}px`);
  }
}

let copiedObject;

export function copy() {
  const object = canvas.current().getActiveObject();
  if (
    !object ||
    object.isEditing ||
    object.class === 'categoryWrapper' ||
    object.type === 'path-group'
  )
    return;
  object.clone(
    cloned => {
      copiedObject = cloned;
    },
    ['name', 'class', 'hasControls']
  );
}

export function paste() {
  if (copiedObject) {
    const currentCanvas = canvas.current();
    currentCanvas.discardActiveObject();
    copiedObject.top += 10;
    copiedObject.left += 10;
    copiedObject.setCoords();
    if (copiedObject.type === 'i-text') {
      setCustomTextEditingBorder(copiedObject);
    }
    if (copiedObject.type === 'activeSelection') {
      copiedObject.forEachObject(obj => currentCanvas.add(obj));
      copiedObject.setCoords();
    } else {
      currentCanvas.add(copiedObject);
    }
    currentCanvas.setActiveObject(copiedObject).requestRenderAll();
  }
  copiedObject = null;
}

export function copyLink(link) {
  const $temp = $('<input>');
  $('body').append($temp);
  $temp.val(link).select();
  document.execCommand('copy');
  $temp.remove();
}

export function group() {
  const currentCanvas = canvas.current();
  if (
    !currentCanvas.getActiveObject() ||
    currentCanvas.getActiveObject().type !== 'activeSelection'
  ) {
    return;
  }
  const activeObjects = currentCanvas.getActiveObjects();
  const activeCategoryObjects = activeObjects.filter(obj => obj.identifier);
  const toBeSelected = currentCanvas
    .getObjects()
    .filter(
      obj =>
        activeObjects.includes(obj) ||
        (obj.identifier &&
          _.find(activeCategoryObjects, { identifier: obj.identifier }))
    );
  const emptySelection = new fabric.ActiveSelection([], {
    canvas: currentCanvas,
  });
  currentCanvas.setActiveObject(emptySelection);
  const selection = new fabric.ActiveSelection(toBeSelected, {
    canvas: currentCanvas,
  });
  selection.toGroup();
}

export function ungroup() {
  if (
    !canvas.current().getActiveObject() ||
    canvas.current().getActiveObject().type !== 'group'
  ) {
    return;
  }
  canvas
    .current()
    .getActiveObject()
    .toActiveSelection();
  canvas.current().requestRenderAll();
}

export function moveObject(axis, sign, e) {
  const currentCanvas = canvas.current();
  const activeObj = currentCanvas.getActiveObject();
  currentCanvas.fire('object:modified', { target: activeObj });
  if (activeObj) e.preventDefault();
  else return;
  if (activeObj.identifier) return;
  if (coordinatesInputeMode() && templatesManager.isDesignerMode()) return;
  if (sign === '-') {
    activeObj[axis] -= 5;
  } else {
    activeObj[axis] += 5;
  }

  activeObj.setCoords();
  currentCanvas.requestRenderAll();
}

export function setOriginX(fabricObj, object, value) {
  const config = templatesManager.getCategoryConfig(
    category.constructor.getCategoryName(fabricObj, object.identifier)
  );
  const newLeft = alignObject(object, value, config.width);
  window.customCanvas.setProperty(fabricObj, object, 'originX', value);
  window.customCanvas.setProperty(fabricObj, object, 'left', newLeft);
}
