import Cropper from 'cropperjs';
import * as notification from '../common/notifications';
import i18nClient from '../i18nextClient';

export class UserProfile {
  constructor() {
    this.id = null;
    this.email = null;
    this.bonusBalance = null;
  }

  setData({ id, email, bonus_balance: bonusBalance }) {
    this.id = id;
    this.email = email;
    if (bonusBalance && bonusBalance.isValid) {
      this.bonusBalance = bonusBalance;
      this.bonusBalance.initialValue = $('#used-bonus-balance').text();
    }
  }

  getBonusBalanceSum() {
    return this.bonusBalance ? this.bonusBalance.sum : 0;
  }

  getBonusBalanceInitialValue() {
    return this.bonusBalance ? this.bonusBalance.initialValue : 0;
  }

  getUserId() {
    return this.id;
  }
}

export function saveImagePath(file, pictureType) {
  const formData = new FormData();
  formData.append('file', file);
  $.ajax({
    url: `/profile/upload?type=${pictureType}`,
    type: 'POST',
    data: formData,
    processData: false,
    contentType: false,
    success(data) {
      $('#upload-profile-photo-img').attr('src', data);
      $('#profile-photo').attr('src', data);
      location.reload();
    },
    error() {
      notification.warning(i18nClient.t('Something went wrong'));
    },
  });
}

export function sendEmailVerification() {
  $.ajax({
    url: '/send_email_verification',
    type: 'POST',
    success() {
      notification.success(i18nClient.t('Sent'));
    },
    error() {
      notification.warning(i18nClient.t('Something went wrong'));
    },
  });
}

export function deleteUser(profileId, button) {
  $.ajax({
    url: `/admin/${profileId}`,
    type: 'DELETE',
    success() {
      button.closest('tr').remove();
    },
    error() {
      notification.warning(i18nClient.t('Something went wrong'));
    },
  });
}

export function loginAs(profileId, provider) {
  $.post('/admin/login_as', { profileId, provider })
    .done(() => {
      $(location).attr('href', '/dashboard');
    })
    .fail(() => {
      notification.warning(i18nClient.t('Something went wrong'));
    });
}

export function uploadPhoto(file) {
  if (file.size > 5000000)
    return notification.warning(i18nClient.t('Image size is too large'));
  if (!$.trim($('#upload-profile-photo').val()).length) return;

  let cropper = null;
  $('#crop-image-modal').modal('show');
  const reader = new FileReader();
  reader.onload = function(e) {
    const img = document.createElement('img');
    img.id = 'image';
    img.src = e.target.result;
    $('.result').html('');
    $('.result').append(img);
    cropper = new Cropper(img, {
      aspectRatio: 1 / 1,
      minContainerWidth: 668,
      minContainerHeight: 500,
      maxContainerHeight: 600,
      minWidth: 300,
      minHeight: 400,
      maxHeight: 600,
      viewMode: 3,
      restore: false,
    });
    $('#upload-profile-photo')[0].value = '';
  };
  reader.readAsDataURL(file);
  $('.save').click(() => {
    const imgSrc = cropper.getCroppedCanvas().toDataURL();

    $.ajax({
      url: imgSrc,
      type: 'GET',
      xhr: function() {
        const xhr = new XMLHttpRequest();
        xhr.responseType = 'blob';
        return xhr;
      },
      success: function(blob) {
        const newFile = new File([blob], file.name, {
          type: file.type,
          lastModified: Date.now(),
        });
        saveImagePath(newFile, 'photo');
      },
      error() {
        notification.warning('Something went wrong');
      },
    });
  });
}

export const userProfile = new UserProfile();
