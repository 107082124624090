import cookies from 'js-cookie';
import i18next from 'i18next';
import EN from '../../locales/en.json';
import UK from '../../locales/uk.json';
import RU from '../../locales/ru.json';

const i18nClient = i18next.init({
  lng: cookies.get('lang'),
  resources: {
    en: {
      translations: EN,
    },
    uk: {
      translations: UK,
    },
    ru: {
      translations: RU,
    },
  },
  preload: ['en', 'uk', 'ru'],
  debug: false,
  ns: ['translations'],
  defaultNS: 'translations',
});

export default i18nClient;
