/* eslint-disable no-underscore-dangle */
import { canvasPage } from './canvasPage';

let tempObj = {};
let categoryObjects = [];
let pendingTransform;

/**
 * remove object from current canvas page when moving out of the page.
 * @param {*} dragObj
 * @param {*} canvasId
 * @returns void
 */
export function drag(dragObj, canvasId) {
  if (!canvasId) return;

  const fabricObj = canvasPage.findFabricObj(canvasId);

  pendingTransform = fabricObj._currentTransform;
  fabricObj._currentTransform = null;

  if (dragObj.class === 'categoryWrapper') {
    categoryObjects = fabricObj
      .getObjects()
      .filter(obj => obj.identifier === dragObj.identifier);
    categoryObjects.forEach(categoryObj => fabricObj.remove(categoryObj));
  } else {
    tempObj = dragObj;
    fabricObj.remove(dragObj);
  }

  fabricObj.renderAll();
}

/**
 * copy object to target canvas page
 * @param {*} canvasId
 * @returns void
 */
export function drop(canvasId) {
  if (!canvasId) return;

  const fabricObj = canvasPage.findFabricObj(canvasId);

  fabricObj._currentTransform = pendingTransform;
  fabric.util.addListener(fabric.document, 'mouseup', fabricObj._onMouseUp);

  if (categoryObjects.length) {
    categoryObjects.forEach(obj => {
      fabricObj.add(obj.setCoords());
      if (obj.class === 'categoryWrapper') fabricObj.setActiveObject(obj);
    });
    categoryObjects = [];
  } else {
    fabricObj.add(tempObj);
    fabricObj.setActiveObject(tempObj);
  }

  fabricObj.renderAll();
}
